import { useCryptoPrice } from "actions/Api";
import clsx from "clsx";
import { useAppState } from "context/context";
import { utils } from "ethers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isSameAddress, mapCurrency, zeroAddress } from "utils";
import PropTypes from "prop-types";

import createPersistedState from "use-persisted-state";
import { networks } from "@aztlan/config";

const useCurrencyState = createPersistedState("currency");

export default function PriceTicker({
  price,
  decimals,
  currency,
  convert = false,
  label,
  image,
  children,
  showSubConvert,
  imageHeight = "38px",
  chainId,
  sm = false,
  ...props
}) {
  // const { chainId } = useAppState();

  const { t } = useTranslation();

  const [userCurrency] = useCurrencyState("MXN");

  // const { data: { pairPrice } = {}, isLoading } = useCryptoPrice({
  //   currency: userCurrency,
  //   crypto: currency,
  //   amount: price,
  //   chainId,
  // });

  if (!price) {
    return null;
  }

  return (
    <div
      className={clsx(
        "d-flex align-self-center align-items-center",
        props.className
      )}
    >
      {image && (
        <img
          src={`/img/currencies/${mapCurrency(
            chainId,
            currency
          ).toLowerCase()}.png`}
          height={imageHeight}
          alt=""
          className={clsx(imageHeight === "38px" ? "me-3" : "me-2")}
        />
      )}
      <div>{label}</div>
      <div>
        <div hidden={convert} {...props}>
          {chainId && (
            <img
              alt=""
              src={`img/blockchains/${chainId}.png`}
              height="15px"
              className="me-1"
            />
          )}
          <small className={clsx({ small: sm }, "fw-bold")}>
            {utils.formatUnits(price, decimals).toString()}{" "}
            {isSameAddress(zeroAddress, currency)
              ? networks[chainId]?.nativeCurrency?.symbol
              : mapCurrency(chainId, currency)?.substring(0, 5)}
          </small>
        </div>
        {/* {showSubConvert && (
          <small className="text-muted d-block mt-2">
            $
            {pairPrice
              ? pairPrice * utils.formatUnits(price, decimals).toString()
              : t("unknown")}{" "}
            {userCurrency}
          </small>
        )} */}
      </div>
      {/* <div hidden={!convert || isLoading} {...props}>
        $
        {pairPrice
          ? pairPrice * utils.formatUnits(price, decimals).toString()
          : t("unknown")}{" "}
        {userCurrency}
      </div> */}
      {children}
    </div>
  );
}

PriceTicker.propTypes = {
  price: PropTypes.string,
  decimals: PropTypes.string,
  currency: PropTypes.string,
  convert: PropTypes.bool,
  label: PropTypes.string,
  image: PropTypes.bool,
  children: PropTypes.node,
  showSubConvert: PropTypes.bool,
  imageHeight: PropTypes.string,
  sm: PropTypes.bool,
};
