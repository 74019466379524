import { useNotifications } from "actions/Api";
import { FetcherComponent } from "components/Shared/Fetcher";
import { FormatedDateRelative } from "components/Shared/FormatedDate";
import { useAppState } from "context/context";
import { Container, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

function NotificationItem({ data: { notification, createdAt } }) {
  const navigate = useNavigate();
  const handleClick = () => {
    if (notification?.nftId) {
      navigate(`?nftId=${notification?.nftId}`);
    }
  };
  const { t } = useTranslation();
  return (
    <ListGroup.Item
      href="#"
      action
      className="p-2 bg-white text-dark pointer"
      onClick={handleClick}
    >
      <p className="fw-bold mb-0">{t(notification)}</p>
      <small className="text-muted">
        <FormatedDateRelative date={createdAt} />
      </small>
    </ListGroup.Item>
  );
}

export default function Notifications() {
  const { t } = useTranslation();
  const { address } = useAppState();
  const query = useNotifications(address);
  return (
    <section className="bg-white mt-5">
      <Container>
        <h3 className="mb-3">
          {t("Notificaciones")} ({query?.data?.length})
        </h3>
        <ListGroup variant="flush">
          <FetcherComponent query={query} emptyLabel="no_notifications">
            <NotificationItem />
          </FetcherComponent>
        </ListGroup>
      </Container>
    </section>
  );
}
