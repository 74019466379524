import ShrinkView from "components/Shared/ShrinkView";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const PrivacyES = `

*Borrador - Última edición: 11/05/2021*


Este contrato describe los términos y condiciones generales (en adelante únicamente "TÉRMINOS Y CONDICIONES") aplicables al uso de los contenidos, productos y servicios ofrecidos a través del sitio **AZTLAN** (en adelante, "SITIO WEB"), del cual es titular (en adelante, "TITULAR"). Cualquier persona que desee acceder o hacer uso del sitio o los servicios que en él se ofrecen, podrá hacerlo sujetándose a los presentes TÉRMINOS Y CONDICIONES, así como a políticas y principios incorporados al presente documento. En todo caso, cualquier persona que no acepte los presentes términos y condiciones, deberá abstenerse de utilizar el SITIO WEB y/o adquirir los productos y servicios que en su caso sean ofrecidos.

  
**I. DEL OBJETO.**

El objeto de los presentes TÉRMINOS Y CONDICIONES es regular el acceso y la utilización del SITIO WEB, entendiendo por este cualquier tipo de contenido, producto o servicio que se encuentre a disposición del público en general dentro del dominio: **AZTLAN** .

El TITULAR se reserva la facultad de modificar en cualquier momento y sin previo aviso, la presentación, los contenidos, la funcionalidad, los productos, los servicios, y la configuración que pudiera estar contenida en el SITIO WEB; en este sentido, el USUARIO reconoce y acepta que en cualquier momento podrá interrumpir, desactivar o cancelar cualquiera de los elementos que conforman el SITIO WEB o el acceso a los mismos.

El acceso al SITIO WEB por parte del USUARIO tiene carácter libre y, por regla general es gratuito sin que el USUARIO tenga que proporcionar una contraprestación para poder disfrutar de ello, salvo en lo referente al costo de la conexión a internet suministrada por el proveedor de este tipo de servicios que hubiere contratado el mismo USUARIO.

El acceso a parte de los contenidos y servicios del SITIO WEB podrá realizarse previa suscripción o registro previo del USUARIO.

El SITIO WEB se encuentra dirigido exclusivamente a personas que cuenten con la mayoría de edad (mayores de 18 años); en este sentido, declina cualquier responsabilidad por el incumplimiento de este requisito.

El SITIO WEB está dirigido principalmente a USUARIOS residentes en la República Mexicana, por lo cual, no asegura que el SITIO WEB cumpla total o parcialmente con la legislación de otros países, de forma que, si el USUARIO reside o tiene su domicilio establecido en otro país y decide acceder o utilizar el SITIO WEB lo hará bajo su propia responsabilidad y deberá asegurarse de que tal acceso y navegación cumple con la legislación local que le es aplicable, no asumiendo ninguna responsabilidad que se pueda derivar de dicho acto.

Se hace del conocimiento del USUARIO que el TITULAR podrá administrar o gestionar el SITIO WEB de manera directa o a través de un tercero, lo cual no modifica en ningún sentido lo establecido en los presentes TÉRMINOS Y CONDICIONES.

  
**II. DEL USUARIO.**

El acceso o utilización del SITIO WEB, así como de los recursos habilitados para interactuar entre los USUARIOS, o entre el USUARIO y el TITULAR tales como medios para realizar publicaciones o comentarios, confiere la condición de USUARIO del SITIO WEB, por lo que quedará sujeto a los presentes TÉRMINOS Y CONDICIONES, así como a sus ulteriores modificaciones, sin perjuicio de la aplicación de la legislación aplicable, por tanto, se tendrán por aceptados desde el momento en el que se accede al SITIO WEB. Dada la relevancia de lo anterior, se recomienda al USUARIO revisar las actualizaciones que se realicen a los presentes TÉRMINOS Y CONDICIONES.

Es responsabilidad del USUARIO utilizar el SITIO WEB de acuerdo a la forma en la que fue diseñado; en este sentido, queda prohibida la utilización de cualquier tipo de software que automatice la interacción o descarga de los contenidos o servicios proporcionados a través del SITIO WEB. Además, el USUARIO se compromete a utilizar la información, contenidos o servicios ofrecidos a través del SITIO WEB de manera lícita, sin contravenir lo dispuesto en los presentes TÉRMINOS Y CONDICIONES, la moral o el orden público, y se abstendrá de realizar cualquier acto que pueda suponer una afectación a los derechos de terceros, o perjudique de algún modo el funcionamiento del SITIO WEB.

Así mismo, el usuario se compromete a proporcionar información lícita y veraz en los formularios habilitados en el SITIO WEB, en los cuales el usuario tenga que proporcionar ciertos datos o información para el acceso a algún contenido, producto o servicio ofrecido por el propio SITIO WEB. En todo caso, el USUARIO notificará de forma inmediata al TITULAR acerca de cualquier hecho que permita suponer el uso indebido de la información registrada en dichos formularios, tales como, robo, extravío, o acceso no autorizado a cuentas y/o contraseñas, con el fin de proceder a su inmediata cancelación.

se reserva el derecho de retirar todos aquellos comentarios y aportaciones que vulneren la ley, el respeto a la dignidad de la persona, que sean discriminatorios, atenten contra los derechos de tercero o el orden público, o bien, que a su juicio no resulten adecuados para su publicación.

En cualquier caso, no será responsable de las opiniones vertidas por los USUARIOS a través de comentarios o publicaciones que estos realicen.

El sólo acceso al SITIO WEB no supone el establecimiento de ningún tipo de relación entre el TITULAR y el USUARIO.

Al tratarse de un SITIO WEB dirigido exclusivamente a personas que cuenten con la mayoría de edad, el USUARIO manifiesta ser mayor de edad y disponer de la capacidad jurídica necesaria para sujetarse a los presentes TÉRMINOS Y CONDICIONES.

  
**III. DEL ACCESO Y NAVEGACIÓN EN EL SITIO WEB.**

El TITULAR no garantiza de ningún modo la continuidad y disponibilidad de los contenidos, productos o servicios ofrecidos a través del SITIO WEB, no obstante, el TITULAR llevará a cabo las acciones que de acuerdo a sus posibilidades le permitan mantener el buen funcionamiento del SITO WEB, sin que esto suponga alguna responsabilidad de parte de .

De igual forma no será responsable ni garantiza que el contenido o software al que pueda accederse a través del SITIO WEB, se encuentre libre de errores, software malicioso, o que pueda causar algún daño a nivel de software o hardware en el equipo a través del cual el USUARIO accede al SITIO WEB.

El TITULAR tampoco se hace responsable de los daños que pudiesen ocasionarse por un uso inadecuado del SITIO WEB. En ningún caso será responsable por las pérdidas, daños o perjuicios de cualquier tipo que surjan por el sólo acceso o utilización del SITIO WEB.

  
**IV. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS.**

De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, el TITULAR se compromete a adoptar las medidas necesarias que estén a su alcance para asegurar la privacidad de los datos personales recabados de forma que se garantice su seguridad, se evite su alteración, pérdida o tratamiento no autorizado.

Además, a efecto de dar cumplimiento a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, todo dato personal que sea recabado a través del SITIO WEB, será tratado de conformidad con los principios de licitud, calidad, finalidad, lealtad, y responsabilidad. Todo tratamiento de datos personales quedará sujeto al consentimiento de su titular. En todo caso, la utilización de datos financieros o patrimoniales, requerirán de autorización expresa de sus titulares, no obstante, esta podrá darse a través del propio SITIO WEB utilizando los mecanismos habilitados para tal efecto, y en todo caso se dará la mayor diligencia y cuidado a este tipo de datos. Lo mismo ocurrirá en el caso de datos personales sensibles, considerando por estos aquellos que debido a una utilización indebida puedan dar origen a discriminación o su divulgación conlleve un riesgo para el titular.

En todo momento se procurará que los datos personales contenidos en las bases de datos o archivos que en su caso se utilicen, sean pertinentes, correctos y actualizados para los fines para los cuales fueron recabados.

El tratamiento de datos personales se limitará al cumplimiento de las finalidades previstas en el **Aviso de Privacidad** el cual se encontrará disponible en la siguiente dirección electrónica:

El SITIO WEB podrá incluir hipervínculos o enlaces que permitan acceder a páginas web de terceros distintos de . Los titulares de dichos sitios web dispondrán de sus propias políticas de privacidad y protección de datos, por lo cual no asume ningún tipo de responsabilidad por los datos que san facilitados por el USUARIO a través de cualquier sitio web distinto a **AZTLAN** .

se reserva el derecho a modificar su Política de Privacidad, de acuerdo a sus necesidades o derivado de algún cambio en la legislación. El acceso o utilización del SITIO WEB después de dichos cambios, implicará la aceptación de estos cambios.

Por otra parte, el acceso al SITIO WEB puede implicar la utilización de cookies, las cuales, son pequeñas cantidades de información que se almacenan en el navegador utilizado por el USUARIO. Las cookies facilitan la navegación, la hacen más amigable, y no dañan el dispositivo de navegación, para ello, pueden recabar información para ingresar al SITIO WEB, almacenar las preferencias del USUARIO, así como la interacción que este tenga con el SITIO WEB, como por ejemplo: la fecha y hora en la que se accede al SITIO WEB, el tiempo que se ha hecho uso de este, los sitios visitados antes y después del mismo, el número de páginas visitadas, la dirección IP de la cual accede el usuario, la frecuencia de visitas, etc.

Este tipo de información será utilizada para mejorar el SITIO WEB, detectar errores, y posibles necesidades que el USUARIO pueda tener, lo anterior a efecto de ofrecer a los USUARIOS servicios y contenidos de mejor calidad. En todo caso, la información que se recopile será anónima y no se identificará a usuarios individuales.

En caso de que el USUARIO no desee que se recopile este tipo de información deberá deshabilitar, rechazar, restringir y/o eliminar el uso de cookies en su navegador de internet. Los procedimientos para realizar estas acciones pueden diferir de un navegador a otro; en consecuencia, se sugiere revisar las instrucciones facilitadas por el desarrollador del navegador. En el supuesto de que rechace el uso de cookies (total o parcialmente) el USUARIO podrá continuar haciendo uso del SITIO WEB, aunque podrían quedar deshabilitadas algunas de las funciones del mismo.

Es posible que en el futuro estas políticas respecto a las cookies cambien o se actualicen, por ello es recomendable revisar las actualizaciones que se realicen a los presentes TÉRMINOS Y CONDICIONES, con objetivo de estar adecuadamente informado sobre cómo y para qué utilizamos las cookies que se generan al ingresar o hacer uso del SITIO WEB.

  
**V. POLÍTICA DE ENLACES.**

El SITIO WEB puede contener enlaces, contenidos, servicios o funciones, de otros sitios de internet pertenecientes y/o gestionados por terceros, como por ejemplo imágenes, videos, comentarios, motores de búsqueda, etc.

La utilización de estos enlaces, contenidos, servicios o funciones, tiene por objeto mejorar la experiencia del USUARIO al hacer uso del SITIO WEB, sin que pueda considerarse una sugerencia, recomendación o invitación para hacer uso de sitios externos. en ningún caso revisará o controlará el contenido de los sitios externos, de igual forma, no hace propios los productos, servicios, contenidos, y cualquier otro material existente en los referidos sitios enlazados; por lo cual, tampoco se garantizará la disponibilidad, exactitud, veracidad, validez o legalidad de los sitios externos a los que se pueda tener acceso a través del SITIO WEB. Así mismo, el TITULAR no asume ninguna responsabilidad por los daños y perjuicios que pudieran producirse por el acceso o uso, de los contenidos, productos o servicios disponibles en los sitios web no gestionados por a los que se pueda acceder mediante el SITIO WEB.

  
**VI. POLÍTICA EN MATERIA DE PROPIEDAD INTELECTUAL E INDUSTRIAL.**

por sí o como parte cesionaria, es titular de todos los derechos de propiedad intelectual e industrial del SITIO WEB, entendiendo por este el código fuente que hace posible su funcionamiento así como las imágenes, archivos de audio o video, logotipos, marcas, combinaciones de colores, estructuras, diseños y demás elementos que lo distinguen. Serán, por consiguiente, protegidas por la legislación mexicana en materia de propiedad intelectual e industrial, así como por los tratados internacionales aplicables. Por consiguiente, queda expresamente prohibida la reproducción, distribución, o difusión de los contenidos del SITIO WEB, con fines comerciales, en cualquier soporte y por cualquier medio, sin la autorización de .

El USUARIO se compromete a respetar los derechos de propiedad intelectual e industrial del TITULAR. No obstante, además de poder visualizar los elementos del SITIO WEB podrá imprimirlos, copiarlos o almacenarlos, siempre y cuando sea exclusivamente para su uso estrictamente personal.

Por otro lado, el USUARIO, se abstendrá de suprimir, alterar, o manipular cualquier elemento, archivo, o contenido, del SITIO WEB, y por ningún motivo realizará actos tendientes a vulnerar la seguridad, los archivos o bases de datos que se encuentren protegidos, ya sea a través de un acceso restringido mediante un usuario y contraseña, o porque no cuente con los permisos para visualizarlos, editarlos o manipularlos.

En caso de que el USUARIO o algún tercero consideren que cualquiera de los contenidos del SITIO WEB suponga una violación de los derechos de protección de la propiedad industrial o intelectual, deberá comunicarlo inmediatamente a a través de los datos de contacto disponibles en el propio SITIO WEB.

**VII. LEGISLACIÓN Y JURISDICCIÓN APLICABLE.**

se reserva la facultad de presentar las acciones civiles o penales que considere necesarias por la utilización indebida del SITIO WEB, sus contenidos, productos o servicios, o por el incumplimiento de los presentes TÉRMINOS Y CONDICIONES.

La relación entre el USUARIO y se regirá por la legislación vigente en México, específicamente en la Ciudad de México. De surgir cualquier controversia en relación a la interpretación y/o a la aplicación de los presentes TÉRMINOS Y CONDICIONES, las partes se someterán a la jurisdicción ordinaria de los tribunales que correspondan conforme a derecho en el estado al que se hace referencia.
`;

const PrivacyEN = `

*Draft - Last revision: 11/05/2021*


This Privacy Policy for Aztlan Market its affiliates (collectively, “Company”, “we”, “us” “our”) describes how we collect, use and disclose information about users of the Company’s website aztlan.market and application, together with any content, tools, features and functionality offered on or through our website (collectively, the “Services”). For the purposes of this Privacy Policy, “you” and “your” means you as the user of the Services. 
PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY USING, ACCESSING, OR DOWNLOADING ANY OF THE SERVICES, YOU AGREE TO THE USES OF INFORMATION DESCRIBED AND TO THE OTHER TERMS OF THIS PRIVACY POLICY. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, PLEASE DO NOT ACCESS AND USE THE SERVICES.

**1. UPDATING THIS PRIVACY POLICY**

We may modify this Privacy Policy from time to time in which case we will update the “Last Revised” date at the top of this Privacy Policy. If we make changes that are material, we will use reasonable efforts to attempt to provide notice to you and, where required by applicable law, we will obtain your consent. Notice may be by email to you at the last email address you provided us, by posting notice of such changes on the Services, or by other means, consistent with applicable law. However, it is your sole responsibility to review the Privacy Policy from time to time to view any such changes. The updated Privacy Policy will be effective as of the time of posting, or such later date as may be specified in the updated Privacy Policy.  IF YOU DO NOT AGREE TO ANY UPDATES TO THIS PRIVACY POLICY PLEASE DO NOT ACCESS OR CONTINUE TO USE THE SERVICES.

**2. COMPANY’S COLLECTION AND USE OF INFORMATION**

When we provide you with the Services, we may ask you to or you may provide us with certain details or information about you as follows: 
Third-party wallet information in order to create a profile and facilitate your use of the Services. Transactional information associated with your third-party wallet address from the blockchain(s). Information that we may receive from your third-party wallet profile: username, description, profile image, third-party wallet account ID.Any other information you choose to include in communications with us, for example, when contacting us through the Services.
Some features of the Services may require you to enter certain information about yourself. You may elect not to provide this information, but doing so may prevent you from using or accessing these features.
We also automatically collect certain information about your interaction with the Services (“Usage Data”). To do this, we may use cookies, web beacons/clear gifs, other geolocation tracking technologies (“Tracking Technologies”). Usage Data may include:
Unique device identifierDevice type, such as your phone, computer, or tabletIP addressBrowser typeUsage Data, such as date and time stamps, such as the date and time you first accessed the ServicesOperating systemLog dataPrecise locationOther information regarding your interaction with the Services, such as clickstream data and ad impressions
We use the information we collect automatically to improve the functionality of the Services, to monitor and manage your usage of the Services, to tailor features and content to you, to run analytics and better understand user interaction with the Services.
In addition to the foregoing, we may use all of the above information to comply with any applicable legal obligations, to enforce any applicable terms of service, and to protect or defend the Services, our rights, the rights of our users, or others.

**3. HOW THE COMPANY SHARES YOUR INFORMATION**

In certain circumstances, the Company may share your information with third parties. Such circumstances may include: 
With vendors or other service providers, such asPayment processorsData analytics vendorsCloud storage providersIT service management vendorsEmail marketing services vendorsSecurity vendorsWith our affiliates or otherwise within our corporate groupWith third parties for marketing purposesTo comply with applicable law or any obligations thereunder, including cooperation with law enforcement, judicial orders, and regulatory inquiriesIn connection with an asset sale, merger, bankruptcy, or other business transactionTo enforce any applicable terms of serviceTo ensure the safety and security of the Company and/or its usersWhen you request us to share certain information with third parties, such as through your use of social media widgets or login integrationsWith professional advisors, such as auditors, law firms, or accounting firms
You acknowledge that such sharing of information may occur in all of the aforementioned circumstances and is permitted by and subject to this Privacy Policy.

**4. COOKIES AND OTHER TRACKING TECHNOLOGIES**

Do Not Track Signals

Your browser settings may also allow you to transmit a “Do Not Track” signal when you visit various websites. Like many websites, our website is not designed to respond to “Do Not Track” signals received from browsers. To learn more about “Do Not Track” signals, you can visit http://www.allaboutdnt.com/.
Cookies and Other Tracking Technologies
You may control the way in which your devices permit the use of Tracking Technologies. If you so choose, you may block or delete our cookies from your browser; however, blocking or deleting cookies may cause some of the Services, including any portal features and general functionality, to work incorrectly. 
Most browsers accept cookies automatically. However, you may be able to configure your browser settings to use the Services without some cookie functionality. You can delete cookies manually or set your browser to automatically delete cookies on a pre-determined schedule.  For example, in the Internet Explorer menu bar, select: Tools 🡪Internet Options🡪Browsing History🡪Delete to view manual and automatic options.
To opt-out of tracking by Google Analytics, click here.
If you have questions regarding the specific information about you that we process or retain, as well as your choices regarding our collection and use practices, please contact us using the information listed below.

**5. USER GENERATED CONTENT**

Certain features of the Services may allow you to upload content (e.g., digital media and art linked to a non-fungible token (“NFT”)) directly to the Services (“User-Generated Content” or “UGC”). We or others may store, display, reproduce, publish, or otherwise use UGC, and may or may not attribute it to you. Others may also have access to UGC and may have the ability to share it with third parties. If you choose to submit UGC to any public area of the Services, your UGC will be considered “public” and will be accessible by anyone, including the Company.
Please note that we do not control who will have access to the information that you choose to make available to others, and cannot ensure that parties who have access to such information will respect your privacy or keep it secure. We are not responsible for the privacy or security of any information that you make publicly available on the features permitting creation of UGC or what others do with information you share with them. We are not responsible for the accuracy, use or misuse of any such UGC that you disclose or receive from third parties.

**6. SOCIAL FEATURES **

Certain features of the Services permit you to initiate interactions between the Services and third-party services or platforms, such as social networks (“Social Features”). Social Features include features that allow you to click and access the Company’s pages on certain third-party platforms, such as Facebook and Twitter, and from there to “like” or “share” our content on those platforms. Use of Social Features may entail a third party’s collection and/or use of your data. If you use Social Features or similar third-party services, information you post or otherwise make accessible may be publicly displayed by the third-party service you are using. Both the Company and the third party may have access to information about you and your use of both the Services and the third-party service. For more information on third-party websites and platforms, see Section 7.

**7. THIRD PARTY WEBSITES AND LINKS, INCLUDING THIRD PARTY WALLETS**

Our Services may contain links to other online platforms operated by third parties. We do not control such other online platforms and are not responsible for their content, their privacy policies, or their use of your information. Information you provide on public or semi-public venues, including information you share on third-party social networking platforms (such as Facebook or Twitter) may also be viewable by other users of the Services and/or users of those third-party online platforms without limitation as to its use by us or by a third party. Our inclusion of such links does not, by itself, imply any endorsement of the content on such platforms or of their owners or operators except as disclosed on the Services. We expressly disclaim any and all liability for the actions of third parties, including but without limitation to actions relating to the use and/or disclosure of personal information by third parties. Any information submitted by you directly to these third parties is subject to that third party’s privacy policy.
In particular, certain transactions conducted via our Services, including creating a profile, purchasing an NFT, or spending applicable cryptocurrency, will require you to connect a Wallet to the Services. By using such Wallet to conduct such transactions via the Services, you agree that your interactions with such third party Wallets are governed by the privacy policy for the applicable Wallet. We expressly disclaim any and all liability for actions arising from your use of third party Wallets, including but without limitation, to actions relating to the use and/or disclosure of personal information by such third party Wallets. 

**8. CHILDREN’S PRIVACY**

Children under the age of 13 are not permitted to use the Services, and we do not seek or knowingly collect any personal information about children under 13 years of age. If we become aware that we have unknowingly collected information about a child under 13 years of age, we will make commercially reasonable efforts to delete such information from our database.
If you are the parent or guardian of a child under 13 years of age who has provided us with their personal information, you may contact us using the below information to request that it be deleted.

**9. DATA SECURITY**

Please note that any information you send to us electronically, while using the Services or otherwise interacting with us, may not be secure when it is transmitted to us. We recommend that you do not use unsecure channels to communicate sensitive or confidential information to us. Please be aware though that, despite our best efforts, no security measures are perfect or impenetrable, and we cannot guarantee “perfect security.” Any information you send us through any means is transmitted at your own risk.

**10. INTERNATIONAL USERS**
The Services are for individuals located in the United States, so if you access the Services from outside the United States, we want to remind you that our handling of information about you will be subject to United States data privacy laws. 

**11. HOW TO CONTACT US**
Should you have any questions about our privacy practices or this Privacy Policy, please email us at contacto@aztlan.market.
`;

export default function Terms() {
  const { t, i18n } = useTranslation();

  return (
    <ShrinkView>
      <h3 className="fw-bold mb-5 text-dark">{t("privacy_policy")}</h3>
      <ReactMarkdown
        className="mb-3 text-muted terms-md"
        remarkPlugins={[remarkGfm]}
      >
        {
          {
            en: PrivacyEN,
            es: PrivacyES,
          }[i18n.language]
        }
      </ReactMarkdown>
    </ShrinkView>
  );
}
