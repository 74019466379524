import { useGetBlogEntry } from "actions/Api";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Badge, Container, Row, Col } from "react-bootstrap";

import { FetcherComponent } from "components/Shared/Fetcher";
import { FormatedDateRelative } from "components/Shared/FormatedDate";
import { BlogBodyCard } from "components/Pages/Blog";
import ShrinkView from "components/Shared/ShrinkView";

function BlogEntryCard(props) {
  const {
    data: { content, image, slug },
  } = props;

  return (
    <ShrinkView>
      <BlogBodyCard {...props.data} />
      <div className="my-5 text-center">
        <img src={image} alt={slug} className="img-fluid rounded-3"></img>
        {/* <div>
              {tags.map((tag, index) => (
                <Badge key={index} className="bg-secondary mx-1">
                  {tag}
                </Badge>
              ))}
            </div> */}
      </div>
      <ReactMarkdown
        className="text-muted markdowned"
        children={content}
        remarkPlugins={[remarkGfm]}
      />
    </ShrinkView>
  );
}

export default function BlogEntry() {
  const { slug } = useParams();

  const query = useGetBlogEntry(slug);

  return (
    <FetcherComponent query={query}>
      <BlogEntryCard />
    </FetcherComponent>
  );
}
