import { useAppState } from "context/context";
import { InstructionsModals } from "constant";
import { useTranslation } from "react-i18next";

const APPROVE_NFT = () => {
  const { t } = useTranslation();
  return <div>{t("aprobar")}</div>;
};

const PUT_TO_SALE = () => {
  const { t } = useTranslation();
  return <div>{t("poner en venta")}</div>;
};

export default function InstructionalModal() {
  const {
    modalProps: { instruction },
  } = useAppState();

  switch (instruction) {
    case InstructionsModals.APPROVE_NFT:
      return <APPROVE_NFT />;
    case InstructionsModals.PUT_TO_SALE:
      return <PUT_TO_SALE />;
    default:
      return null;
  }
}
