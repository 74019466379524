import React, { useEffect } from "react";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import createPersistedState from "use-persisted-state";
const useConnectionState = createPersistedState("connectionState");

// function Redirect({ to }) {
//   let navigate = useNavigate();
//   useEffect(() => {
//     navigate(to);
//   });
//   return null;
// }

export const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isConnected] = useConnectionState();

  useEffect(() => {
    if (!isConnected) {
      navigate("/");
    }
  }, [isConnected]);

  if (!isConnected) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
};
