import { Card, Col } from "react-bootstrap";

export default function GalleryImageLoader() {
  return (
    <Col lg="3" md="4" sm="12">
      <Card className="bg-white border-0 pointer mb-4">
        <div>
          <div
            className="rounded loading"
            style={{
              height: "245px",
            }}
          />
          <div className="pt-3 px-2">
            <div
              className="rounded-md mb-3 loading"
              style={{ height: "20px" }}
            />
            <div className="mb-4 d-flex">
              <div>
                <div className="rounded-pill avatar-40 me-2 loading" />
              </div>
              <div className="w-100 align-self-center">
                <div
                  className="rounded-md mb-2 loading"
                  style={{ height: "15px" }}
                />
                <div
                  className="rounded-md loading"
                  style={{ height: "15px" }}
                />
              </div>
            </div>
            <div
              className="rounded-md mb-3 loading w-50"
              style={{ height: "15px" }}
            />
          </div>
        </div>
      </Card>
    </Col>
  );
}
