import React, { useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { Container, Row, Col, Card } from "react-bootstrap";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
// import { ReactComponent as EllipseIcon } from "assets/icons/ellipse.svg";
// import { ReactComponent as Cube } from "assets/icons/cube.svg";
// import { ReactComponent as DoubleCube } from "assets/icons/double-cube.svg";

const road = [
  {
    date: "2019 - Primer cuatrimestre",
    lead: "El nacimiento de la idea",
    text: "Se formo el equipo de desarollo y se realizo el planteamiento del problema.",
  },
  {
    date: "2019 - Segundo cuatrimestre",
    lead: "Whitepaper",
    text: "Se elaboro el Whitepaper, se hizo la selección de las herramientas tecnológicas y se creo la primer versión del Design System.",
  },
  {
    date: "2020 - Primer cuatrimestre",
    lead: "Ecosistema legal",
    text: "Se inicio el desarrollo de la plataforma y se analizó el ecosistema legal en México.",
  },
  {
    date: "2020 - Segundo cuatrimestre",
    lead: "Versión Alpha",
    text: "Liberación de versión Alpha de Aztlan, revisión global UX (Pruebas de usabilidad).",
  },
  {
    date: "2020 - Tercer cuatrimestre",
    lead: "Integración Multichain",
    text: "Integración Multichain e incorporación de transacciones off-chain.",
  },
  {
    date: "2021 - Primer cuatrimestre",
    lead: "Versión Beta",
    text: "Liberación Versión Beta y planeación del programa BugBounty.",
  },
  {
    date: "2021 - Segundo cuatrimestre",
    lead: "Posicionamiento de marca",
    text: "Inicio de campaña de Marketing y posicionamiento de marca (SEO).",
  },
  {
    date: "2021 - Tercer cuatrimestre",
    lead: "El inicio de Aztlan Market",
    text: "Puesta en producción y oferta inicial descentralizada del token (IDO).",
  },
  {
    date: "2022 - Primer cuatrimestre",
    lead: "Desarrollo móvil",
    text: "Desarrollar aplicación móvil para visualización de ERC721 y creación de programa de incentivos.",
  },
  {
    date: "2022 - Segundo cuatrimestre",
    lead: "Alianzas estratégicas",
    text: "Acercamiento con INDAUTOR y crecimiento del equipo de desarrollo.",
  },
  {
    date: "2022 - Tercer cuatrimestre",
    lead: "Expansión",
    text: "Busqueda alianzas en sector gobierno y sector privado, expansión en Latinoamérica",
  },
];

const CardRoadmap = ({ date, lead, text, on }) => {
  const { t } = useTranslation();
  return (
    <Card
      className={clsx(
        "flex-shrink-0 border-0 shadow-lg me-5 zoom",
        on && "zoom-hover"
      )}
      style={{ width: "400px", height: "272px" }}
    >
      <Card.Body className="p-5 user-select-none">
        <small className="text-primary d-block mb-2">{t(date)}</small>
        <p className="lead">{t(lead)}</p>
        <p className="text-muted mb-0">{t(text)}</p>
      </Card.Body>
    </Card>
  );
};

export default function RoadMap() {
  const [on, setOn] = useState();
  return (
    <section className="bg-white road-map mt-5">
      <Container>
        <h3>Aztlan Roadmap</h3>
      </Container>
      <Container fluid>
        <ScrollContainer
          className="scroll-container horizontal-scroll w-100 py-5 px-4"
          hideScrollbars={false}
          onStartScroll={() => setOn(true)}
          onEndScroll={() => setOn(false)}
        >
          {road.map((props, key) => (
            <CardRoadmap key={key} {...props} on={on} />
          ))}
        </ScrollContainer>
      </Container>
    </section>
  );
}
