import clsx from "clsx";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { ReactComponent as PhotographIcon } from "assets/icons/picture.svg";

export default function DropZone({ onDrop, text, className }) {
  const { t } = useTranslation();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <div
      className={clsx(
        "text-muted text-center p-5 bg-light rounded-3 pointer",
        className
      )}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <span>{t("Suelta los archivos")} ...</span>
      ) : (
        <div>
          <div className="my-2">
            <PhotographIcon />
          </div>
          <div>
            <span>{t(text || "PNG, GIF, WEBP, MP4 or MP3. Max 100Mb")}</span>
          </div>
        </div>
      )}
    </div>
  );
}
