import { useGetBlog } from "actions/Api";
import { useNavigate } from "react-router-dom";
import { Badge, Container, Card, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";

import { FetcherComponent } from "components/Shared/Fetcher";
import { FormatedDateRelative } from "components/Shared/FormatedDate";
import format from "date-fns/format";
import ProfileViewer from "components/Shared/ProfileViewer";
import { getLocale } from "utils";
import remarkGfm from "remark-gfm";

import BlogImg from "assets/blog-img.jpg";

function BlogCard(props) {
  const {
    data: {
      author,
      title,
      slug,
      image,
      createdAt = new Date(),
      description,
      tags = [],
    },
  } = props;
  const navigate = useNavigate();

  const { i18n } = useTranslation();

  const handleGoToBlogEntry = () => {
    navigate(`/blog/${slug}`);
  };
  return (
    <div
      className="text-white bg-dark-x d-flex pointer"
      onClick={handleGoToBlogEntry}
    >
      <div className="flex-shrink-0">
        <img src={image} alt="" width="200px"></img>
      </div>
      <div className="flex-grow-1 ms-3">
        <h3>{title}</h3>
        <span>{description}</span>
        <div>
          {tags.map((tag, index) => (
            <Badge key={index} className="bg-secondary mx-1">
              {tag}
            </Badge>
          ))}
        </div>
        <div className="mt-2 fw-bold">
          <span>
            {author} - <FormatedDateRelative date={createdAt} />
          </span>
        </div>
      </div>
    </div>
  );
}

function MainCard({ props }) {
  const navigate = useNavigate();
  const { slug, image } = props;

  const handleGoToBlogEntry = () => {
    navigate(`/blog/${slug}`);
  };
  return (
    <div
      onClick={handleGoToBlogEntry}
      className="d-flex flex-wrap pointer gap-5 mb-5"
    >
      <Col lg={7}>
        <img
          src={image}
          width={987}
          alt="main card "
          className="img-fluid rounded-3"
        />
      </Col>
      <Col lg={4} className="d-flex align-items-center">
        <div>
          <BlogBodyCard {...props} />
        </div>
      </Col>
    </div>
  );
}
export const BlogBodyCard = ({
  title,
  description,
  author,
  authorImage,
  createdAt,
}) => {
  const { i18n } = useTranslation();
  return (
    <>
      <h3 className="text-dark mb-3">{title}</h3>
      <ReactMarkdown
        className="mb-3 text-muted"
        children={description}
        remarkPlugins={[remarkGfm]}
      />
      <ProfileViewer
        username={author}
        imageUrl={authorImage}
        description={format(new Date(createdAt), "yyyy-MM-dd hh:mm", {
          locale: getLocale(i18n.language),
        }).toString()}
      />
    </>
  );
};
function BlogCard2({ props }) {
  const navigate = useNavigate();

  const { slug, image } = props;

  const handleGoToBlogEntry = () => {
    navigate(`/blog/${slug}`);
  };

  return (
    <Col lg={4} className="mb-5">
      <Card className="bg-transparent border-0 " onClick={handleGoToBlogEntry}>
        <img src={image} alt="card" className="mb-3 rounded-3 img-fluid" />
        <BlogBodyCard {...props} />
      </Card>
    </Col>
  );
}

function BlogView(props) {
  const { index, data } = props;
  return (
    <>{index === 0 ? <MainCard props={data} /> : <BlogCard2 props={data} />}</>
  );
}

const NewBlogEntry = ({ data: { title, description, image, slug }, goTo }) => (
  <Col lg={{ span: 4 }} className="g-4 pointer" onClick={() => goTo(slug)}>
    <img src={image} alt="" className="img-fluid rounded mb-4" />
    <p className="text-primary text-uppercase fw-bold mb-2">{title}</p>
    <ReactMarkdown
      children={description}
      className="lead fw-light mb-0 me-5"
      remarkPlugins={[remarkGfm]}
    />
  </Col>
);

export default function Blog() {
  const query = useGetBlog();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goTo = (slug) => navigate(`/blog/${slug}`);

  return (
    <section className="bg-white mt-5">
      <Container>
        <h3 className="mb-5">{t("Noticias Relevantes")}</h3>
        <Row className="">
          <FetcherComponent query={query}>
            <NewBlogEntry goTo={goTo} />
          </FetcherComponent>
        </Row>
      </Container>
    </section>
  );
}
