import { useAppState } from "context/context";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { isSameAddress } from "utils";
import { OPEN_BUY_MODAL, useNFT } from "./NFTContext";

export default function NFTControlsBuyer() {
  const { t } = useTranslation();

  const [
    {
      blockchainInfo: { minted, onSale, owner },
    },
    dispatch,
  ] = useNFT();

  const { address } = useAppState();

  const openModal = () => {
    dispatch({
      type: OPEN_BUY_MODAL,
    });
  };

  if (!minted || !onSale || isSameAddress(owner, address)) {
    return null;
  }

  return <Button className="w-100" onClick={openModal}>{t("Comprar")}</Button>;
}
