import { useGallery } from "actions/Api";
import { FetcherComponent } from "components/Shared/Fetcher";
import { galleryFilters } from "@aztlan/config";
import { Badge, Row } from "react-bootstrap";
import clsx from "clsx";
// import { useState } from 'react';
import GalleryImage from "components/Shared/GalleryImage";
import { useTranslation } from "react-i18next";
import GalleryImageLoader from "components/Shared/GalleryImageLoader";

function Filter({ filter, setSelectedFilter, selectedFilter }) {
  // const handleChangeFilter = () => {
  //     setSelectedFilter(filter)
  // }
  return (
    <Badge
      className={clsx(
        "mx-1 px-3 py-2 rounded-3 text-capitalize",
        filter === selectedFilter ? "bg-none" : "bg-dark-x"
      )}
      // onClick={handleChangeFilter}
    >
      {filter}
    </Badge>
  );
}

export default function Gallery() {
  // const [selectedFilter, setSelectedFilter] = useState(galleryFilters[0]);

  const query = useGallery({ featured: true });

  const { t } = useTranslation();

  return (
    <div>
      {/* <div className="my-4">
                <Badge>
                    {t('Categorías')}
                </Badge>
                {galleryFilters.map((filter, index) =>
                    <Filter
                        filter={t(filter)}
                        key={index}
                    />
                )}
            </div> */}
      <Row className="">
        <FetcherComponent
          query={query}
          loadingComponent={
            <Row>
              {[...Array(4)].map((val, key) => (
                <GalleryImageLoader key={key} />
              ))}
            </Row>
          }
        >
          <GalleryImage
            md={6}
            lg={4}
            xl={3}
            sm={12}
            xs={12}
            className="mb-4"
            fixedheight="true"
          />
        </FetcherComponent>
      </Row>
    </div>
  );
}
