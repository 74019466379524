import { useEffect, useState } from "react";

export default function useDropZonePreview() {
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [files, setFiles] = useState([]);

  const setFree = () =>
    files.forEach((file) => URL.revokeObjectURL(file.preview));

  useEffect(() => {
    if (acceptedFiles.length) {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    }
    return () => {
      setFree();
    };
  }, [acceptedFiles]);

  const reset = () => {
    setFiles([]);
    setAcceptedFiles([]);
    setFree();
  };

  return [files, setAcceptedFiles, reset];
}
