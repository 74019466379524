import { CHAIN_ID } from "@aztlan/config";
import { useAppState } from "context/context";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ReactComponent as PolygonIcon } from "assets/icons/polygon.svg";
import { ReactComponent as BSCIcon } from "assets/icons/bsc.svg";
import { ReactComponent as EthereumIcon } from "assets/icons/eth.svg";
import { fillColor } from "@aztlan/config";

const primaryColor = "#0b6dff";

export default function ChainNotSupportedModal() {
  const { addNetwork } = useAppState();

  const { t } = useTranslation();

  const handleConnectToPolygon = () => {
    addNetwork(CHAIN_ID.POLYGON);
  };
  const handleConnectToBSC = () => {
    addNetwork(CHAIN_ID.BSC);
  };
  const handleConnectToBSCTestnet = () => {
    addNetwork(CHAIN_ID.BSCTest);
  };
  const handleConnectToHardHat = () => {
    addNetwork(CHAIN_ID.HARDHAT);
  };
  const handleConnectToMainnet = () => {
    addNetwork(CHAIN_ID.MAINNET);
  };
  const handleConnectToMumbai = () => {
    addNetwork(CHAIN_ID.POLYGONMUMBAI);
  };

  return (
    <div>
      <div className="mb-4">
        <h5>{t("Red no soportada")}</h5>
        <label className="text-muted">{t("Selecciona otra red")} </label>
      </div>
      <div className="mb-4">
        <p className="fw-bold mb-2">Mainnets</p>
        <ul className="list-unstyled mb-0">
          <li>
            <Button
              variant="link"
              className="me-2 ps-0 text-decoration-none"
              onClick={handleConnectToMainnet}
              disabled
            >
              <EthereumIcon fill={fillColor} height="20px" className="me-2" />
              Ethereum
            </Button>
          </li>

          <li>
            <Button
              variant="link"
              className="me-2 ps-0 text-decoration-none"
              onClick={handleConnectToPolygon}
            >
              <PolygonIcon fill={primaryColor} height="20px" className="me-2" />
              Polygon
            </Button>
          </li>
          <li>
            <Button
              variant="link"
              className="me-2 ps-0 text-decoration-none"
              onClick={handleConnectToBSC}
            >
              <BSCIcon fill={primaryColor} height="20px" className="me-2" />
              BSC
            </Button>
          </li>
        </ul>
      </div>

      <div>
        <p className="fw-bold mb-2">{t("Testnets")}</p>
        <ul className="list-unstyled mb-0">
          <li>
            <Button
              variant="link"
              className="me-2 ps-0 text-decoration-none"
              onClick={handleConnectToMumbai}
            >
              <PolygonIcon fill={primaryColor} height="20px" className="me-2" />
              Mumbai
            </Button>
          </li>
          {/* <li>
            <Button
              variant="link"
              className="me-2 text-decoration-none"
              onClick={handleConnectToBSCTestnet}
            >
              <BSCIcon fill={fillColor} height="20px" className="me-2" />
              BSC Testnet
            </Button>
          </li>
          <li>
            <Button
              variant="link"
              className="me-2 text-decoration-none"
              onClick={handleConnectToHardHat}
            >
              <BSCIcon fill={fillColor} height="20px" className="me-2" />
              HardHat
            </Button>
          </li> */}
        </ul>
        <div className="mt-4 text-center">
          <small className="text-muted">
            {t(
              "se encuentra en fase beta, por el momento solo es posible conectarse a una testnet"
            )}
            .
          </small>
        </div>
      </div>
    </div>
  );
}
