import { useTranslation } from "react-i18next";
import { getLocale } from "utils";
import formatRelative from "date-fns/formatRelative";
import format from "date-fns/format";

export function FormatedDateRelative({ date = new Date() }) {
  const { i18n } = useTranslation();

  return formatRelative(new Date(date), new Date(), {
    locale: getLocale(i18n.language),
  });
}

export const FormatDate = ({ date = new Date() }) => {
  const { i18n } = useTranslation();
  return format(new Date(date), "PPpp", {
    locale: getLocale(i18n.language),
  }).toString();
};
