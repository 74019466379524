// import { ReactComponent as LinkedinIcon } from "assets/linkedin.svg";
// import { ReactComponent as FacebookIcon } from "assets/facebook.svg";
// import { ReactComponent as InstagramIcon } from "assets/instagram.svg";
// import { ReactComponent as DiscordIcon } from "assets/discord.svg";
// import { ReactComponent as RedditIcon } from "assets/reddit.svg";
// import { ReactComponent as TwitterIcon } from "assets/twitter.svg";
// import { ReactComponent as MediumIcon } from "assets/medium.svg";
// import { ReactComponent as TelegramIcon } from "assets/telegram.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getRandom, removeSpecialCharacters } from "utils";
import { useState } from "react";
import clsx from "clsx";

const socialNetworks = {
  medium: { href: `https://medium.com/@`, icon: "medium" },
  discord: { href: `https://discord.gg/`, icon: "discord" },
  facebook: { href: `https://facebook.com/`, icon: "facebook" },
  twitter: { href: `https://twitter.com/`, icon: "twitter" },
  instagram: { href: `https://instagram.com/`, icon: "instagram" },
  reddit: { href: `https://www.reddit.com/r/`, icon: "reddit" },
  telegram: { href: `https://t.me/`, icon: "telegram" },
  linkedin: { href: `https://linkedin.com/`, icon: "linkedin" },
};

export default function SocialsBar({ className, ...props }) {
  const [rand] = useState(getRandom());

  return Object.keys(props).map((networkName, key) => {
    const network = socialNetworks[networkName];
    if (!network) return null;
    const networkValue = props[networkName];
    if (!networkValue) return null;
    const selector = removeSpecialCharacters(props[networkName])
      .split("/")
      .pop();
    const id = `${networkName}-${rand}-${selector}`;
    return (
      // <OverlayTrigger
      //   key={key}
      //   transition={false}
      //   overlay={
      //     <Tooltip id={id}>
      //       <b className="text-capitalize">{networkName}</b>
      //       <br />
      //       {networkValue}
      //     </Tooltip>
      //   }
      // >
      // {({ ref, ...triggerHandler }) => (
      <a
        key={key}
        id={id}
        href={`${network.href}${networkValue}`}
        rel="noreferrer"
        target="_blank"
        // ref={ref}
        className={clsx("hoverable", className)}
        // {...triggerHandler}
      >
        <div className={clsx("icon-svg", `icon-${network.icon}`)} />
      </a>
      // )}
      // </OverlayTrigger>
    );
  });
}
