import clsx from "clsx";
import { Badge } from "react-bootstrap";

export default function BadgeSelector({
  children,
  selected,
  name,
  className,
  handleSelect,
  ...props
}) {
  return (
    <Badge
      onClick={handleSelect}
      bg={selected ? "light" : "white"}
      text={selected ? "dark" : "muted"}
      className={clsx(
        className,
        "pointer mx-1 px-3 py-2 rounded-1 text-capitalize",
        selected && "fw-bold"
      )}
      {...props}
    >
      {children}
    </Badge>
  );
}
