import { useEffect, useState } from "react";
import { Form, Container, Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useGetRemainingBalance, useWithdrawBids } from "actions/Api";
import { useAppState } from "context/context";
import { utils, BigNumber } from "ethers";
import { networks } from "@aztlan/config";
import { useForm } from "react-hook-form";
import LoadingButton from "components/Shared/LoadingButton";
import { toast } from "components/Shared/Toast";

export default function Balances() {
  const { register, watch } = useForm();

  const [selectedCurrency, setSelectedCurrency] = useState();

  const { chainId, address } = useAppState();

  const { t } = useTranslation();

  const { mutate, isLoading: isRecovering } = useWithdrawBids();

  const currency = watch("currency");

  const otherCurrency = watch("otherCurrency");

  const {
    data: { balance = "0000" } = {},
    isLoading,
    refetch,
  } = useGetRemainingBalance({
    currency: selectedCurrency,
    address,
  });

  const handleRecoverCrypto = () => {
    mutate(
      { currency: selectedCurrency },
      {
        onSuccess: () => {
          refetch();
          toast.success(`${t("Retirado")} ${utils.parseUnits(balance, 18)}`);
        },
      }
    );
  };

  useEffect(() => {
    setSelectedCurrency();
  }, [currency, otherCurrency]);

  const handleViewBalance = () => {
    if (currency === "other" && !utils.isAddress(otherCurrency)) {
      toast.error(t("not_valid_address"));
    } else {
      setSelectedCurrency(currency === "other" ? otherCurrency : currency);
    }
  };

  return (
    <div>
      <h5 className="mb-3">{t("Retirar")}</h5>
      <Form>
        <Form.Group className="mb-4">
          <Form.Label className="text-muted">{t("currency")}</Form.Label>
          <Form.Select size="lg" {...register("currency")}>
            <option value="none">{t("Selecciona una moneda")}</option>
            {networks[chainId]?.BEP20S?.map(({ address, symbol }) => (
              <option value={address} key={symbol}>
                {symbol}
              </option>
            ))}
            <option value="other">{t("other")}</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-4" hidden={currency !== "other"}>
          <Form.Label>{t("Dirección")}</Form.Label>
          <Form.Control
            className="bg-dark"
            size="lg"
            {...register("otherCurrency")}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-4" hidden={balance === "0000"}>
          <Form.Label>{t("Balance")}</Form.Label>
          <h3 className="text-center text-muted">
            {utils.formatUnits(balance).toString()}
          </h3>
        </Form.Group>
        <Form.Group>
          <LoadingButton
            disabled={selectedCurrency || currency === "none" || !currency}
            onClick={handleViewBalance}
            loading={isLoading}
            className="w-100 text-center"
            loadingtext={t("fetching balance")}
            hidden={BigNumber.from(balance).gt(BigNumber.from("0"))}
          >
            {t("View Balance")}
          </LoadingButton>
          <LoadingButton
            className="w-100 text-center"
            onClick={handleRecoverCrypto}
            loading={isRecovering}
            hidden={BigNumber.from(balance).lte(BigNumber.from("0"))}
          >
            {t("Recover")}
          </LoadingButton>
        </Form.Group>
      </Form>
    </div>
  );
}
