import { SET_MODAL, useApp } from "context/context";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "components/Shared/Toast";
import { Button, Form } from "react-bootstrap";

export default function UseLocalModal() {
  const [{ connectLocal }, dispatch] = useApp();

  const privateKeyRef = useRef();

  const { t } = useTranslation();

  const toggleShow = () => dispatch({ type: SET_MODAL, modal: null });

  const handleSetAddress = () => {
    const privateKey = privateKeyRef.current.value;
    connectLocal({ privateKey });
    toast.success(t("connected"));
    toggleShow();
  };

  return (
    <div>
      <div>
        <h4 className="text-center">{t("connect_secret_key")}</h4>
        <div className="my-4">
          <Form.Control
            className="bg-dark text-center d-flex"
            ref={privateKeyRef}
            placeholder={t("type_private_key")}
          />
        </div>
      </div>
      <Button className="w-100" onClick={handleSetAddress}>
        {t("connect")}
      </Button>
    </div>
  );
}
