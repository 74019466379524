import React, {
  useContext,
  useReducer,
  createContext,
  useEffect,
  useCallback,
  useState,
} from "react";
import produce from "immer";
import { Contract, providers } from "ethers";
import { toast } from "components/Shared/Toast";
import detectEthereumProvider from "@metamask/detect-provider";
import { BscConnector } from "@binance-chain/bsc-connector";
import createPersistedState from "use-persisted-state";
import { useTranslation } from "react-i18next";
import NFTController from "@aztlan/blockchain/artifacts/contracts/NFTController.sol/NFTController.json";
import { CHAIN_ID, networks, WALLETS } from "@aztlan/config";
import { useGetProfile } from "actions/Api";
import Torus from "@toruslabs/torus-embed";
import { modals } from "constant";

const useConnectionState = createPersistedState("connectionState");
const useCurrencyState = createPersistedState("currency");

const AppStateContext = createContext();
const AppDispatchContext = createContext();

export const TOGGLE_RIGHT_BAR = "TOGGLE_RIGHT_BAR";
export const SET_MODAL = "SET_MODAL";
export const RESET = "RESET";
export const SET_PROVIDER = "SET_PROVIDER";
export const SET_PROVIDER_ONLY = "SET_PROVIDER_ONLY";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const TOGGLE_EDIT_PROFILE = "TOGGLE_EDIT_PROFILE";
export const TOGGLE_THEME = "TOGGLE_THEME";
export const CLOSE_EDIT_PROFILE = "CLOSE_EDIT_PROFILE";

// const PROVIDER_URL = process.env.REACT_APP_PROVIDER_URL;

const initialState = {
  walletConnected: null,
  nftController: null,
  wallet: null,
  nativeCurrency: "ETH",
  address: null,
  active: false,
  username: "",
  imageUrl: "",
  modalProps: {},
  modal: null,
  rightBar: false,
  editingProfile: false,
  theme: "light",
  rightBarProps: {},
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case TOGGLE_THEME:
      draft.theme = draft.theme === "dark" ? "light" : "dark";
      break;
    case TOGGLE_EDIT_PROFILE:
      draft.editingProfile = !draft.editingProfile;
      break;
    case CLOSE_EDIT_PROFILE:
      draft.editingProfile = false;
      break;
    case SET_PROVIDER_ONLY:
      draft.provider = action.provider;
      break;
    case SET_PROVIDER:
      draft.provider = action.provider;
      draft.chainId = action.chainId;
      draft.address = action.address;
      draft.wallet = action.wallet;
      draft.nftController = action.nftController;
      draft.nativeCurrency = networks[action.chainId].nativeCurrency.symbol;
      draft.active = true;
      draft.web3Provider = action.web3Provider;
      draft.connector = action.connector;
      break;
    case SET_MODAL:
      draft.modal = action.modal;
      draft.modalBody = action.modalBody;
      if (action.modal || action.modalBody) {
        draft.modalProps = action.modalProps;
        draft.modalSize = action.modalSize;
      } else {
        draft.modalProps = {};
        draft.modalBody = null;
        draft.modalSize = "lg";
      }
      break;
    case UPDATE_PROFILE:
      draft.username = action.username;
      draft.imageUrl = action.imageUrl;
      draft.verified = action.verified;
      break;
    case TOGGLE_RIGHT_BAR:
      draft.rightBar = !draft.rightBar;
      if (draft.rightBar) {
        draft.rightBarProps = action.rightBarProps;
      } else {
        draft.rightBarProps = {};
      }
      break;
    case RESET:
      return { ...initialState };
    default:
      return;
  }
}, {});

const bsc = new BscConnector({
  supportedChainIds: Object.values(CHAIN_ID),
});

function AppProvider({ children, mockState = {} }) {
  const [listening, setListening] = useState([]);

  useCurrencyState("MXN");

  const { t } = useTranslation();

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...mockState,
  });

  const [walletConnected, setWalletConnected] = useConnectionState(null);

  const addNetwork = useCallback(
    (_chainId) => {
      const { chainName, nativeCurrency, rpcUrls, blockExplorerUrls, chainId } =
        networks[_chainId] || {};
      state.provider
        .request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName,
              nativeCurrency,
              rpcUrls,
              blockExplorerUrls,
              chainId,
            },
          ],
        })
        .then((a) => {
          connectWallet();
        })
        .catch((error) => {
          toast.warning(t("RejectedWalletChange"));
        });
    },
    [state.provider]
  );

  const connectBSC = async () => {
    try {
      const { provider } = await bsc.activate();
      return [provider];
    } catch (error) {
      setWalletConnected(null);
      dispatch({
        type: SET_MODAL,
        modal: "INSTALL_BSC_WALLET",
      });
      return [];
    }
  };

  const connectMetamask = async () => {
    try {
      const provider = await detectEthereumProvider({
        timeout: 4000,
        silent: true,
      });
      if (!provider) throw new Error("No provider");
      return [provider];
    } catch (error) {
      setWalletConnected(null);
      dispatch({
        type: SET_MODAL,
        modal: "INSTALL_METAMASK_WALLET",
      });
      return [];
    }
  };

  const connectTorus = async () => {
    try {
      const torus = new Torus({});

      await torus.init({
        enableLogging: false,
        network: { host: "bsc_mainnet" },
      });

      await torus.login();

      const provider = torus.provider;

      return [provider, torus];
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      return [];
    }
  };

  const connectWallet = async (walletName, firstTime) => {
    try {
      let connectFunction;

      if (walletName === WALLETS.BSC) {
        connectFunction = connectBSC;
      } else if (walletName === WALLETS.METAMASK) {
        connectFunction = connectMetamask;
      } else if (walletName === WALLETS.TORUS) {
        connectFunction = connectTorus;
      }

      if (!connectFunction) return;

      const [provider, connector] = await connectFunction();

      if (!provider) return;

      if (walletName === "METAMASK" && firstTime && !provider.chainId) {
        return window.location.reload();
      }

      await provider.request({ method: "eth_requestAccounts" });

      const web3Provider = new providers.Web3Provider(provider);

      const chainId = Number(
        await provider.request({
          method: "eth_chainId",
        })
      );

      dispatch({
        type: SET_PROVIDER_ONLY,
        provider,
      });

      const supportedNetwork = networks[chainId];

      setWalletConnected(walletName);

      if (supportedNetwork?.NFTController) {
        toast.info(`${t("connected_to")} ${supportedNetwork.chainName}`);
      } else {
        throw new Error("Chain Not Supported");
      }

      const accounts = await web3Provider.listAccounts();

      const signer = web3Provider.getSigner();

      const nftController = new Contract(
        supportedNetwork?.NFTController,
        NFTController.abi,
        signer
      );

      dispatch({
        type: SET_PROVIDER,
        web3Provider,
        connector,
        provider,
        chainId,
        address: accounts[0],
        wallet: signer,
        nftController,
      });

      dispatch({ type: SET_MODAL, modal: null });
    } catch (err) {
      toast.warn(err?.message);
      if (err.message === "Chain Not Supported") {
        dispatch({ type: SET_MODAL, modal: modals.CHAIN_NOT_SUPPORTED });
      } else {
        setWalletConnected(null);
      }
    }
  };

  useEffect(() => {
    if (walletConnected) {
      connectWallet(walletConnected);
    }
  }, []);

  useEffect(() => {
    if (state.provider && !listening.includes(walletConnected)) {
      state.provider.on("accountsChanged", function (accounts) {
        connectWallet(walletConnected);
      });
      state.provider.on("chainChanged", function (chainId) {
        connectWallet(walletConnected);
      });
      state.provider.on("disconnect", function (error) {
        disconnectWallet();
      });
      setListening([...listening, walletConnected]);
    }
  }, [state.provider, walletConnected]);

  useGetProfile(state.address, {
    enabled: state.active,
    onSuccess: ({ username, imageUrl, verified } = {}) => {
      dispatch({ type: UPDATE_PROFILE, username, imageUrl, verified });
    },
    refetchOnWindowFocus: !state.editingProfile,
  });

  const disconnectWallet = () => {
    state.connector?.logout?.();
    dispatch({ type: RESET });
    setWalletConnected(null);
  };

  const connectLocal = ({ privateKey }) => {
    // try {
    //   const wallet = new Wallet(privateKey, getDefaultProvider());
    //   dispatch({
    //     type: CHANGE_WALLET,
    //     wallet: wallet,
    //     address: wallet.address,
    //   });
    // } catch (err) {
    //   toast.error("not_valid_key");
    // }
  };

  return (
    <AppStateContext.Provider
      value={{
        ...state,
        connectWallet,
        disconnectWallet,
        addNetwork,
      }}
    >
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

function useAppState() {
  const context = useContext(AppStateContext);
  if (context === undefined) {
    throw new Error("useAppState must be used within a AppProvider");
  }
  return context;
}

function useAppDispatch() {
  const context = useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error("useAppDispatch must be used within a AppProvider");
  }
  return context;
}

function useApp() {
  return [useAppState(), useAppDispatch()];
}

export { AppProvider, useApp, useAppState, useAppDispatch };
