import { useUpload, useUploadHash } from "actions/Api";
import DropZone from "components/Shared/DropZone";
import { useApp } from "context/context";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  OverlayTrigger,
  Tooltip,
  ListGroup,
} from "react-bootstrap";
import useDropZonePreview from "hooks/useDropZonePreview";
import ExclamationIcon from "@heroicons/react/outline/ExclamationIcon";
import ShieldCheckIcon from "@heroicons/react/outline/ShieldCheckIcon";
import { toast } from "components/Shared/Toast";
import { categoryTags } from "constant";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import BadgeSelector from "components/Shared/BadgeSelector";
import GalleryImage from "components/Shared/GalleryImage";
import LoadingButton from "components/Shared/LoadingButton";

const MintForm = ({
  t,
  handleSubmit,
  onSubmit,
  handleDrop,
  control,
  thumbs,
  isValid,
  files,
  loading,
  resetDrop,
  setValue,
  selectedTags,
}) => (
  <section className="bg-white mt-5">
    <Container>
      <Row>
        <Col
          lg={{ span: 5, offset: 1, order: 0 }}
          sm={{ order: 1 }}
          xs={{ order: 1 }}
        >
          <h3 className="fw-bold">{t("Crea tu NFT")}</h3>
          <p className="text-muted mb-5">
            {t("Para crear tu NFT llena los siguientes campos")}
          </p>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-5">
              <Form.Label
                className="mb-3 text-dark fw-bold"
                htmlFor="exampleEmail"
              >
                {t("Carga tu archivo")}*
              </Form.Label>
              {!files.length ? (
                <DropZone onDrop={handleDrop} files={files} />
              ) : (
                <div>
                  <Button
                    disabled={loading}
                    onClick={resetDrop}
                    variant="secondary"
                  >
                    {t("Cargar de nuevo")}
                  </Button>
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-5">
              <Form.Label className="mb-2 fw-bold text-dark" htmlFor="name">
                {t("Nombre del nft")}*
              </Form.Label>
              <Controller
                control={control}
                name="name"
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.Control
                    size="lg"
                    disabled={loading}
                    placeholder={t("Ingresar nombre")}
                    {...field}
                  />
                )}
              />
            </Form.Group>
            <Form.Group className="mb-5">
              <Form.Label
                className="mb-2 text-dark fw-bold"
                htmlFor="description"
              >
                {t("Descripción")}*
              </Form.Label>
              <Controller
                control={control}
                name="description"
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.Control
                    size="lg"
                    disabled={loading}
                    placeholder={t("Ingresar descripción")}
                    type="textarea"
                    {...field}
                  />
                )}
              />
            </Form.Group>
            <Form.Group className="mb-5">
              <Form.Label
                className="mb-2 text-dark fw-bold"
                htmlFor="externalLink"
              >
                {t("Link externo")}
                {/* <span className="text-muted">({t("optional")})</span> */}
              </Form.Label>
              <Controller
                control={control}
                name="externalLink"
                defaultValue=""
                render={({ field }) => (
                  <Form.Control
                    size="lg"
                    placeholder="https://demogallery.com/picasso.png"
                    type="text"
                    disabled={loading}
                    {...field}
                  />
                )}
              />
            </Form.Group>
            <Form.Group className="mb-5">
              <Form.Label className="fw-bold text-dark" htmlFor="categories">
                {t("Categorias")}
              </Form.Label>
              <div>
                {categoryTags.map((category, key) => (
                  <BadgeSelector
                    className="mt-2 border"
                    key={key}
                    handleSelect={() => {
                      if (loading) return;
                      setValue(`tags.${category}`, !selectedTags?.[category]);
                    }}
                    selected={selectedTags?.[category]}
                  >
                    {t(category)}
                  </BadgeSelector>
                ))}
              </div>
            </Form.Group>
            <Form.Group className="mb-5">
              <Form.Label className="mb-3 fw-bold text-dark" htmlFor="options">
                {t("Opciones")}
              </Form.Label>
              <ListGroup variant="flush" className="border rounded p-4">
                <ListGroup.Item
                  variant="light"
                  className="d-flex justify-content-between align-items-start pt-0 pb-4 border-bottom"
                >
                  <div>
                    <ShieldCheckIcon className="me-2" height="20px" />
                  </div>
                  <div className="me-auto">
                    <div
                      className="text-dark fw-bold"
                      htmlFor="hashOnly"
                      id="hashOnlyHelp"
                    >
                      {t("hashOnly")}
                    </div>
                    <small className="text-muted"> {t("hash_only_help")}</small>
                  </div>

                  <div className="form-check form-switch ms-2">
                    <Controller
                      control={control}
                      name={"hashOnly"}
                      disabled={loading}
                      render={({ field }) => (
                        <input
                          disabled={loading}
                          className="form-check-input bg-light"
                          type="checkbox"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  variant="light"
                  className="d-flex justify-content-between align-items-start pt-4 pb-0"
                >
                  <div>
                    <ExclamationIcon className="me-2" height="20px" />
                  </div>
                  <div className="me-auto">
                    <div
                      className="text-dark fw-bold"
                      htmlFor="nsfw"
                      id="hashOnlyHelp"
                    >
                      {t("nsfw")}
                    </div>
                    <small className="text-muted"> {t("nsfw_help")}</small>
                  </div>

                  <div className="form-check form-switch ms-2">
                    <Controller
                      control={control}
                      name={"nsfw"}
                      disabled={loading}
                      render={({ field }) => (
                        <input
                          disabled={loading}
                          className="form-check-input bg-light"
                          type="checkbox"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </Form.Group>
            <LoadingButton
              type="submit"
              loading={loading}
              disabled={!isValid || !files.length}
              className="d-flex align-self-center align-items-center"
              loadingtext={t("Creando nft")}
            >
              {t("Crear NFT")}
            </LoadingButton>
          </Form>
        </Col>
        <Col
          lg={{ span: 4, offset: 1, order: 1 }}
          sm={{ order: 0 }}
          xs={{ order: 0 }}
          className="mb-xs-3"
        >
          <div className="sticky-lg-top" style={{ top: "128px" }}>
            <Form.Group className="mb-4">
              <Form.Label className="mb-3 fw-bold" htmlFor="preview">
                {t("Vista previa")}
              </Form.Label>
              <aside>{thumbs}</aside>
            </Form.Group>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

const EmptyThumbnail = ({ t }) => (
  <div className="p-3 text-muted rounded-3 text-center border">
    <div className="bg-light px-lg-4  py-lg-7 p-7 rounded-3">
      {t("Carga tu archivo para mostrar la vista previa de tu NFT")}
    </div>
  </div>
);

export default function Mint() {
  const [files, handleDrop, resetDrop] = useDropZonePreview();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [{ address, username, imageUrl, verified }] = useApp();

  const { mutate: upload, isLoading } = useUpload();

  const { mutate: uploadHash, isLoading: isUploadingHash } = useUploadHash();

  const {
    control,
    handleSubmit,
    formState: { isValid, errors, isDirty },
    setValue,
    watch,
  } = useForm({ mode: "all" });

  const selectedTags = watch("tags");

  const description = watch("description");

  const nsfw = watch("nsfw");

  const hashOnly = watch("hashOnly");

  const name = watch("name");

  const queryClient = useQueryClient();

  const onSuccess = ({ sha256 }) => {
    toast.success(t("executed_success"));
    navigate(`/perfil/${address}`);
    navigate({ search: `?nft=${sha256}` });
    queryClient.invalidateQueries(["infinity-gallery"]);
  };

  const onSubmit = ({ description, name, tags = [], hashOnly, nsfw }) => {
    if (hashOnly) {
      uploadHash(
        {
          files,
          description,
          name,
          nsfw,
          tags: Object.keys(tags).filter((k) => tags[k] === true),
        },
        {
          onSuccess,
        }
      );
    } else {
      upload(
        {
          files,
          address,
          action: "mint-art",
          metadata: {
            description,
            name,
            nsfw,
            tags: Object.keys(tags).filter((k) => tags[k] === true),
          },
        },
        {
          onSuccess,
        }
      );
    }
  };

  const thumbs = files.length ? (
    files.map((file, key) => (
      <GalleryImage
        key={key}
        data={{
          hashOnly,
          file,
          description,
          name,
          owner: address,
          author: address,
          nsfw,
        }}
      />
    ))
  ) : (
    <EmptyThumbnail t={t} />
  );

  return (
    <MintForm
      t={t}
      handleDrop={handleDrop}
      control={control}
      handleSubmit={handleSubmit}
      isValid={isValid}
      errors={errors}
      files={files}
      isDirty={isDirty}
      onSubmit={onSubmit}
      thumbs={thumbs}
      loading={isLoading || isUploadingHash}
      resetDrop={resetDrop}
      setValue={setValue}
      selectedTags={selectedTags}
    />
  );
}
