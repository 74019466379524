import clsx from "clsx";

export const CustomCheckbox = (props) => (
  <div className={clsx("form-check", props.className)}>
    {props?.icon}{" "}
    <input
      type="checkbox"
      value=""
      id={props.label}
      {...props}
      className="form-check-input pointer"
    />
    <small className="form-check-label" htmlFor={props.label}>
      {props.label}
    </small>
  </div>
);
