import "react-datepicker/dist/react-datepicker.css";
import "styles/custom.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import "./i18n";
import Main from "components/Main";
import { AppProvider } from "context/context";

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <Main />
      </AppProvider>
    </QueryClientProvider>
  );
}
