import { Col, Container, Row } from "react-bootstrap";

export default function ShrinkView({ children }) {
  return (
    <section className="bg-white">
      <Container className="mt-5">
        <Row className="d-flex flex-column align-items-center">
          <Col  lg={{ span: 6 }}>{children}</Col>
        </Row>
      </Container>
    </section>
  );
}
