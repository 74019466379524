import { Button } from "react-bootstrap";
import { Loading } from "components/Shared/Fetcher";
import clsx from "clsx";

export default function LoadingButton({
  loading,
  loadingtext,
  disabled,
  className,
  children,
  ...props
}) {
  return (
    <Button
      {...props}
      disabled={disabled || loading}
      className={clsx("text-center", className)}
    >
      <div className="d-flex align-self-center align-items-center m-0 p-0 align-middle justify-content-center">
        <div
          className="spinner-border spinner-border-sm text-white me-2"
          hidden={!loading}
        />
        <div>{loading && loadingtext ? loadingtext : children}</div>
      </div>
    </Button>
  );
}
