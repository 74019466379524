import { useFetchStats } from "actions/Api";
// import ParentSize from "@visx/responsive/lib/components/ParentSize";
// import AreaChart from "components/Graphs/AreaChart";
import { FetcherComponent } from "components/Shared/Fetcher";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";

export default function Stats() {
  // Developers & roadmap
  const query = useFetchStats();

  const { t } = useTranslation();

  return (
    <section className="bg-white">
      <Container>
        <h3>{t("Volumen de transacciones")}</h3>
        <p className="text-muted mb-0">Información no disponible</p>
      </Container>

      {/*  <div style={{ height: "400px" }}>
          <ParentSize>
            {({ width, height }) => (
              <FetcherComponent query={query}>
                <AreaChart width={width} height={height} />
              </FetcherComponent>
            )}
          </ParentSize>
        </div> */}
    </section>
  );
}
