import { SET_MODAL, useApp } from "context/context";
import { Wallet } from "ethers";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "components/Shared/Toast";
import { Button } from "react-bootstrap";
import { ReactComponent as ClipboardCopyIcon } from "assets/icons/clipboard.svg";
import { primaryColor } from "@aztlan/config";

export default function CreateLocalModal() {
  const [{ connectLocal }, dispatch] = useApp();

  const { t } = useTranslation();

  const toggleShow = () => dispatch({ type: SET_MODAL, modal: null });
  const [state, setState] = useState({});

  const handleSetAddress = () => {
    connectLocal(state);
    toggleShow();
  };
  useEffect(() => {
    const { address, privateKey, mnemonic } = Wallet.createRandom();
    setState({ address, privateKey, mnemonic });
  }, []);

  const handleCopyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    toast.info(t("Copiado al portapapeles"));
  };

  return (
    <div className="text-center">
      <div className="mb-3">
        <h4 className="text-dark">{t("attention")}</h4>
        <small className="text-muted">
          <Trans i18nKey="save_instructions" components={{ b: <strong /> }} />
        </small>
      </div>
      <div>
        <div>{t("address")}</div>
        <div>
          <div className="mb-3 ">
            {state.address ? (
              <div
                className="text-muted me-1 d-flex align-items-center pointer justify-content-center"
                onClick={() => handleCopyToClipboard(state.address)}
              >
                {state.address}
                <ClipboardCopyIcon
                  height="16px"
                  fill={primaryColor}
                  className="me-1"
                />
              </div>
            ) : (
              `${t("generating")}...`
            )}
          </div>
        </div>

        <div>{t("private_key")}</div>
        <div>
          <div className="mb-3 ">
            {state.privateKey ? (
              <div
                className="text-muted d-flex align-items-center pointer justify-content-center"
                onClick={() => handleCopyToClipboard(state.privateKey)}
              >
                {state.privateKey}
                <ClipboardCopyIcon
                  height="16px"
                  fill={primaryColor}
                  className="me-1"
                />
              </div>
            ) : (
              `${t("generating")}...`
            )}
          </div>
        </div>

        <div>{t("mnemonic_key")}</div>
        <div>
          <div className="mb-5">
            {state.mnemonic ? (
              <div
                className="text-muted me-1 d-flex align-items-center pointer justify-content-center"
                onClick={() => handleCopyToClipboard(state.mnemonic?.phrase)}
              >
                {state.mnemonic?.phrase}
                <ClipboardCopyIcon
                  height="16px"
                  fill={primaryColor}
                  className="me-1"
                />
              </div>
            ) : (
              `${t("generating")}...`
            )}
          </div>
        </div>
      </div>
      <Button
        className="w-100 my-2"
        onClick={handleSetAddress}
        disabled={!state.privateKey}
      >
        {t("i_have_saved")}
      </Button>
    </div>
  );
}
