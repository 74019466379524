import { Modal } from "react-bootstrap";
import clsx from "clsx";

import { SET_MODAL, useApp } from "context/context";
import ConnectWalletModal from "components/Modals/ConnectWalletModal";
import WarningConnectModal from "components/Modals/WarningConnectModal";
import UseLocalModal from "components/Modals/UseLocalModal";
import CreateLocalModal from "components/Modals/CreateLocalModal";
import ReportModal from "components/Modals/ReportModal";
import InstallMetamaskModal from "components/Modals/InstallMetamaskModal";
import InstallBSCModal from "components/Modals/InstallBSCModal";
import InstructionalModal from "components/Modals/InstructionalModal";
import ChainNotSupportedModal from "components/Modals/ChainNotSupportedModal";
import WithdrawModal from "components/Pages/Balances";
import VerifyModal from "components/Modals/VerifyModal";
import { modals } from "constant";

export const ModalContainer = (props) => {
  const { className } = props;

  const [{ modal, modalSize = "", modalBody }, dispatch] = useApp();

  const handleClose = () => {
    dispatch({ type: SET_MODAL, modal: null });
  };

  return (
    <Modal
      show={!!modal || !!modalBody}
      size={modalSize}
      className={clsx(className, "modal-sm-fullscreen")}
      centered={true}
      backdropClassName="zi-1055"
      onHide={handleClose}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {
          {
            // 'PROFILE': <ProfileModal />,
            [modals.INSTALL_METAMASK_WALLET]: <InstallMetamaskModal />,
            [modals.INSTALL_BSC_WALLET]: <InstallBSCModal />,
            [modals.CREATELOCAL]: <CreateLocalModal />,
            [modals.USELOCAL]: <UseLocalModal />,
            [modals.WARNING]: <WarningConnectModal />,
            [modals.CONNECT]: <ConnectWalletModal />,
            [modals.REPORT]: <ReportModal />,
            [modals.WITHDRAW]: <WithdrawModal />,
            [modals.INSTRUCTIONS]: <InstructionalModal />,
            [modals.CHAIN_NOT_SUPPORTED]: <ChainNotSupportedModal />,
            [modals.VERIFY]: <VerifyModal />,
          }[modal]
        }
      </Modal.Body>
    </Modal>
  );
};
