import React, { useContext, useReducer, createContext, useEffect } from "react";
import produce from "immer";
import { useGetArtWork, useGetNFTInfo } from "actions/Api";
import { useAppState } from "context/context";
import { isSameAddress } from "utils";
import { isAfter } from "date-fns";
import { BigNumber } from "@ethersproject/bignumber";
import { utils } from "ethers";

const NFTStateContext = createContext();
const AppDispatchContext = createContext();

export const SET_NFT = "SET_NFT";
export const TOGGLE_PREVIEW = "TOGGLE_PREVIEW";
export const RESET = "RESET";
export const SET_NFT_INFO = "SET_NFT_INFO";
export const REFETCH_INTERVAL = "REFETCH_INTERVAL";
export const TOGGLE_LAZY = "TOGGLE_LAZY";
export const OPEN_BUY_MODAL = "OPEN_BUY_MODAL";
export const CLOSE_BUY_MODAL = "CLOSE_BUY_MODAL";
export const CLOSE_BID_MODAL = "CLOSE_BID_MODAL";
export const OPEN_BID_MODAL = "OPEN_BID_MODAL";
export const SET_OWNER = "SET_OWNER";
export const SET_OFFER = "SET_OFFER";
export const SET_AUTHOR = "SET_AUTHOR";

const initialState = {
  refetchInterval: 0,
  author: "",
  sha256: "",
  tags: [],
  IpfsHash: "",
  externalLink: "",
  description: "",
  filetype: "",
  name: "",
  image: "",
  onSale: "",
  onAuction: "",
  nsfw: "",
  vouchers: [],
  blockchainInfo: {},
  preview: {},
  blockchainInfoDatabase: {},
  lazy: false,
  showBuyModal: false,
  showBidModal: false,
  isOwner: false,
  formattedOffer: "",
};

const formatOffer = (offer, decimals = 18) =>
  utils
    .parseUnits(offer.split(",").join("").toString(), decimals || 18)
    .toString();

const reducer = produce((draft, action) => {
  switch (action.type) {
    case SET_OFFER:
      if (action.offer) {
        draft.formattedOffer = formatOffer(action.offer, action.decimals);
        draft.offer = action.offer;
      }
      break;
    case SET_OWNER:
      draft.isOwner = action.isOwner;
      break;
    case CLOSE_BID_MODAL:
      draft.showBidModal = false;
      break;
    case OPEN_BID_MODAL:
      draft.showBidModal = true;
      draft.offer = action.offer;
      break;
    case CLOSE_BUY_MODAL:
      draft.showBuyModal = false;
      draft.voucher = null;
      break;
    case OPEN_BUY_MODAL:
      draft.showBuyModal = true;
      draft.voucher = action.voucher;
      break;
    case TOGGLE_LAZY:
      draft.lazy = !draft.lazy;
      break;
    case REFETCH_INTERVAL:
      draft.refetchInterval = action.refetchInterval;
      break;
    case SET_AUTHOR:
      draft.isAuthor = action.isAuthor; //isSameAddress(draft.address, draft.author);
      break;
    case SET_NFT:
      draft._id = action.data._id;
      draft.author = action.data.author;
      draft.sha256 = action.data.sha256;
      draft.tags = action.data.tags;
      draft.IpfsHash = action.data.IpfsHash;
      draft.externalLink = action.data.externalLink;
      draft.description = action.data.description;
      draft.filetype = action.data.filetype;
      draft.name = action.data.name;
      draft.image = action.data.image;
      draft.onSale = action.data.onSale;
      draft.onAuction = action.data.onAuction;
      draft.nsfw = action.data.nsfw;
      draft.IpfsUrl = action.data.IpfsUrl;
      draft.vouchers = action.data.vouchers;
      draft.createdAt = action.data.createdAt;
      draft.blockchainInfoDatabase = action.data.blockchainInfo || {};
      // draft.isAuthor = isSameAddress(action.address, action.data.author);
      break;
    case SET_NFT_INFO:
      draft.blockchainInfo.owner = action.data.owner;
      draft.blockchainInfo.onSale = action.data.sellingPrice !== "0";
      draft.blockchainInfo.onAuction = action.data.highestBid !== "0";
      draft.blockchainInfo.tokenId = action.data.tokenId;
      draft.blockchainInfo.minted = action.data.minted;
      draft.blockchainInfo.sellingPrice = action.data.sellingPrice;
      draft.blockchainInfo.saleCurrency = action.data.saleCurrency;
      draft.blockchainInfo.bidCurrency = action.data.bidCurrency;
      draft.blockchainInfo.bidEnded = action.data.bidEnded;
      draft.blockchainInfo.biddingTime = action.data.biddingTime;
      draft.blockchainInfo.highestBid = action.data.highestBid;
      draft.blockchainInfo.highestBidder = action.data.highestBidder;
      draft.blockchainInfo.initialPrice = action.data.initialPrice;
      draft.blockchainInfo.bidSeller = action.data.bidSeller;
      draft.blockchainInfo.bidOffers = action.data.bidOffers;

      draft.blockchainInfo.decimals = action.data.decimal || 18;
      draft.isOwner = isSameAddress(action.address, action.data.owner);
      draft.blockchainInfo.hasHighestBid = isSameAddress(
        action.address,
        action.data.highestBidder
      );
      draft.blockchainInfo.auctionIsFinished = isAfter(
        new Date(),
        new Date(action.data.biddingTime * 1000)
      );
      if (action.data.highestBid) {
        const HB = BigNumber.from(action.data.highestBid);
        const PC = HB.mul(10).div(100);
        draft.offer = utils
          .formatUnits(HB.add(PC).toString(), draft.blockchainInfo.decimals)
          .toString();
        draft.formattedOffer = formatOffer(
          draft.offer,
          draft.blockchainInfo.decimals
        );
      }
      break;
    case TOGGLE_PREVIEW:
      draft.preview = Object.keys(draft.preview).length ? {} : action.preview;
      break;
    case RESET:
      return { ...initialState };
    default:
      return;
  }
}, {});

function NFTProvider({ children, id }) {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });

  const { address, chainId } = useAppState();

  // useEffect(() => {
  //   dispatch({
  //     type: SET_OWNER,
  //     isOwner: isSameAddress(
  //       address,
  //       state.blockchainInfo.owner || state.author
  //     ),
  //   });
  // }, [address, chainId, state.blockchainInfo.owner, state.author]);

  useEffect(() => {
    // if (state.author && address)
      dispatch({
        type: SET_AUTHOR,
        isAuthor: isSameAddress(state.author, address),
      });
  }, [state.author, address]);

  useEffect(() => {
    if (!id) {
      dispatch({ type: RESET });
    }
  }, [id]);

  useEffect(() => {
    if (state.refetchInterval && state.blockchainInfoDatabase.tokenId) {
      dispatch({ type: REFETCH_INTERVAL, refetchInterval: 0 });
    }
  }, [state.refetchInterval, state.blockchainInfoDatabase.tokenId]);

  const { isLoading: isLoadingArtwork, refetch: refetchArtwork } =
    useGetArtWork(id, {
      refetchInterval: state.refetchInterval,
      onSuccess: (data) => {
        dispatch({ type: SET_NFT, data, address });
      },
      onError: () => {
        dispatch({ type: SET_NFT, data: {} });
      },
      enabled: !!id,
    });

  const { isLoading: isLoadingInfo, refetch: refetchInfo } = useGetNFTInfo(
    {
      tokenId: state.blockchainInfoDatabase?.tokenId,
    },
    {
      onSuccess: (data) => {
        dispatch({
          type: SET_NFT_INFO,
          data,
          address,
        });
      },
      onError: () => {
        dispatch({ type: SET_NFT_INFO, data: {} });
      },
    }
  );

  return (
    <NFTStateContext.Provider
      value={{
        ...state,
        isLoadingArtwork,
        isLoadingInfo,
        refetchInfo,
        refetchArtwork,
      }}
    >
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </NFTStateContext.Provider>
  );
}

function useNFTState() {
  const context = useContext(NFTStateContext);
  if (context === undefined) {
    throw new Error("useNFTState must be used within a AppProvider");
  }
  return context;
}

function useNFTDispatch() {
  const context = useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error("useNFTDispatch must be used within a AppProvider");
  }
  return context;
}

function useNFT() {
  return [useNFTState(), useNFTDispatch()];
}

export { NFTProvider, useNFT, useNFTState, useNFTDispatch };
