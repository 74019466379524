import { useTranslation } from "react-i18next";
import { toast as originalToast } from "react-toastify";

const CustomComponent = ({ icon, content, name }) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex align-self-center">
      <div className="border-end ms-1 me-3">{icon}</div>
      <div>
        <div className="lh-sm">
          <span className="fw-bolder m-0 p-0 lh-sm text-capitalize">{t(name)}</span>
        </div>
        <small className="m-0 p-0 lh-sm ">{content}</small>
      </div>
    </div>
  );
};

function CustomToast(name, icon, content, options) {
  originalToast[name](
    <CustomComponent icon={icon} content={content} name={name} {...options} />,
    options
  );
}

export const toast = {
  success: (content, options) => CustomToast("success", "", content, options),
  error: (content, options) => CustomToast("error", "", content, options),
  warning: (content, options) => CustomToast("warning", "", content, options),
  warn: (content, options) => CustomToast("warn", "", content, options),
  dark: (content, options) => CustomToast("dark", "", content, options),
  info: (content, options) => CustomToast("info", "", content, options),
};
