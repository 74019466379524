import {
  useCancelSale,
  useCreateLazyArtWork,
  useERC20Decimals,
  useIsApprovedForAll,
  usePutToAuction,
  usePutToSale,
  useSetApprovalForAll,
} from "actions/Api";
import { SET_MODAL, useApp, useAppState } from "context/context";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import Select, { components } from "react-select";
import {
  getLocale,
  isSameAddress,
  isUndefined,
  mapCurrency,
  mapCurrencyPropertyByAddress,
  truncateAddress,
} from "utils";
import { networks } from "@aztlan/config";
import DatePicker from "react-datepicker";
import CurrencyInput from "components/Shared/CurrencyInput";
import { utils } from "ethers";
import { toast } from "components/Shared/Toast";
import { useEffect } from "react";
import LoadingButton from "components/Shared/LoadingButton";
import { TOGGLE_LAZY, useNFT } from "./NFTContext";

const Col70 = ({ children }) => (
  <Col lg="6" md="6" sm="12">
    {children}
  </Col>
);

const Col30 = ({ children }) => (
  <Col lg="6" md="6" sm="12">
    {children}
  </Col>
);

const RowForm = ({ right, left, ...props }) => (
  <Row className="mb-4" {...props}>
    <Col70>{left}</Col70>
    <Col30>{right}</Col30>
  </Row>
);

const CustomOption = ({ innerProps, isDisabled, ...props }) => {
  const { t } = useTranslation();
  if (isDisabled) return null;
  return (
    <div {...innerProps} className="d-flex mb-1 px-3 py-2 pointer hoverable">
      <div className="me-2 pointer">
        <img
          src={`/img/currencies/${
            props.value === "custom" ? "custom" : props.label.toLowerCase()
          }.png`}
          alt=""
          height="20px"
          width="20px"
        />
      </div>
      <div className="pointer lh-1">
        <div>
          <label className="fw-bold pointer">{props.label}</label>
        </div>
        <div>
          <small className="text-muted pointer">
            {{
              "0x0000000000000000000000000000000000000000": t("native"),
              custom: "...",
            }[props.value] || truncateAddress(props.value)}
          </small>
        </div>
      </div>
      <div className="divider"></div>
    </div>
  );
};

const Control =
  (props, chainId) =>
  ({ children, ...rest }) => {
    return (
      <components.Control {...rest}>
        {props?.value && chainId && (
          <div
            className=" avatar rounded-pill avatar-32"
            style={{
              backgroundImage: `url(${`/img/currencies/${mapCurrency(
                chainId,
                props.value
              ).toLowerCase()}.png`})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          />
        )}
        {children}
      </components.Control>
    );
  };

const OwnerSaleAuctionForm = (props) => {
  const {
    handleSubmit,
    handleValidateCurrency,
    decimalsWatch,
    isLoadingAuction,
    onSubmit,
    register,
    selectedCurrency,
    chainId,
    type,
    currencyAddressWatch,
    setValue,
    selectedDate,
    lazy,
    isLoadingSale,
    selectedTime,
    control,
    isValid,
  } = props;

  const { t, i18n } = useTranslation();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <RowForm
        hidden={lazy}
        left={
          <Form.Group>
            <Form.Label className="fw-bold text-dark">
              {t("Selecciona el tipo de venta")}
            </Form.Label>
            <Form.Select size="lg" {...register("type", { required: true })}>
              <option value="sale">{t("Precio fijo")}</option>
              <option value="auction">{t("auction")}</option>
            </Form.Select>
          </Form.Group>
        }
      />

      <RowForm
        left={
          <Form.Group>
            <Form.Label className="fw-bold text-dark">
              {t("sellingPrice")}
            </Form.Label>
            <Controller
              control={control}
              name="sellingPrice"
              rules={{ required: true }}
              render={({ field, ...props }) => (
                <CurrencyInput
                  className="form-control form-control-lg"
                  placeholder="0.00"
                  type="text"
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  {...field}
                />
              )}
            />
          </Form.Group>
        }
        right={
          <Form.Group>
            <Form.Label className="fw-bold text-dark">
              {t("currency")}
            </Form.Label>
            <Controller
              control={control}
              name="currency"
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  classNamePrefix="react-select"
                  name="currency"
                  noOptionsMessage={t("No hay mas opciones")}
                  placeholder={t("$")}
                  isSearchable={false}
                  options={[
                    ...networks[chainId]?.BEP20S.map(({ address, symbol }) => ({
                      value: address,
                      label: symbol,
                    })),
                    { value: "custom", label: t("custom") },
                  ]}
                  onChange={(e) => field.onChange(e.value)}
                  components={{
                    Option: CustomOption,
                    Control: Control(selectedCurrency, chainId),
                  }}
                  {...field}
                />
              )}
            ></Controller>
            {/* <Form.Select size="lg" {...register("currency")}>
              {networks[chainId]?.BEP20S?.map(({ address, symbol }) => (
                <option value={address} key={symbol}>
                  {symbol}
                </option>
              ))}
              <option value={"custom"}>{t("custom")}</option>
            </Form.Select> */}
          </Form.Group>
        }
      />

      <RowForm
        hidden={selectedCurrency?.value !== "custom"}
        left={
          <Form.Group>
            <Form.Label className="fw-bold">{t("currencyAddress")}</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              rules={{ required: selectedCurrency?.value === "custom" }}
              placeholder={t("currencyAddress")}
              {...register("currencyAddress", {
                required: selectedCurrency?.value === "custom",
              })}
            />
          </Form.Group>
        }
        right={
          <Form.Group>
            <Form.Label className="fw-bold">
              {isUndefined(decimalsWatch) ? <br /> : t("Decimales")}
            </Form.Label>
            <div>
              <LoadingButton
                // variant="dark-x"
                className="text-capitalize"
                hidden={
                  selectedCurrency?.value !== "custom" ||
                  !isUndefined(decimalsWatch)
                }
                onClick={handleValidateCurrency}
                disabled={!utils.isAddress(currencyAddressWatch)}
              >
                {t("Validar")}
              </LoadingButton>

              <Form.Control
                size="lg"
                type="text"
                disabled={true}
                hidden={isUndefined(decimalsWatch)}
                rules={{ required: selectedCurrency?.value === "custom" }}
                placeholder={t("decimals")}
                {...register("decimals")}
              />
            </div>
          </Form.Group>
        }
      />

      <RowForm
        hidden={type !== "auction"}
        left={
          <Form.Group>
            <Form.Label className="fw-bold text-dark">
              {t("biddingTime")}
            </Form.Label>
            <Controller
              control={control}
              name="biddingDateSelected"
              rules={{ required: type === "auction" }}
              render={({ field, ...props }) => (
                <DatePicker
                  autoComplete="off"
                  minDate={new Date()}
                  className="form-control form-control-lg text-muted"
                  selected={selectedDate}
                  calendarStartDay="0"
                  locale={getLocale(i18n.language)}
                  // placeholderText="01/01/2022"
                  onChange={(e) => field.onChange(e.value)}
                  {...field}
                />
              )}
            />
          </Form.Group>
        }
        right={
          <Form.Group>
            <Form.Label className="fw-bold text-dark">
              {t("Selecciona el horario")}
            </Form.Label>
            <Controller
              control={control}
              name="biddingTimeSelected"
              autoComplete="off"
              rules={{ required: type === "auction" }}
              render={({ field, ...props }) => (
                <DatePicker
                  selected={selectedTime}
                  // placeholderText="11:30 PM"
                  showTimeSelect
                  showTimeSelectOnly
                  autoComplete="off"
                  className="form-select form-select-lg text-muted"
                  timeIntervals={15}
                  dateFormat="h:mm aa"
                  onChange={(e) => field.onChange(e.value)}
                  {...field}
                />
              )}
            />
          </Form.Group>
        }
      />

      <Row>
        <Col lg="12">
          <Form.Group>
            <LoadingButton
              className="w-100 text-center"
              type="submit"
              hidden={type === "auction"}
              loading={isLoadingSale}
              disabled={
                (selectedCurrency?.value === "custom" && !decimalsWatch) ||
                !isValid
              }
              loadingtext={t("Esperando aprobación")}
            >
              {lazy ? t("Crear voucher") : t("Vender NFT")}
            </LoadingButton>
            <LoadingButton
              className="w-100 text-center"
              type="submit"
              hidden={type !== "auction" || lazy}
              loading={isLoadingAuction}
              disabled={
                (selectedCurrency?.value === "custom" && !decimalsWatch) ||
                !isValid
              }
            >
              {t("Subastar")}
            </LoadingButton>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

const OwnerCancelSaleForm = ({ isCancelingSale, handleCancelSale }) => {
  const { t } = useTranslation();
  return (
    <Form>
      <Form.Group>
        <LoadingButton
          className="w-100"
          onClick={handleCancelSale}
          loading={isCancelingSale}
          loadingtext={t("Esperando confirmación")}
        >
          {t("Cancelar venta")}
        </LoadingButton>
      </Form.Group>
    </Form>
  );
};

export default function NFTControlsOwner() {
  const { t } = useTranslation();

  const [
    {
      blockchainInfo: { tokenId, minted, owner, onAuction, onSale },
      sha256,
      lazy,
      refetchArtwork,
      refetchInfo,
    },
    dispatchNFT,
  ] = useNFT();

  const [{ address, chainId, wallet }] = useApp();

  const { mutate: putToSale, isLoading: isLoadingSale } = usePutToSale();

  const { mutate: putToAuction, isLoading: isLoadingAuction } =
    usePutToAuction();

  const { mutate: cancelSale, isLoading: isCancelingSale } = useCancelSale();

  const { mutate: createArtWorkLazy } = useCreateLazyArtWork({
    onSuccess: () => {
      dispatchNFT({ type: TOGGLE_LAZY });
      refetchArtwork();
    },
  });

  const { mutate: isApprovedForAll } = useIsApprovedForAll();

  const { mutate: setApprovalForAll, isLoading: isLoadingApprove } =
    useSetApprovalForAll();

  const { mutate: erc20Decimals } = useERC20Decimals();

  const {
    handleSubmit,
    register,
    watch,
    getValues,
    setValue,
    control,
    reset,
    formState: { isValid },
  } = useForm({ mode: "all" });

  const selectedCurrency = watch("currency");

  const type = watch("type");

  const currencyAddressWatch = watch("currencyAddress");

  const decimalsWatch = watch("decimals");

  const selectedDate = watch("biddingDateSelected");

  const selectedTime = watch("biddingTimeSelected");

  useEffect(() => {
    setValue(
      "decimals",
      mapCurrencyPropertyByAddress(chainId, selectedCurrency?.value, "decimals")
    );
  }, [selectedCurrency?.value, currencyAddressWatch, chainId]);

  const onSubmit = ({
    sellingPrice,
    currency,
    currencyAddress,
    type,
    biddingDateSelected,
    biddingTimeSelected,
    decimals,
  }) => {
    const formattedSellingPrice = utils.parseUnits(
      sellingPrice?.split(",").join("") || "0",
      decimals
    );

    const finalCurrency =
      currency?.value !== "custom" ? currency?.value : currencyAddress;

    if (formattedSellingPrice.lte("0")) return toast.error("must_be_gt_zero");

    if (lazy) {
      return createArtWorkLazy(
        {
          sha256,
          currency: finalCurrency,
          minPrice: formattedSellingPrice.toString(),
        },
        {
          onSuccess: () => {
            reset({
              type: null,
              currency: null,
              currencyAddress: null,
              decimals: null,
            });
          },
        }
      );
    }

    isApprovedForAll(
      {},
      {
        onSuccess: (isApproved) => {
          // dispatch({
          //   type: SET_MODAL,
          //   modal: modals.INSTRUCTIONS,
          //   modalSize: "md",
          //   modalProps: {
          //     instruction: isApproved
          //       ? InstructionsModals.PUT_TO_SALE
          //       : InstructionsModals.APPROVE_NFT,
          //   },
          // });

          if (!isApproved) {
            setApprovalForAll(
              {},
              {
                onSuccess: () => {
                  onSubmit({
                    sellingPrice,
                    currency,
                    currencyAddress,
                    type,
                    biddingDateSelected,
                    biddingTimeSelected,
                    decimals,
                  });
                },
              }
            );
          } else if (type === "sale") {
            putToSale(
              {
                tokenId: tokenId,
                sellingPrice: formattedSellingPrice,
                currency: finalCurrency,
              },
              {
                onSuccess: () => {
                  refetchInfo();
                  refetchArtwork();
                },
              }
            );
          } else if (type === "auction") {
            biddingDateSelected.setMinutes(biddingTimeSelected.getMinutes());
            biddingDateSelected.setHours(biddingTimeSelected.getHours());

            const formattedBiddingTime = Number(
              biddingDateSelected.getTime().toString().substring(0, 10)
            );

            putToAuction(
              {
                tokenId: tokenId,
                initialPrice: formattedSellingPrice,
                currency: finalCurrency,
                biddingTime: formattedBiddingTime,
              },
              {
                onSuccess: () => {
                  refetchInfo();
                  refetchArtwork();
                },
              }
            );
          }
        },
      }
    );
  };

  const handleCancelSale = () => {
    cancelSale(
      { tokenId: tokenId },
      {
        onSuccess: () => {
          refetchInfo();
          refetchArtwork();
        },
      }
    );
  };

  const handleValidateCurrency = () => {
    erc20Decimals(
      { wallet, erc20Address: getValues("currencyAddress") },
      {
        onSuccess: (decimals) => {
          setValue("decimals", decimals);
        },
        onError: () => {
          toast.error(t("No es un ERC20 válido"));
        },
      }
    );
  };

  if ((!minted || !isSameAddress(owner, address)) && !lazy) {
    return null;
  }

  if (!onSale && !onAuction)
    return (
      <OwnerSaleAuctionForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        register={register}
        selectedCurrency={selectedCurrency}
        chainId={chainId}
        type={type}
        lazy={lazy}
        handleCancelSale={handleCancelSale}
        handleValidateCurrency={handleValidateCurrency}
        selectedDate={selectedDate}
        selectedTime={selectedTime}
        setValue={setValue}
        decimalsWatch={decimalsWatch}
        currencyAddressWatch={currencyAddressWatch}
        isLoadingSale={isLoadingSale || isLoadingApprove}
        isLoadingAuction={isLoadingAuction || isLoadingApprove}
        isCancelingSale={isCancelingSale}
        control={control}
        isValid={isValid}
      />
    );

  if (onSale)
    return (
      <OwnerCancelSaleForm
        isCancelingSale={isCancelingSale}
        handleCancelSale={handleCancelSale}
      />
    );

  return null;
}
