import {
  useApproveERC20,
  useBuyLazyNFT,
  useBuyNFT,
  useGetProfile,
} from "actions/Api";
import LoadingButton from "components/Shared/LoadingButton";
import PriceTicker from "components/Shared/PriceTicker";
import { SET_MODAL, useApp } from "context/context";
import { useTranslation } from "react-i18next";
import { toast } from "components/Shared/Toast";
import { errorMapper, noop, zeroAddress } from "utils";
import {
  CLOSE_BUY_MODAL,
  REFETCH_INTERVAL,
  useNFT,
  useNFTState,
} from "components/Pages/NFT/NFTContext";
import { Modal } from "react-bootstrap";

function ModalBuyContainer({
  currency,
  price,
  name,
  verified,
  isLoading,
  isLoadingApprove,
  onClick,
  handleClose,
  showBuyModal,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      show={showBuyModal}
      centered
      backdropClassName="zi-1055"
      onHide={handleClose}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <h5 className="text-dark fw-bold">{t("Comprar este NFT")}</h5>
          <span className="text-muted">
            {" "}
            {t("Confirma la compra de")}{" "}
            <label className="fw-bold">{name}</label> {t("por")}:{" "}
          </span>
          <h3 className="text-center text-dark mt-3 mb-4">
            <PriceTicker
              currency={currency}
              price={price}
              className="justify-content-center"
            />
          </h3>

          <div className="bg-light p-2 my-4 rounded-3" hidden={verified}>
            <p className="text-dark mb-0">
              {t("Este creador aun no ha sido verificado")}
            </p>
            <small className="text-muted">
              {t("Compra este NFT bajo tu propio riesgo")}
            </small>
          </div>

          <div>
            <div>
              <LoadingButton
                onClick={onClick}
                loading={isLoading}
                loadingtext={
                  isLoadingApprove
                    ? t("Esperando pago")
                    : t("Esperando aprobación pago")
                }
              >
                {t("Comprar ahora")}
              </LoadingButton>
            </div>
            <small className="text-muted d-block mt-3">
              {t("Las ordenes de compra no pueden deshacerse")}
            </small>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default function BuyerModal() {
  const [
    { name, blockchainInfo, sha256, author, voucher, showBuyModal },
    dispatch,
  ] = useNFT();

  const { mutate: buyLazyNFT, isLoading: isLoadingLazy } = useBuyLazyNFT();

  const { mutate: buyNFT, isLoading: isLoadingBuy } = useBuyNFT();

  const { mutate: mutateApprove, isLoading: isLoadingApprove } =
    useApproveERC20();

  const { data } = useGetProfile(author, { enabled: !!author });

  const handleClose = () => dispatch({ type: CLOSE_BUY_MODAL });

  const onSuccess = () => {
    handleClose();
    dispatch({ type: REFETCH_INTERVAL, refetchInterval: 2000 });
  };

  const onError = (err) => {
    const error = errorMapper(err);
    if (error === "errors.not_enough_allowed") {
      mutateApprove(
        {
          erc20Address: blockchainInfo?.saleCurrency || voucher.currency,
          amount: blockchainInfo?.sellingPrice || voucher.minPrice,
        },
        {
          onSuccess: () => {
            handleBuy();
          },
          onError: (err) => {
            toast.error(errorMapper(err));
          },
        }
      );
    } else {
      toast.error(error);
    }
  };

  const handleBuy = () => {
    if (voucher) {
      buyLazyNFT(
        {
          sha256: voucher.sha256,
          currency: voucher.currency,
          minPrice: voucher.minPrice,
          creator: voucher.creator,
          signature: voucher.signature,
        },
        { onSuccess, onError }
      );
    } else {
      buyNFT(
        {
          tokenId: blockchainInfo?.tokenId,
          value:
            blockchainInfo?.saleCurrency === zeroAddress
              ? blockchainInfo?.sellingPrice
              : 0,
        },
        {
          onSuccess,
          onError,
        }
      );
    }
  };

  return (
    <ModalBuyContainer
      name={name}
      handleClose={handleClose}
      currency={blockchainInfo?.saleCurrency || voucher?.currency}
      price={blockchainInfo?.sellingPrice || voucher?.minPrice}
      verified={data?.verified}
      onClick={handleBuy}
      isLoading={isLoadingBuy || isLoadingApprove || isLoadingLazy}
      isLoadingApprove={isLoadingApprove}
      showBuyModal={showBuyModal}
    />
  );
}
