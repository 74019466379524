import React, { Fragment, useState, useMemo } from "react";
import {
  SET_MODAL,
  useApp,
  useAppDispatch,
  useAppState,
} from "context/context";
import {
  Navbar,
  Nav,
  NavItem,
  Button,
  Container,
  Dropdown,
  Modal,
  Collapse,
  Form,
} from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { truncateAddress, noop } from "utils";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import {
  useGetERC20Balance,
  useGetUSDTBalance,
  useGetNativeBalance,
  useNotifications,
  useMarkNotificationsAsRead,
} from "actions/Api";
import { FetcherComponent } from "components/Shared/Fetcher";
import Avatar from "components/Shared/Avatar";
import { ReactComponent as GlobeAltIcon } from "assets/icons/world.svg";
import ChevronDown from "@heroicons/react/outline/ChevronDownIcon";
import ChevronRightIcon from "@heroicons/react/outline/ChevronRightIcon";
import ChevronUpIcon from "@heroicons/react/outline/ChevronUpIcon";
import { ReactComponent as DisconnectIcon } from "assets/icons/disconnect.svg";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as Wallet } from "assets/icons/wallet.svg";
import { ReactComponent as LightningBoltIcon } from "assets/icons/flash.svg";
// import { ReactComponent as Logo } from "assets/aztlan-log.svg";
import { ReactComponent as LogoBlue } from "assets/logos/LogoBlue.svg";
import { CHAIN_ID, fillColor, networks as networksData } from "@aztlan/config";
import { toast } from "components/Shared/Toast";
import { CustomCheckbox } from "components/Shared/CustomCheckbox";
import { ReactComponent as BellIcon } from "assets/icons/bell.svg";
import { ReactComponent as PumpIcon } from "assets/icons/pump.svg";
import { FormatedDateRelative } from "components/Shared/FormatedDate";
import { modals } from "constant";
import RippleButton from "components/Shared/RippleButton";

const navBarMenus = [
  { to: "/explorar", title: "explorar", className: " px-0 btn-sm" },
  {
    to: "/minar",
    title: "crear",
    className: "btn-sm mb-3 mb-lg-0 ms-0",
  },
];

const dropdownMenus = [
  {
    to: "/perfil",
    title: "perfil",
    icon: <UserIcon height="20px" className="me-3" />,
  },
  {
    to: "/minar",
    title: "minar",
    icon: <LightningBoltIcon height="20px" className="me-3" />,
  },
];

function Balance({ data, currency = "", addToMetamask }) {
  const { balance } = data;
  const { t } = useTranslation();
  return (
    <Dropdown.ItemText className="mb-2 mt-3 pe-6 px-0 pt-0 d-flex align-items-center">
      <div>
        <img
          src={`/img/currencies/${currency.toLowerCase()}.png`}
          alt={currency}
          width="30px"
        />
      </div>
      <div className="ms-3 lh-sm">
        <div>
          <small className="text-dark fw-bold">{t("Balance")}</small>
        </div>
        <div>
          <small className="text-muted text-uppercase text-truncate mb-0">
            {balance.split(".")?.[0]}.{balance.split(".")?.[1].substring(0, 5)}{" "}
            {currency}
          </small>
        </div>
      </div>
      <div
        className="ms-4 pointer"
        hidden={currency.toLowerCase() !== "aztlan"}
        onClick={addToMetamask}
      >
        <img src="img/wallets/metamask.svg" width="20px" alt="" />
      </div>
    </Dropdown.ItemText>
  );
}

function ContractNotFound() {
  const { t } = useTranslation();
  return (
    <Dropdown.ItemText>
      <small className="text-muted my-2">{t("token_not_found")}</small>
    </Dropdown.ItemText>
  );
}

function AccountDropDown({ handleGoTo, isModal }) {
  const { t } = useTranslation();

  const [
    {
      address,
      chainId,
      disconnectWallet,
      username,
      imageUrl,
      nativeCurrency,
      provider,
    },
  ] = useApp();

  const USDTquery = useGetUSDTBalance();

  const ERC20query = useGetERC20Balance();

  const NativeBalanceQuery = useGetNativeBalance();

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(address);
    toast.info(t("Copiado al portapapeles"));
  };

  const dispatch = useAppDispatch();

  const handleViewWithdrawals = () => {
    dispatch({ type: SET_MODAL, modal: modals.WITHDRAW, modalSize: "md" });
  };

  const addToMetamask = async () => {
    const network = networksData[CHAIN_ID.BSC];
    const tokenSymbol = "AZTLAN";
    const TOKEN = network.BEP20S.find(({ symbol }) => symbol === tokenSymbol);
    const tokenAddress = TOKEN.address;
    const tokenDecimals = 18;
    const tokenImage = "https://www.aztlan.market/aztlan-logo-32x32.png";

    try {
      //EIP-747
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await provider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        // toast.success(t("TokenAdded"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const contracts = useMemo(() => {
    const tokens = [
      {
        query: NativeBalanceQuery,
        error: <ContractNotFound />,
        currency: nativeCurrency?.toLowerCase(),
      },
      {
        query: USDTquery,
        error: <ContractNotFound />,
        currency: "usdt",
      },
    ];
    if (chainId === CHAIN_ID.BSC)
      tokens.push({
        query: ERC20query,
        error: <ContractNotFound />,
        currency: "aztlan",
      });
    return tokens || [];
  }, [NativeBalanceQuery, USDTquery, ERC20query, nativeCurrency, chainId]);

  if (!address) return null;

  if (isModal) {
    return (
      <>
        {dropdownMenus.map(({ to, title, icon }, key) => (
          <MenuItem key={key} onClick={() => handleGoTo(`${to}/${address}`)}>
            {icon} {t(title)}
          </MenuItem>
        ))}
        <MenuItem onClick={handleViewWithdrawals}>
          <Wallet height="20px" className="me-3" stroke={fillColor} />
          {t("Retirar")}
        </MenuItem>

        <MenuItem onClick={disconnectWallet}>
          <DisconnectIcon height="20px" className="me-3" />
          {t("Desconectar")}
        </MenuItem>
      </>
    );
  }

  return (
    <div className="d-flex">
      <Dropdown>
        <Dropdown.Toggle
          as={CustomToggle}
          paddingLeft="45px"
          image={
            <Avatar
              address={address}
              image={imageUrl}
              className="me-2"
              size="38"
            />
          }
        >
          {username ? username : truncateAddress(address)}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-dark p-4 mt-3 border-0 shadow-lg">
          <Dropdown.Header className="pe-6 pb-3 px-0 pt-0 border-bottom user-select-none">
            <p className="fw-bold text-dark mb-0">{username}</p>
            <RippleButton
              className="p-0 text-muted"
              onClick={handleCopyToClipboard}
            >
              <small className="d-block">{truncateAddress(address)}</small>
            </RippleButton>
          </Dropdown.Header>

          {contracts.map((contract, key) => (
            <FetcherComponent
              key={key}
              query={contract.query}
              errorComponent={contract.errorComponent}
            >
              <Balance
                currency={contract.currency}
                addToMetamask={addToMetamask}
              />
            </FetcherComponent>
          ))}

          {dropdownMenus.map(({ to, title, icon }, key) => (
            <Fragment key={key}>
              <Dropdown.Item
                onClick={() => handleGoTo(`${to}/${address}`)}
                className="text-capitalize d-flex align-items-center text-dark pointer"
              >
                {icon}
                <small className="pointer fw-bold">{t(title)}</small>
              </Dropdown.Item>
            </Fragment>
          ))}
          <Dropdown.Item
            onClick={handleViewWithdrawals}
            className="d-flex align-items-center text-dark pointer"
          >
            <Wallet height="20px" className="me-3" stroke={fillColor} />
            <small className="pointer fw-bold">{t("Retirar")}</small>
          </Dropdown.Item>

          <Dropdown.Item
            onClick={disconnectWallet}
            className="d-flex align-items-center text-dark pointer mb-0"
          >
            <DisconnectIcon height="20px" className="me-3" />
            <small className="pointer fw-bold">{t("Desconectar")}</small>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

const SimpleToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div onClick={onClick} ref={ref}>
    {children}
  </div>
));

const CustomToggle = React.forwardRef(
  ({ children, onClick, image, paddingLeft = "25px" }, ref) => (
    <Button
      variant=""
      size="sm"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className="d-flex align-self-center align-items-center">
        <div className="position-absolute">{image}</div>
        <div style={{ paddingLeft }}>{children}</div>
      </div>
    </Button>
  )
);

function Networks({ isModal }) {
  const [openNetworks, setOpenNetworks] = useState(false);

  const toggleNetworks = () => setOpenNetworks(!openNetworks);

  const { t } = useTranslation();

  const [{ address, chainId, addNetwork }] = useApp();

  const networks = useMemo(
    () => [
      {
        label: "Ethereum",
        checked: chainId === CHAIN_ID.MAINNET,
        disabled: true,
        onChange: () => addNetwork(CHAIN_ID.BSC),
      },
      {
        label: "Polygon",
        checked: chainId === CHAIN_ID.POLYGON,
        disabled: false,
        onChange: () => addNetwork(CHAIN_ID.POLYGON),
      },
      {
        label: "BSC",
        checked: chainId === CHAIN_ID.BSC,
        disabled: false,
        onChange: () => addNetwork(CHAIN_ID.BSC),
      },
    ],
    [chainId, addNetwork]
  );

  const testnets = useMemo(
    () => [
      {
        label: "Mumbai",
        checked: chainId === CHAIN_ID.POLYGONMUMBAI,
        disabled: false,
        onChange: () => addNetwork(CHAIN_ID.POLYGONMUMBAI),
      },
      // {
      //   label: "BSCTestnet",
      //   checked: chainId === CHAIN_ID.BSCTest,
      //   disabled: false,
      //   onChange: () => addNetwork(CHAIN_ID.BSCTest),
      // },
      // {
      //   label: "Hardhat",
      //   checked: chainId === CHAIN_ID.HARDHAT,
      //   disabled: false,
      //   onChange: () => addNetwork(CHAIN_ID.HARDHAT),
      // },
    ],
    [chainId, addNetwork]
  );

  const goToFaucet = () => {
    window.open(networksData?.[chainId]?.faucet, "_blank");
  };

  if (!address) return null;

  if (isModal) {
    return (
      <>
        <MenuItem
          onClick={toggleNetworks}
          isCollapse={true}
          isCollapsed={openNetworks}
        >
          <GlobeAltIcon stroke="#1b1f29" height="20px" className="me-4" />
          {t("Networks")}
        </MenuItem>
        <Collapse in={openNetworks}>
          <ul className="list-unstyled ps-3">
            <li className="fs-5 mb-3">Mainnets</li>
            {networks.map((network) => (
              <li key={network.label} className="ms-4">
                <Form.Check
                  type="checkbox"
                  className="mb-4 fs-5 fw-light text-muted"
                  label={network.label}
                  checked={network.checked}
                  disabled={network.disabled}
                  onChange={network.onChange}
                />
              </li>
            ))}
            <li className="fs-5 mb-3">Testnets</li>
            {testnets.map((testnet) => (
              <li key={testnet.label} className="ms-4">
                <Form.Check
                  type="checkbox"
                  className="mb-4 fs-5 fw-light text-muted"
                  label={testnet.label}
                  icon={testnet.icon}
                  checked={testnet.checked}
                  disabled={testnet.disabled}
                  onChange={testnet.onChange}
                />
              </li>
            ))}
          </ul>
        </Collapse>
      </>
    );
  }

  return (
    <>
      <NavItem className="my-lg-0 my-3">
        <Dropdown>
          <Dropdown.Toggle as={SimpleToggle}>
            <div
              style={{ height: "45px", width: "30px" }}
              className="d-flex align-self-center align-items-center me-3 pointer"
            >
              {/* <EthereumIcon /> */}
              <GlobeAltIcon stroke="#1b1f29" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-dark py-2 mt-3 border-0 shadow-lg">
            <Dropdown.Header className="text-dark ps-0" tag="div">
              <span className="fw-bold">{t("Mainnets")}</span>
            </Dropdown.Header>

            {networks.map((network) => (
              <Dropdown.ItemText className="text-muted" key={network.label}>
                <CustomCheckbox
                  label={network.label}
                  checked={network.checked}
                  disabled={network.disabled}
                  onChange={network.onChange}
                />
              </Dropdown.ItemText>
            ))}

            <Dropdown.Header className="text-dark ps-0" tag="div">
              <span className="fw-bold">{t("Testnets")}</span>
            </Dropdown.Header>
            {testnets.map((testnet) => (
              <Dropdown.ItemText className="text-muted" key={testnet.label}>
                <CustomCheckbox
                  label={testnet.label}
                  icon={testnet.icon}
                  checked={testnet.checked}
                  disabled={testnet.disabled}
                  onChange={testnet.onChange}
                />
              </Dropdown.ItemText>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </NavItem>
      <NavItem hidden={!networksData?.[chainId]?.faucet}>
        <Dropdown>
          <Dropdown.Toggle as={SimpleToggle}>
            <div
              style={{ height: "45px", width: "30px" }}
              className="d-flex align-self-center align-items-center me-3 pointer"
              onClick={goToFaucet}
            >
              <PumpIcon height="22px" />
            </div>
          </Dropdown.Toggle>
        </Dropdown>
      </NavItem>
    </>
  );
}

function Notification({ data = {}, isModal }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = () => {
    if (data.sha256) {
      navigate(`/nft/${data.sha256}`);
    }
  };

  if (isModal) {
    return (
      <MenuItem className="fs-6 ms-4">
        <div>{t(data.notification)}</div>
        <div>
          <small className="text-muted">
            <FormatedDateRelative date={data.createdAt} />
          </small>
        </div>
      </MenuItem>
    );
  }

  return (
    <>
      <Dropdown.Item onClick={handleClick} className="lh-1">
        <small className="text-dark fw-bold d-block mb-2">
          {t(data.notification)}
        </small>
        <small className="text-muted d-block">
          <FormatedDateRelative date={data.createdAt} />
        </small>
      </Dropdown.Item>
      <Dropdown.Divider />
    </>
  );
}

const MenuItem = ({
  children,
  isGoTo,
  isCollapse,
  isCollapsed,
  onClick = noop,
  className,
  hidden,
}) => (
  <div
    hidden={hidden}
    className={clsx("menu-item", (isCollapse || isGoTo) && "d-flex", className)}
    onClick={onClick}
  >
    <div>{children}</div>
    {isCollapse && (
      <div className="ms-auto">
        {isCollapsed ? (
          <ChevronUpIcon height="20px" />
        ) : (
          <ChevronDown height="20px" />
        )}
      </div>
    )}
    {isGoTo && (
      <div className="ms-auto">
        <ChevronRightIcon height="20px" />
      </div>
    )}
  </div>
);

function NotificationsDropdown({ isModal, closeModal = noop }) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  const { address } = useAppState();

  const query = useNotifications(address);

  const navigate = useNavigate();

  const { mutate } = useMarkNotificationsAsRead();

  const { t } = useTranslation();

  const goToNotifications = () => {
    navigate("/notificaciones");
    closeModal();
  };

  const handleSetAsRead = (e) => {
    e.preventDefault();
    e.stopPropagation();
    mutate(
      { notifications: query.data?.map((n) => n._id) },
      {
        onSuccess: () => {
          query.refetch();
          toast.success(t("Marcadas como leídas"));
        },
      }
    );
  };

  const notificationsNotRead = query?.data?.filter((n) => !n.read)?.length;
  // const notificationsRead = query?.data?.filter((n) => n.read)?.length;

  if (!address) return null;

  if (isModal) {
    return (
      <>
        <MenuItem onClick={toggleOpen} isCollapse={true} isCollapsed={open}>
          <BellIcon height="20px" className="me-4" />
          {t("Notificaciones")} ({query?.data?.length})
        </MenuItem>
        <Collapse in={open}>
          <div className="ps-4">
            <FetcherComponent
              query={query}
              filter={(n) => !n.read}
              emptyComponent={
                <MenuItem className="ps-4 text-muted">
                  {t("No hay notificaciones")}
                </MenuItem>
              }
            >
              <Notification isModal />
            </FetcherComponent>
            <MenuItem
              onClick={goToNotifications}
              className="ps-4"
              isGoTo={true}
            >
              {t("Ver notificaciones")}
            </MenuItem>
          </div>
        </Collapse>
      </>
    );
  }

  return (
    <Dropdown align="end">
      <Dropdown.Toggle as={SimpleToggle}>
        <div
          style={{ height: "45px", width: "30px" }}
          className="d-flex align-self-center align-items-center ms-1 pointer"
        >
          <div className="position-relative pointer">
            <BellIcon height="25px" />
            <span
              className="position-absolute top-0 start-100 translate-middle bg-danger rounded-circle"
              style={{ height: "10px", width: "10px", marginTop: "2px" }}
              hidden={!notificationsNotRead}
            >
              {/* <small>{notificationsNotRead}</small> */}
            </span>
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="dropdown-menu-dark p-4 mt-3 border-0 shadow-lg"
        dir="right"
      >
        <Dropdown.Header className="p-0 mb-2" tag="div">
          <small className="fw-bold text-dark mb-0">
            {t("Notificaciones")} ({query?.data?.length})
          </small>
        </Dropdown.Header>

        <Dropdown.Item
          className="text-center"
          onClick={handleSetAsRead}
          hidden={!notificationsNotRead}
        >
          <small className="fw-bold text-muted">{t("Marcar como leído")}</small>
        </Dropdown.Item>

        <FetcherComponent
          query={query}
          filter={(n) => !n.read}
          emptyComponent={
            <Dropdown.ItemText className="p-0">
              <small className="text-muted">{t("No hay notificaciones")}</small>
            </Dropdown.ItemText>
          }
        >
          <Notification />
        </FetcherComponent>
        <Dropdown.Item className="mt-2" onClick={goToNotifications}>
          <div className="d-flex align-self-center align-items-center">
            <div className="">
              <small className="fw-bold text-muted">
                {t("Ver notificaciones")}
              </small>
            </div>
            <div className="ms-auto">
              <ChevronRightIcon width="20px" />
            </div>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

const ButtonsNavbar = ({ isModal, closeModal = noop }) => {
  const navigate = useNavigate();

  const [{ address }, dispatch] = useApp();
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const handleConnectWallet = () => {
    dispatch({ type: SET_MODAL, modal: modals.CONNECT });
  };

  const handleGoTo = (to) => {
    if (!address && to === "/minar") {
      handleConnectWallet();
    } else {
      navigate(to);
      closeModal();
    }
  };

  const handleWallet = () => {
    closeModal();
    handleConnectWallet();
  };

  if (isModal) {
    return (
      <>
        <MenuItem onClick={() => handleGoTo("explorar")}>
          {t("Explorar galeria")}
        </MenuItem>

        <Networks isModal={isModal} />

        <NotificationsDropdown isModal={isModal} closeModal={closeModal} />

        <AccountDropDown handleGoTo={handleGoTo} isModal={isModal} />

        <MenuItem
          hidden={address}
          className="border rounded py-2 text-center mt-lg-0 text-primary border-primary"
          onClick={handleWallet}
        >
          {t("Conectar Wallet")}
        </MenuItem>
      </>
    );
  }

  return (
    <Nav>
      {navBarMenus.map(({ title, to, className }) => (
        <NavItem key={title} onClick={() => handleGoTo(to)}>
          <Button
            className={clsx(
              className,
              "text-capitalize text-decoration-none",
              pathname === to ? "text-muted" : "text-dark"
            )}
            variant="link"
          >
            {t(title)}
          </Button>
        </NavItem>
      ))}

      <Networks />

      <NavItem className="ms-0" hidden={!address}>
        <NotificationsDropdown />
      </NavItem>

      <NavItem className="ms-0">
        <AccountDropDown handleGoTo={handleGoTo} />

        <Button
          hidden={address}
          className="mt-3 mt-lg-0 "
          variant="primary"
          size="sm"
          onClick={handleConnectWallet}
        >
          {t("Conectar Wallet")}
        </Button>
      </NavItem>
    </Nav>
  );
};

export default function NavBar(props) {
  const [showModal, setShowModal] = useState(false);
  const hideModal = () => setShowModal(false);
  const { t } = useTranslation();

  return (
    <Navbar {...props}>
      <Container fluid={props.fluid}>
        <Navbar.Brand>
          <Link
            to="/"
            className="text-decoration-none d-flex align-self-center align-items-center"
          >
            <LogoBlue height="28px" />
            <h5 className="text-dark mb-0 ms-2">{t("company_name")}</h5>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle onClick={() => setShowModal(true)} />
        {showModal && (
          <Modal
            className="bg-white"
            show={showModal}
            onHide={hideModal}
            backdrop="static"
          >
            <Modal.Header
              closeButton
              className="border-bottom-x custom-header-modal"
            >
              <Modal.Title className="d-flex align-self-center align-items-center">
                <LogoBlue height="28px" />
                <h5 className="text-dark mb-0 ms-2">{t("company_name")}</h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="mobile-menu">
              <ButtonsNavbar isModal={true} closeModal={hideModal} />
            </Modal.Body>
          </Modal>
        )}
        <Navbar.Collapse className="flex-row-reverse" id="main-navbar">
          <ButtonsNavbar />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
