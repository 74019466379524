import { createAvatar } from "@dicebear/avatars";
import CheckCircleIcon from "@heroicons/react/outline/CheckCircleIcon";
import clsx from "clsx";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as style from "@dicebear/big-smile";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { noop } from "utils";

export function Verified({ verified, size, absolutePositioned = true }) {
  const [target] = useState(`profile-${(Math.random() * 10000).toFixed()}`);
  const { t } = useTranslation();
  if (!verified) return null;

  return (
    <span
      className={clsx(
        absolutePositioned &&
          "position-absolute top-0 start-100 translate-middle badge"
      )}
      id={target}
    >
      <OverlayTrigger
        transition={false}
        overlay={<Tooltip id="hashOnlyHelp">{t("userVerified")}</Tooltip>}
      >
        {({ ref, ...triggerHandler }) => (
          <div ref={ref}>
            <CheckCircleIcon
              height={Number(size) <= 42 ? "20px" : "30px"}
              className="mx-1 text-secondary"
              {...triggerHandler}
            />
          </div>
        )}
      </OverlayTrigger>
    </span>
  );
}

export default function Avatar({
  image,
  alt,
  size = 42,
  className,
  verified,
  address,
  onClick = noop,
}) {
  const avatar = useMemo(() => {
    if (!address || image) return null;
    return createAvatar(style, {
      seed: String(address).toLowerCase(),
      dataUri: true,
    });
  }, [address, image]);

  if (!image && !avatar) return null;

  return (
    <div
      onClick={onClick}
      className="btn btn-default m-0 p-0 g-0 position-relative"
    >
      {image ? (
        <div
          className={clsx(className, "avatar rounded-pill", `avatar-${size}`)}
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      ) : (
        <img
          src={avatar}
          alt=""
          className={clsx(className, "rounded-pill bg-light border")}
          height={size}
        />
      )}
      {/* <Verified verified={verified} size={size} /> */}
    </div>
  );
}
