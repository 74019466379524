import { useVerifyProfile } from "actions/Api";
import LoadingButton from "components/Shared/LoadingButton";
import { useAppState } from "context/context";
import { Button, Form, ListGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export function VerifyModal() {
  const { t } = useTranslation();

  const { address } = useAppState();
  const { mutate, isLoading } = useVerifyProfile();
  const { handleSubmit, register } = useForm();

  const onSubmit = (values) => {
    console.log(values)

    mutate({ address, ...values });
  };

  return (
    <div>
      <h5>{t("Verificar perfil")}</h5>
      <label className="text-muted">{t("Al verificar tu perfil")}</label>
      <ListGroup variant="white">
        <ListGroup.Item variant="light border-0">1. </ListGroup.Item>
        <ListGroup.Item variant="light border-0">2. </ListGroup.Item>
      </ListGroup>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Control
          placeholder={t("Tweet")}
          {...register("tweet")}
        ></Form.Control>
        <LoadingButton type="submit" loading={isLoading} className="w-100">
          {t("Verificar")}
        </LoadingButton>
      </Form>
    </div>
  );
}

export default VerifyModal;
