export const InstructionsModals = {
  APPROVE_NFT: "APPROVE_NFT",
  PUT_TO_SALE: "PUT_TO_SALE",
};

export const modals = {
  INSTALL_METAMASK_WALLET: "INSTALL_METAMASK_WALLET",
  INSTALL_BSC_WALLET: "INSTALL_BSC_WALLET",
  // CREATELOCAL: "CREATELOCAL",
  // USELOCAL: "USELOCAL",
  WARNING: "WARNING",
  CONNECT: "CONNECT",
  BID: "BID",
  REPORT: "REPORT",
  WITHDRAW: "WITHDRAW",
  // INSTRUCTIONS: "INSTRUCTIONS",
  CHAIN_NOT_SUPPORTED: "CHAIN_NOT_SUPPORTED",
  VERIFY: "VERIFY",
};

export const tokenomicsDistribution = [
  { label: "founders", usage: 10, symbol: "*" },
  { label: "parters", usage: 10 },
  { label: "marketing", usage: 5 },
  { label: "airdrop", usage: 5 },
  { label: "reserve", usage: 5 },
  { label: "sale", usage: 65 },
];

export const categoryTags = [
  "Art",
  "Music",
  "Games",
  "Collectibles",
  "Modeling",
  "Photography",
  "Sports",
  "Metaverses",
  "Memes",
];
