import { useUpload } from "actions/Api";

export default function useFileUpload(options = {}) {
  const { mutate: upload, status } = useUpload();

  const chooseFileAndUploadFile = (data, options) => {
    const eleInput = document.createElement("input");
    eleInput.type = "file";
    eleInput.style.display = "none";
    eleInput.onchange = (e) => {
      const files = e.target.files;

      if (files) {
        return upload({ ...data, files }, options);
      }
    };
    eleInput.click();
  };

  return {
    status,
    uploadFile: upload,
    chooseFileAndUploadFile,
  };
}
