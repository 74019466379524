import { Button, Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import { ReactComponent as Logo } from "assets/logos/LogoBlue.svg";
import { ReactComponent as TwitterIcon } from "assets/twitter.svg";
import { useForm } from "react-hook-form";
import { utils } from "ethers";
import { useRegisterToAirdrop } from "actions/Api";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const FormRegister = ({ handleSubmit, onSubmit, t, register, isValid }) => (
  <Form onSubmit={handleSubmit(onSubmit)} className="">
    <ListGroup numbered as="ol" className="border-none" variant="">
      <div className="mb-4">
        <p className="text-dark fw-bold">1. {t("Síguenos en Twitter")}</p>
        <a
          href="https://twitter.com/aztlanmarket"
          rel="noreferrer"
          target="_blank"
        >
          <TwitterIcon className="ms-2" /> @AztlanMarket
        </a>
      </div>

      <Form.Group className="mb-4">
        <Form.Label className="text-dark fw-bold">
          2. {t("Ingresa tu nombre de usuario")}
        </Form.Label>
        <Form.Control
          autoComplete={""}
          placeholder="BeepleTwitter"
          {...register("twitter", { required: true })}
        ></Form.Control>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label className="text-dark fw-bold">
          3. {t("Ingresa tu dirección en la BSC")}
        </Form.Label>
        <Form.Control
          autoComplete={""}
          placeholder="0xaaE77f4F01c0...390Bd551312762FE4"
          {...register("address", {
            required: true,
            validate: (address) => utils.isAddress(address),
          })}
        ></Form.Control>
      </Form.Group>

      <Button
        variant="primary"
        className="w-100"
        size=""
        disabled={!isValid}
        type="submit"
      >
        {t("Regístrate")}
      </Button>
    </ListGroup>
  </Form>
);

export default function Airdrop() {
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: "all" });

  const { t } = useTranslation();

  const [response, setResponse] = useState();
  const { mutate: registerAirdrop } = useRegisterToAirdrop();

  const onSubmit = ({ twitter, address }) => {
    registerAirdrop(
      { twitter, address },
      {
        onSuccess: () => {
          setResponse(t("¡Gracias por tu registro!"));
        },
        onError: () => {
          setResponse(t("¡Ya estás participando!"));
        },
      }
    );
  };

  return (
    <section className="mt-4">
      <Container className="my-4 mt-5">
        <Row className="">
          <Col xs="12" lg="5" className="">
            <div className="position-relative h-100">
              <div className="position-absolute top-50 start-50 translate-middle">
                <Logo height="500px" />
              </div>
              {/* <img src={AirdropPNG} alt="" className="img-fluid rounded"></img> */}
            </div>
          </Col>
          <Col
            xs="12"
            lg="7"
            className="d-flex justify-content-center align-content-center align-self-center align-middle p-5"
          >
            <div className="card shadow-lg p-2 p-lg-4">
              <div className="card-body">
                <div className="mb-5">
                  <h3>{t("Airdrop Campaign")}</h3>
                  <label className="text-muted">
                    {t("¡Síguenos en Twitter para recibir")}
                  </label>{" "}
                  <a
                    href="https://bscscan.com/token/0x92bD46E88931cbcfCFe1a4b7cCE1847D6fA74FAD"
                    target="_blank"
                    rel="noreferrer"
                    className="fw-bold"
                  >
                    Aztlan
                  </a>{" "}
                  {t("gratis")}!
                </div>
                {response ? (
                  <h4>{response}</h4>
                ) : (
                  <FormRegister
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    t={t}
                    register={register}
                    isValid={isValid}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
