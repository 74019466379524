import { useRegisterToNewsletter } from "actions/Api";
import Jumbotron from "components/Shared/Jumbotron";
import { Button, Col, Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "components/Shared/Toast";
import { emailRule } from "utils";

export default function Newsletter() {
  const { t } = useTranslation();
  const { mutate } = useRegisterToNewsletter();
  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty },
    reset,
  } = useForm({ mode: "all" });

  const onSubmit = (body) => {
    mutate(body, {
      onSuccess: () => {
        toast.success(t("Registrado con éxito"));
        reset();
      },
    });
  };
  return (
    <section className="bg-white mt-5">
      <Container className="text-center">
        <Jumbotron
          title={t("Newsletter")}
          subtitle={t("newsletter_subtitle")}
        />
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="mb-5">
          <Form.Group
            as={Col}
            lg={{ span: "6", offset: "3" }}
            controlId="email"
          >
            <Form.Control
              className="text-center"
              type="text"
              placeholder={t("Ingresa tu correo electrónico")}
              isInvalid={isDirty && !isValid}
              {...register("email", {
                required: true,
                pattern: {
                  value: emailRule,
                },
              })}
            />
            <Form.Control.Feedback type="invalid">
              {t("errors.email_invalid")}
            </Form.Control.Feedback>
            <Button type="submit" disabled={!isValid} className="mt-5">
              {t("Suscribirse")}
            </Button>
          </Form.Group>
        </Form>
      </Container>
    </section>
  );
}
