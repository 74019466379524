import { Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SearchIcon from "@heroicons/react/outline/SearchIcon";
import { noop } from "utils";
import PropTypes from "prop-types";

export default function SearchInput({ setSearchTerm = noop }) {
  const { t } = useTranslation();
  return (
    <Col lg="5" xl="5">
      <div className="input-group text-muted">
        {" "}
        <Form.Control
          type="text"
          placeholder={`${t("Buscar")}...`}
          aria-label={t("Buscar")}
          aria-describedby="basic-addon1"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <span className="bg-light border-0 input-group-text" id="basic-addon1">
          <SearchIcon width="15px" stroke="#757a8a" />
        </span>
      </div>
    </Col>
  );
}

SearchInput.propTypes = {
  setSearchTerm: PropTypes.func.isRequired,
};
