import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function useScrollTop() {
  const { pathname } = useLocation();
  useEffect(
    () => () => {
      if (!pathname) return;
      const timeout = setTimeout(() => {
        try {
          // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        } catch (error) {
          // just a fallback for older browsers
          window.scrollTo(0, 0);
        }
      }, 50);
      return () => {
        clearTimeout(timeout);
      };
    },
    [pathname]
  );
}
