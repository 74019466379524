import clsx from "clsx";
import { cloneElement, forwardRef } from "react";
import { useTranslation } from "react-i18next";

export function Loading({ className, hidden } = {}) {
  return (
    <div className={clsx("text-center my-2", className, hidden && "d-none")}>
      <div
        className="spinner-border spinner-border-sm text-muted"
        role="status"
      ></div>
    </div>
  );
}

export function Empty({ emptyLabel = "no_elements" }) {
  const { t } = useTranslation();
  return <span className="text-muted"> {t(emptyLabel)}</span>;
}

function ErrorHandler({ error, refetch }) {
  const { t } = useTranslation();
  return (
    <div>
      <span>
        {t("error", { error: error?.data?.message || error?.data || error })}
      </span>
      <button className="btn btn-default" onClick={refetch}>
        {t("retry")}
      </button>
    </div>
  );
}

export const FetcherComponent = forwardRef((props, ref) => {
  const {
    query: { isLoading, data, isSuccess, error, isError, refetch },
    query2: { data: data2, isLoading: isLoadingQuery2 } = {},
    filter,
    children,
    name,
    noloader,
    errorComponent,
    emptyComponent,
    emptyLabel,
    loadingComponent,
  } = props;
  if (isLoading && !noloader) {
    return loadingComponent || <Loading />;
  }
  if (isError) {
    if (errorComponent) {
      return errorComponent;
    } else {
      return <ErrorHandler error={error} refetch={refetch} />;
    }
  }
  if (isSuccess) {
    if (data.docs) {
      if (!data.docs.length) {
        if (emptyComponent) return emptyComponent;
        return <Empty emptyLabel={emptyLabel} />;
      }
      // PAGINATED
      return data.docs.map((innerData, index) =>
        cloneElement(children, {
          data: { ...innerData, ...data2 },
          key: `${name || "cloned"}-${index}`,
          index,
          length: data.docs.length,
        })
      );
    } else if (Array.isArray(data)) {
      let filtered = data;
      if (filter) {
        filtered = data.filter(filter);
      }
      if (filtered.length === 0) {
        if (emptyComponent) return emptyComponent;
        return <Empty emptyLabel={emptyLabel} />;
      }
      return filtered.map((innerData, index) =>
        cloneElement(children, {
          data: { ...innerData, ...data2 },
          key: `${name || "cloned"}-${index}`,
          index,
          length: filtered.length,
        })
      );
    } else {
      return cloneElement(children, {
        data: { ...data, ...data2 },
        isLoadingQuery2,
      });
    }
  }
  return null;
});
