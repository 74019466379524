import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationES from "locales/es/translation.json";
import translationEN from "locales/en/translation.json";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // debug: true,
    resources: {
      es: {
        translation: translationES,
      },
      en: {
        translation: translationEN,
      },
    },
    fallbackLng: "es",
    supportedLngs: ["en", "es"],
    interpolation: {
      escapeValue: false,
    },
  });
