import {
  useGetNFTBidInfo,
  useGetNFTInfo,
  useGetNFTSaleInfo,
  useGetProfile,
} from "actions/Api";
import { FormatDate } from "components/Shared/FormatedDate";
import { utils } from "ethers";
import LogoutIcon from "@heroicons/react/outline/LogoutIcon";
import { mapCurrencyPropertyByAddress, truncateAddress } from "utils";
import { UserPill } from "components/Pages/NFTDetail2";
import { useNFTState } from "components/Pages/NFT/NFTContext";
import { useTranslation } from "react-i18next";
import { networks } from "@aztlan/config";

const TransactionContainer = ({ children }) => (
  <div className="bg-white rounded-1 border shadowed px-3 py-4 mb-2">
    <div className="d-flex align-items-center align-self-center">
      {children}
    </div>
  </div>
);

const PriceControl = ({ amount, chainId, currency }) =>
  (amount && chainId && currency && (
    <div className="d-flex flex-column">
      <div className="align-self-end">
        <h5 className="fw-bold m-0 p-0">
          {amount && utils.formatEther(amount)}{" "}
          {mapCurrencyPropertyByAddress(chainId, currency, "symbol")}
        </h5>
      </div>
      <div className="align-self-end">
        <span className="m-0 p-0 text-muted"></span>
      </div>
    </div>
  )) ||
  null;

function ProvenanceItem({ chainId, event, date = new Date(), ...rest }) {
  const { data: addressDB = {} } = useGetProfile(rest.data?.who);
  const { data: to = {} } = useGetProfile(rest.data?.to);

  const { t } = useTranslation();

  if (["AuctionEnded", "ArtWorkPurchased"].includes(event)) {
    return (
      <div className="m-4">
        <div className="d-flex justify-content-center mb-3">
          <UserPill user={addressDB} />
        </div>
        <div className="text-center">
          <div>
            <label>
              <b>{t(event)} </b>
              {addressDB?.username || truncateAddress(rest.data?.who)}
            </label>
          </div>
          <div hidden={rest.data?.price}>
            <label className="fw-bold">
              {t("Sold for")}{" "}
              <PriceControl
                amount={rest.data?.price}
                chainId={chainId}
                currency={rest.data?.currency}
              />
            </label>
          </div>
          <div>
            <small>
              <FormatDate date={date} />
            </small>
          </div>
        </div>
      </div>
    );
  }

  return (
    <TransactionContainer>
      <div>
        <UserPill user={addressDB} />
        <UserPill user={to} className="mt-2" />
      </div>
      <div className="ms-2">
        <div>
          <small className="fw-bold">{t(event)} </small>
          <small className="text-muted">
            {addressDB?.username
              ? `@${addressDB.username}`
              : truncateAddress(rest.data?.who)}
          </small>
        </div>
        <div hidden={!to?.address}>
          <small className="fw-bold">{t("En favor de")} </small>
          <small className="text-muted">
            {to?.username ? `@${to.username}` : truncateAddress(to?.address)}
          </small>
        </div>
        <div>
          <small className="fw-bold text-muted">
            <FormatDate date={date}></FormatDate>
          </small>
        </div>
      </div>
      <div className="ms-auto d-flex align-self-center align-items-center">
        <PriceControl
          amount={rest.data?.price}
          chainId={chainId}
          currency={rest.data?.currency}
        />
        <div className="ms-3" hidden={!rest?.transactionHash}>
          <a
            href={`${chainId && networks[chainId]?.blockExplorerUrls?.[0]}tx/${
              rest.transactionHash
            }`}
            target="_blank"
            rel="noreferrer"
          >
            <LogoutIcon height="25px" color="#445b78" className="pointer" />
          </a>
        </div>
      </div>
    </TransactionContainer>
  );
}

export default function NFTHistory() {
  const {
    author,
    createdAt,
    blockchainInfoDatabase: { tokenId, chainId, provenance = [] },
  } = useNFTState();

  const provenanceItems = [...provenance];

  return (
    <>
      {provenanceItems
        .sort((a, b) =>
          a.blockNumber < b.blockNumber
            ? 1
            : b.blockNumber < a.blockNumber
            ? -1
            : 0
        )
        .map((history, key) => (
          <ProvenanceItem key={key} chainId={chainId} {...history} />
        ))}

      <ProvenanceItem
        event="ArtWorkUploaded"
        date={createdAt}
        data={{ who: author }}
      />
    </>
  );
}
