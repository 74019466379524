import { contactPhone, contactEmail } from "@aztlan/config";
import { useTranslation } from "react-i18next";
import { Card, Col, Container, Row } from "react-bootstrap";
import SocialsBar from "components/Shared/SocialsBar";

export default function Help() {
  const { t } = useTranslation();

  return (
    <section className="bg-white mt-5">
      <Container>
        <Row>
          <Col lg={{ span: 6 }}>
            <h3 className="mb-3">{t("¿Como podemos ayudarte?")} </h3>
            <p className="text-muted mb-5">{t("help_subtitle")}</p>

            <Col>
              <div className="mb-5">
                <p className="fw-bold mb-0">{t("Envíanos un correo")}</p>
                <p className="text-muted mb-0">{contactEmail}</p>
              </div>
            </Col>

            <Col>
              <div className="mb-5">
                <p className="fw-bold mb-0">{t("Ó unete a nuestro discord")}</p>
                <a
                  href="https://discord.com/invite/B6XY3YDgdM"
                  className="text-muted"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://discord.com/invite/B6XY3YDgdM
                </a>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
