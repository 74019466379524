import AnimatedGallery from "components/Shared/AnimatedGallery";
import Gallery from "components/Shared/Gallery";
import Jumbotron from "components/Shared/Jumbotron";
import { SET_MODAL, useApp } from "context/context";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Row, Col, Card } from "react-bootstrap";
import { modals } from "constant";

import Hero from "assets/hero-img-01.png";
import Users from "assets/users.png";
import IconWallet from "assets/icon-wallet.svg";
import IconNFT from "assets/icon-nft.svg";
import IconNext from "assets/arrow-next.svg";

import { ReactComponent as EhereumSVG } from "assets/blockchains/ethereum.svg";
import { ReactComponent as PolygonSVG } from "assets/blockchains/polygon.svg";
import { ReactComponent as BSC } from "assets/blockchains/bsc.svg";

export default function Home() {
  const { t } = useTranslation();

  const [{ address }, dispatch] = useApp();

  const navigate = useNavigate();

  const handleConnectWallet = () => {
    dispatch({ type: SET_MODAL, modal: modals.CONNECT });
  };

  const handleGoToGallery = () => {
    navigate("/explorar");
  };

  return (
    <div>
      <section className="bg-white mt-5">
        <Container>
          <Row>
            <Col
              lg={{ span: 5, offset: 1 }}
              className="d-flex align-items-center"
            >
              <div>
                <h3 className="text-dark">
                  {t("La mejor plataforma digital para comprar y vender NFTs")}.
                </h3>
                <p className="text-muted">
                  {t(
                    "Comercializa, explora y certifica tus activos digitales en la primer plataforma de NFTs en México"
                  )}
                  .
                </p>
                {address ? (
                  <Button
                    className="mt-3 mb-5"
                    variant="secondary"
                    onClick={handleGoToGallery}
                  >
                    {t("Explorar galeria")}
                  </Button>
                ) : (
                  <Button className="mt-3 mb-5" onClick={handleConnectWallet}>
                    {t("Conectar wallet")}
                  </Button>
                )}
                <div className="d-flex flex-column flex-sm-row align-items-center mb-3">
                  <img
                    src={Users}
                    alt=""
                    className="w-25 me-3 align-self-center mb-3 mb-sm-0"
                  />
                  <small className="text-muted w-50 mb-0">
                    {t(
                      "Cientos de artistas ya crearon sus NFTs, comienza ahora"
                    )}
                  </small>
                </div>
              </div>
            </Col>
            <Col lg={{ span: 6 }}>
              <img src={Hero} alt="" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <div className="d-flex flex-column align-items-center">
            <p className="text-muted mb-4">
              {t("Crea y vende en las Blockchains principales")}
            </p>
            <div className="d-flex flex-column flex-sm-row">
              <EhereumSVG className="me-0 me-sm-5 mb-sm-0 mb-4" /> <PolygonSVG className="me-0 me-sm-5 mb-sm-0 mb-4"/> <BSC />
            </div>
          </div>
        </Container>          
      </section>

      <section className="bg-white border-bottom">
        <div className="container">
          <Row>
            <Col lg={{ span: 6 }}>
              <h3 className="mb-3">{t("Crea y vende tus NFTs")}</h3>
              <p className="text-muted mb-5">
                {t(
                  "Comenzar a vender tus propios es muy rápido y sencillo, sigue los pasos que se muestran a continuación para publicar tu primer NFT"
                )}
                .
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 4 }} className="">
              <div className="mb-5 mb-lg-0">
                <img src={IconWallet} alt="" className="img-fluid mb-3" />
                <p className="fw-bold mb-2">{t("Connecta tu Wallet")}</p>
                <p className="text-muted me-5">
                  {t(
                    "Crea o conecta tu wallet de preferencia y conectala a Azltan Market"
                  )}
                  .{" "}
                  {/* <span className="text-primary">
                    {t("¿Qué es una wallet?")}
                  </span> */}
                </p>
              </div>
            </Col>
            <Col lg={{ span: 4 }} className="">
              <div className="mb-5 mb-lg-0">
                <img src={IconNFT} alt="" className="img-fluid mb-3" />
                <p className="fw-bold mb-2">{t("Agrega tus NFTs")}</p>
                <p className="text-muted me-5">
                  {t(
                    "Sube tu arte (imagenes, videos, audios o 3D), añade un título, descripción, categoría"
                  )}
                  .
                </p>
              </div>
            </Col>
            <Col lg={{ span: 4 }} className="">
              <div>
                <img src={IconWallet} alt="" className="img-fluid mb-3" />
                <p className="fw-bold mb-2">{t("Comienza a venderlos")}</p>
                <p className="text-muted me-5">
                  {t(
                    "Tu eliges como vender tu NFT, tienes la opción de seleccionar un precio fijo o subastarlo"
                  )}
                  .
                </p>
              </div>
            </Col>
          </Row>
    
        </div>
      </section>
      {/* <AnimatedGallery /> */}
      <section className="bg-white">
        <Container>
          <div className="d-flex align-items-center mb-5">
            <h3 className="text-dark text-capitalize flex-grow-1">
              {t("tendencias")}
            </h3>
            <Link to="/explorar" className="fw-bold text-decoration-none">
              {t("explore_gallery")} <img src={IconNext} alt="" className="img-fluid" />
            </Link> 
            {/* <Card className="me-2 border-0 text-muted">
              <Card.Body className="d-flex flex-column">
                <div>
                  <small>NFTs Creados</small>
                </div>
                <div className="ms-end text-end">
                  <h5>420</h5>
                </div>
              </Card.Body>
            </Card>
            <Card className="border-0 text-muted">
              <Card.Body className="d-flex flex-column">
                <div>
                  <small>Creadores</small>
                </div>
                <div className="ms-end text-end">
                  <h5>169</h5>
                </div>
              </Card.Body>
            </Card>
            */}
          </div> 
          <Gallery />
          {/* <div className="text-center mb-2 mt-1">
            <Button size="sm" variant="secondary">
              {t("explore_gallery")}
            </Button>
          </div> */}
        </Container>
      </section>
    </div>
  );
}
