import { Button } from "react-bootstrap";
import { isChrome, isChromium, isFirefox, isSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";

const selectLink = () => {
  if (isChrome || isChromium)
    return "https://chrome.google.com/webstore/detail/binance-wallet/fhbohimaelbohpjbbldcngcnapndodjp";
  if (isFirefox)
    return "https://addons.mozilla.org/en-US/firefox/addon/binance-chain/";
  if (isSafari) return null;
};

export default function InstallBSCModal() {
  const { t } = useTranslation();
  const handleOpen = () => {
    window.open(selectLink(), "_blank");
  };
  return (
    <div className="text-center">
      <h5 className="text-dark">{t("Instala")} Binance Wallet</h5>
      {isSafari ? (
        <div>
          <div className="my-3">
            <h4 className="text-muted">
              {t(
                "Este wallet no esta disponible para tu navegador, intenta seleccionar un wallet distinto"
              )}
            </h4>
          </div>
        </div>
      ) : (
        <div>
          <div className="my-3">
            <img
              src="img/wallets/install_bsc.gif"
              className="h-100 w-100 img-fluid rounded-3"
              alt=""
            />
          </div>
          <div className="mb-3">
            <small className="text-muted">
              {t("Refresca la pantalla después de instalar la extensión")}
            </small>
          </div>
          <div>
            <Button variant="dark" className="mb-2" onClick={handleOpen}>
              {t("Descargar")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
