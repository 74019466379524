import { SET_MODAL, useApp } from "context/context";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { connectors, WALLETS } from "@aztlan/config";
import clsx from "clsx";
import { modals } from "constant";

function ConnectorSelector({
  handleConnect,
  title,
  description,
  image,
  disabled,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={clsx(
        disabled ? "text-muted not-allowed" : "text-dark",
        "bg-white pointer p-3 d-flex"
      )}
      onClick={handleConnect}
    >
      <div className="flex-shrink-0 mt-1">
        <img
          className="align-self-start mr-3"
          src={image}
          alt={t(title)}
          height="35px"
        />
      </div>
      <div className="flex-grow-1 ms-3">
        <div>
          <p className="fw-bold my-0">
            {t(title)}
            {disabled ? <small> ({t("Próximamente")})</small> : ""}
          </p>
        </div>
        <div className="lh-1">
          <small className="text-muted">{t(description)}.</small>
        </div>
      </div>
    </div>
  );
}

export default function ConnectWalletModal() {
  const { t } = useTranslation();

  const [{ connectWallet }, dispatch] = useApp();

  const handleConnectMetamask = () => {
    connectWallet(WALLETS.METAMASK, true);
  };

  const handleConnectLocalModal = () => {
    dispatch({ type: SET_MODAL, modal: modals.WARNING });
  };

  const handleConnectBSC = () => {
    connectWallet(WALLETS.BSC);
  };

  const handleConnectTorus = () => {
    connectWallet(WALLETS.TORUS);
  };

  return (
    <div>
      <div className="text-start text-sm-center mb-2">
        <h5 className="text-dark fw-bold mb-1">
          {t("Conecta tu Wallet para continuar")}
        </h5>
        <p className="text-muted mb-0">
          {t("Selecciona alguna de las siguientes opciones")}
        </p>
      </div>
      <Row>
        <Col
          md={{ span: 12, order: 0 }}
          lg={{ span: 12, order: 0 }}
          xs={{ order: 1 }}
          sm={{ order: 1 }}
          className="mt-3"
        >
          <ConnectorSelector
            handleConnect={handleConnectMetamask}
            {...connectors.metamask}
          />
        </Col>
        <Col
          md={{ span: 12, order: 0 }}
          lg={{ span: 12, order: 0 }}
          xs={{ order: 1 }}
          sm={{ order: 1 }}
          className="mt-3"
        >
          <ConnectorSelector
            handleConnect={handleConnectBSC}
            {...connectors.bsc}
          />
        </Col>

        <Col
          md={{ span: 12, order: 0 }}
          lg={{ span: 12, order: 0 }}
          xs={{ order: 0 }}
          sm={{ order: 0 }}
          className="mt-3"
        >
          <ConnectorSelector
            handleConnect={handleConnectTorus}
            {...connectors.torus}
          />
        </Col>
        <Col
          md={{ span: 12, order: 0 }}
          lg={{ span: 12, order: 0 }}
          xs={{ order: 1 }}
          sm={{ order: 1 }}
          className="mt-3"
        >
          <ConnectorSelector
            disabled
            handleConnect={handleConnectLocalModal}
            {...connectors.local}
          />
        </Col>
      </Row>

      <div className="text-center mt-4">
        <small className="text-dark">
          {t("By unlocking your wallet you agree to our")}
          <a
            href="/terminos-y-condiciones"
            target="_blank"
            className="text-secondary text-decoration-none"
          >
            {" "}
            {t("terms_and_conditions")}{" "}
          </a>
          {t("and")}
          <a
            href="/privacidad"
            target="_blank"
            className="text-secondary text-decoration-none"
          >
            {" "}
            {t("privacy_policy")}{" "}
          </a>
        </small>
      </div>
    </div>
  );
}
