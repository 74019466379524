import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { ToastContainer, Flip } from "react-toastify";
import ReactGA from "react-ga";

import useScrollTop from "hooks/useScrollTop";

import NavBar from "components/Shared/NavBar";
import Footer from "components/Shared/Footer";
import { PrivateRoute } from "components/Helpers/PrivateRoute";
import { ModalContainer } from "components/Shared/ModalContainer";

// Pages
import Exclusives from "components/Pages/Exclusives";
import Tokenomics from "components/Pages/Tokenomics";
import Blog from "components/Pages/Blog";
import Explore from "components/Pages/Explore";
import Profile from "components/Pages/Profile";
import About from "components/Pages/About";
import Home from "components/Pages/Home";
import Mint from "components/Pages/Mint";
import BlogEntry from "components/Pages/BlogEntry";
import Plans from "components/Pages/Plans";
import Gallery from "components/Pages/Gallery";
import Stats from "components/Pages/Stats";
// import Whitepaper from "components/Pages/Whitepaper";
import Terms from "components/Pages/Terms";
import Privacy from "components/Pages/Privacy";
import Verify from "components/Pages/Verify";
import NFTCertificate from "components/Pages/NFT/NFTCertificate";
import NFTHistory from "components/Pages/NFT/NFTHistory";
import NFTDetail from "components/Pages/NFTDetail2";
import RoadMap from "components/Pages/RoadMap";
import Artists from "components/Pages/Artists";
import HowItWorks from "components/Pages/HowItWorks";
import Balances from "components/Pages/Balances";
import Help from "components/Pages/Help";
import Staking from "components/Pages/Staking";
import Newsletter from "components/Pages/Newsletter";
import Notifications from "components/Pages/Notifications";
import Airdrop from "components/Pages/Airdrop";
// import ComingSoon from "components/Pages/ComingSoon";
// import RightSideBar from "components/Shared/Backdrop";
// import { ReactQueryDevtools } from "react-query/devtools";

function AllRoutes() {
  useScrollTop();
  ReactGA.initialize("UA-212429051-1");
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <Routes>
      {/* Public routes */}
      <Route path="/destacados" element={<Exclusives />} />
      <Route path="/explorar" element={<Explore />} />
      <Route path="/nft/:id/certificado" element={<NFTCertificate />} />
      <Route path="/nft/:tokenId/historial" element={<NFTHistory />} />
      <Route path="/nft/:id" element={<NFTDetail />} />
      <Route path="/creadores" element={<Artists />} />
      <Route path="/como-funciona" element={<HowItWorks />} />
      <Route path="/galeria/:id" element={<Gallery />} />
      <Route path="/tokenomics" element={<Tokenomics />} />
      <Route path="/acerca" element={<About />} />
      <Route path="/planes" element={<Plans />} />
      <Route path="/blog/:slug" element={<BlogEntry />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/road-map" element={<RoadMap />} />
      {/* <Route path={["/whitepaper", "/en/whitepaper"]} element={<Whitepaper/>} /> */}
      <Route path="/perfil/:address" element={<Profile />} />
      <Route path="/estadisticas" element={<Stats />} />
      <Route path="/newsletter" element={<Newsletter />} />
      <Route path="/verificador" element={<Verify />}>
        <Route path=":sha256" element={<Verify />} />
      </Route>
      <Route path="/airdrop" element={<Airdrop />} />
      <Route path="/terminos-y-condiciones" element={<Terms />} />
      <Route path="/privacidad" element={<Privacy />} />
      <Route path="/ayuda" element={<Help />} />
      {/* <Route path="/staking" element={<Staking/>} /> */}

      {/* Private routes */}
      <Route
        path="/balances"
        element={
          <PrivateRoute>
            <Balances />
          </PrivateRoute>
        }
      >
        <Route
          path=":address"
          element={
            <PrivateRoute>
              <Balances />
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path="/minar"
        element={
          <PrivateRoute>
            <Mint />
          </PrivateRoute>
        }
      >
        <Route
          path=":address"
          element={
            <PrivateRoute>
              <Mint />
            </PrivateRoute>
          }
        ></Route>
      </Route>
      <Route
        path="/perfil"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
      <Route
        path="/notificaciones"
        element={
          <PrivateRoute>
            <Notifications />
          </PrivateRoute>
        }
      />

      {/* Home */}
      {/* <Route path="/main" element={<Home/>} /> */}
      <Route path="/" element={<Home />} />
    </Routes>
  );
}
function Main() {
  return (
    <>
      {/* <ReactQueryDevtools initialIsOpen={true}></ReactQueryDevtools> */}
      <ToastContainer
        position="bottom-right"
        transition={Flip}
        hideProgressBar={true}
      />
      <ModalContainer />
      <BrowserRouter>
        {/* <RightSideBar /> */}
        <NFTDetail />
        <NavBar fixed="top" expand="lg" className="py-3 bg-white" />
        <AllRoutes />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default Main;
