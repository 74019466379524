import { useReportNFT } from "actions/Api";
import { useAppState } from "context/context";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const ReportForm = ({
  handleSubmit,
  doSubmit,
  register,
  reasonWatch,
  t,
  isValid,
}) => (
  <form onSubmit={handleSubmit(doSubmit)}>
    <h5 className="fw-bold mb-1">{t("Report this nft")}</h5>
    <p className="text-muted mb-0">
      {t("What are your reporting this nft for?")}
    </p>
    <Row>
      <Col lg={{ span: 8, offset: 2 }}>
        <Form.Select
          size="lg"
          className="text-center my-3"
          {...register("reason", { required: true })}
        >
          <option></option>
          <option value="copyright">{t("Copyright asset")}</option>
          <option value="imporsonating">
            {t("Impersonating someone else")}
          </option>
          <option value="innapropiate">{t("Inappropiate content")}</option>
          <option value="other">{t("Other")}</option>
        </Form.Select>
      </Col>
    </Row>
    <Row hidden={reasonWatch !== "other"}>
      <Col lg={{ span: 8, offset: 2 }}>
        <Form.Control
          size="lg"
          className="bg-dark mb-4"
          placeholder={t("Explain further")}
          {...register("detail", { required: reasonWatch === "other" })}
        />
      </Col>
    </Row>
    <div>
      <Button type="submit" disabled={!isValid}>
        {t("Reportar")}
      </Button>
    </div>
    <div className="mt-2">
      <div>
        <small>{t("We will take down nfts that violate our")}</small>
      </div>
      <div>
        <small className="fw-bold">{t("terms_and_conditions")}</small>
      </div>
    </div>
  </form>
);

const Reported = ({ t }) => (
  <div>
    <h4>{t("Revisaremos tu reporte")}</h4>
    <small className="text-muted">{t("")}</small>
  </div>
);

export default function ReportModal() {
  const { t } = useTranslation();

  const [reported, setReported] = useState(false);

  const { modalProps } = useAppState();

  const {
    watch,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: "all" });

  const { mutate } = useReportNFT();

  const reasonWatch = watch("reason");

  const doSubmit = (values) => {
    mutate(
      { id: modalProps._id, ...values },
      {
        onSuccess: () => {
          setReported(true);
        },
      }
    );
  };

  return (
    <div className="text-center">
      {reported ? (
        <Reported t={t} />
      ) : (
        <ReportForm
          t={t}
          register={register}
          handleSubmit={handleSubmit}
          isValid={isValid}
          reasonWatch={reasonWatch}
          doSubmit={doSubmit}
        />
      )}
    </div>
  );
}
