import { useGetArtWork } from "actions/Api";
import DropZone from "components/Shared/DropZone";
import { FetcherComponent } from "components/Shared/Fetcher";
import GalleryImage from "components/Shared/GalleryImage";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { calculateSHA256 } from "utils";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <h5 className="text-center fw-bold">
      {t("El archivo no ha sido registrado")}
    </h5>
  );
};
const HashViewer = ({ sha256, handleReset }) => {
  const { t } = useTranslation();
  return (
    <div className="">
      <div className="my-3">
        <h5 className="text-muted fw-bold">SHA256</h5>
        <span className="text-muted">{sha256}</span>
      </div>
      <Button
        hidden={!sha256}
        onClick={handleReset}
        className="bg-primary border-0 my-2 fw-bold"
      >
        {t("Buscar otro archivo")}
      </Button>
    </div>
  );
};
const DropFile = ({ onDrop, handleSearch, setSearch, search }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <DropZone onDrop={onDrop} text="file_to_verify" className="my-3" />
        <small className="text-muted">
          *{t("El archivo no saldrá de tu computadora")}
        </small>
      </div>
      <div className="mt-3">
        <Form.Control
          type="text"
          onChange={({ target }) => setSearch(target.value)}
          className="text-center p-5"
          placeholder={t("Ó ingresa el SHA256 de tu archivo")}
        />
        <Button
          disabled={!search}
          className="mt-3"
          onClick={handleSearch}
        >
          {t("Buscar archivo")}
        </Button>
      </div>
    </div>
  );
};

export default function Verify() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [search, setSearch] = useState();

  const { sha256 } = useParams();

  const onDrop = async (files) => {
    navigate(`/verificador/${await calculateSHA256(files?.[0])}`);
  };

  const query = useGetArtWork(sha256, { enabled: !!sha256, retry: false });

  const handleReset = () => {
    navigate("/verificador");
  };

  const handleSearch = () => {
    navigate(`/verificador/${search}`);
  };

  return (
    <section className="bg-white mt-5">
      <Container>
        <Row>
          <Col lg={{ span: 5, offset: 1 }}>
            <h3 className="mb-0">{t("Verificador")}</h3>
            <div className="text-muted mb-5" hidden={sha256}>
              <span>
                {t(
                  "El verificador te permite encontrar un archivo dentro del sistema"
                )}
                :
              </span>
            </div>
            {sha256 ? (
              <HashViewer sha256={sha256} handleReset={handleReset} />
            ) : (
              <DropFile
                onDrop={onDrop}
                search={search}
                setSearch={setSearch}
                handleSearch={handleSearch}
              />
            )}
          </Col>
          <Col lg={{ span: 5, offset: 1 }}>
            <FetcherComponent query={query} errorComponent={<NotFound />}>
              <GalleryImage />
            </FetcherComponent>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
