import React, { Fragment } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import { useGetArtWork, useQR } from "actions/Api";
import { useNavigate, useParams } from "react-router-dom";
import { FetcherComponent } from "components/Shared/Fetcher";
import { useTranslation } from "react-i18next";
import { FormatDate } from "components/Shared/FormatedDate";
import { Container, Button } from "react-bootstrap";

const excluded = ["id", "name", "IpfsUrl"];
function Certificate(props) {
  const { t } = useTranslation();

  const {
    data: {
      // IpfsUrl,
      // _id,
      // author,
      // authorInfo,
      // blockHash,
      // blockNumber,
      // chainId,
      // contractAddress,
      // createdAt,
      // currency,
      // decimals,
      // description,
      // filetype,
      // id,
      // minted,
      // name,
      // onSale,
      // owner,
      // ownerInfo,
      // price,
      // sha256,
      // tags,
      // tokenId,
      // transactionHash,
      // updatedAt,
      // qr
    },
    isLoadingQuery2,
  } = props;

  if (isLoadingQuery2) return null;

  return (
    <div className="w-100" style={{ height: "1000px" }}>
      <PDFViewer className="w-100 h-100">
        <Document>
          <Page style={styles.body}>
            <Text style={styles.title}>NFT</Text>
            <Text style={styles.subtitle}>{props.data.name}</Text>
            {Object.keys(props.data)
              .filter((p) => !excluded.includes(p))
              .map(
                (name, key) =>
                  typeof props.data[name] === "string" && (
                    <Fragment key={key}>
                      {name === "qr" ? (
                        <Image style={styles.image} src={props.data[name]} />
                      ) : (
                        <>
                          <Text style={styles.text}>
                            {t(`certificate.${name}`)}
                          </Text>
                          <Text style={styles.subtext}>{props.data[name]}</Text>
                        </>
                      )}
                    </Fragment>
                  )
              )}
            <Text style={styles.footer}>{t("Certificado por AZTLAN")}</Text>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
}

function NFTCertificate() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useGetArtWork(id, { refetchOnWindowFocus: false });
  const query2 = useQR(`${window.location.origin}/nft/${id}`, {
    refetchOnWindowFocus: false,
  });
  const goBack = () => navigate(-1);

  return (
    <section>
      <Container className="mt-5">
        <h3 className="mb-2">{t("Certificado blockchain")}</h3>
        <Button onClick={goBack}>{t("Volver")}</Button>
        <FetcherComponent query={query} query2={query2}>
          <Certificate />
        </FetcherComponent>
      </Container>
    </section>
  );
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingHorizontal: 30,
  },
  title: {
    fontSize: 30,
    fontWeight: 700,
  },
  subtitle: {
    fontSize: 25,
    paddingRight: 100,
    fontWeight: 700,
    paddingBottom: 30,
  },
  subtext: {
    marginTop: 6,
    fontSize: 10,
    marginBottom: 24,
  },
  text: {
    fontSize: 12,
    fontWeight: 700,
  },
  image: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 100,
    height: 100,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 8,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  footer: {
    position: "absolute",
    fontSize: 8,
    bottom: 15,
    right: 20,
  },
});

export default NFTCertificate;
