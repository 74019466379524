import { Loading } from "components/Shared/Fetcher";
import { useTranslation } from "react-i18next";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import SearchInput from "components/Shared/SearchInput";
import { useInfinityGallery } from "actions/Api";
import GalleryImage from "components/Shared/GalleryImage";
import { categoryTags as existingCategoryTags } from "constant";
import { useImmerReducer } from "use-immer";
import { Fragment, useEffect, useRef, useState } from "react";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import useDebounce from "hooks/useDebounce";
import GalleryImageLoader from "components/Shared/GalleryImageLoader";
import Select from "react-select";
// import { CustomCheckbox } from 'components/Shared/CustomCheckbox';

function LeftSide({ setQueryFilter }) {
  const { t } = useTranslation();

  const handleChangeSortBy = ({ value }) => {
    setQueryFilter({ type: "sortBy", sortBy: value });
  };
  const handleChangeByStatus = ({ value }) => {
    setQueryFilter({ type: "status", status: value });
  };
  const handleChangeByVerified = ({ value }) => {
    setQueryFilter({ type: "verified", verified: value });
  };

  return (
    <div className="d-flex flex-row-reverse gap-3">
      <Select
        styles={{
          control: (style) => ({ ...style, padding: 0, fontSize: "13px" }),
        }}
        classNamePrefix="react-select"
        isSearchable={false}
        defaultValue={[{ value: "newest", label: t("Más nuevo") }]}
        options={[
          { value: "newest", label: t("Más nuevo") },
          { value: "oldest", label: t("Más viejo") },
          { value: "pricest", label: t("Más caro") },
          { value: "cheapest", label: t("Más barato") },
        ]}
        onChange={handleChangeSortBy}
      ></Select>
      <Select
        styles={{
          control: (style) => ({ ...style, padding: 0, fontSize: "13px" }),
        }}
        classNamePrefix="react-select"
        isSearchable={false}
        defaultValue={[{ value: "all", label: t("Todos los estatus") }]}
        options={[
          { value: "all", label: t("Todos los estatus") },
          { value: "onSale", label: t("Solo venta") },
          { value: "onAuction", label: t("Solo subasta") },
          { value: "onlyExhibit", label: t("Solo exhibición") },
        ]}
        onChange={handleChangeByStatus}
      ></Select>
      <Select
        styles={{
          control: (style) => ({ ...style, padding: 0, fontSize: "13px" }),
        }}
        classNamePrefix="react-select"
        isSearchable={false}
        defaultValue={[
          { value: "all", label: t("Verificados y no verificados") },
        ]}
        options={[
          { value: "all", label: t("Verificados y no verificados") },
          { value: "verified", label: t("Solo verificados") },
        ]}
        onChange={handleChangeByVerified}
      ></Select>
      {/* <CustomCheckbox
                label="Verificados"
                checked={false}
                onChange={()=>{}}
            /> */}
      {/* <input type="range" className="form-range" id="customRange1" onChange={handleChangeVerified}></input>Precio */}
    </div>
  );
}

const styles = {
  control: (style) => ({ ...style, padding: "0px", fontSize: "13px" }),
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: "none" } : base;
  },
};

function RightSide({ query, setQueryFilter, queryFilter }) {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState("");

  // const handleSelectAllBadges = () => {
  //   setQueryFilter({ type: "selectAll" });
  // };

  const { selectedTags } = queryFilter;

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    setQueryFilter({ type: "name", name: searchTerm });
  }, [debouncedSearchTerm]);

  const {
    fetchNextPage,
    hasNextPage,
    status,
    error,
    // fetchPreviousPage,
    // hasPreviousPage,
    // isFetchingPreviousPage,
    isFetchingNextPage,
    data = {},
  } = query;

  const loadMoreButtonRef = useRef();

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  return (
    <div>
      <div className="mb-4">
        <h3 className="mb-3">{t("Descubre los mejores NFTs")}</h3>
        <SearchInput setSearchTerm={setSearchTerm} />
      </div>
      <Row>
        <Col md="3" className="mt-4">
          <Select
            classNamePrefix="react-select"
            closeMenuOnSelect={false}
            closeMenuOnScroll={true}
            placeholder={`${t("Filtra por etiqueta")}...`}
            noOptionsMessage={t("No hay mas opciones")}
            options={existingCategoryTags.map((tag) => ({
              value: tag,
              label: t(tag),
            }))}
            // isClearable={selectedTags?.some((tag) => !tag.isFixed)}
            value={selectedTags}
            styles={styles}
            isMulti
            onChange={(value) =>
              setQueryFilter({
                type: "selectMultiple",
                selectedTags: value,
              })
            }
          />
        </Col>
        <Col md="9" className="mt-4">
          <LeftSide setQueryFilter={setQueryFilter} queryFilter={queryFilter} />
        </Col>
      </Row>
      <Row className="mt-5">
        {status === "loading" ? (
          <Row>
            {[...Array(8)].map((val, key) => (
              <GalleryImageLoader key={key} />
            ))}
          </Row>
        ) : status === "error" ? (
          <span>Error: {error.message}</span>
        ) : (
          <>
            {/* <div className=" text-center">
                                <button
                                    className="btn bg-dark-x"
                                    onClick={() => fetchPreviousPage()}
                                    disabled={!hasPreviousPage || isFetchingPreviousPage}
                                >
                                    {isFetchingNextPage
                                        ? 'Loading more...'
                                        : hasNextPage
                                            ? 'Load Older'
                                            : 'Nothing more to load'}
                                </button>
                            </div> */}
            {data.pages?.map((page) => (
              <Fragment key={page.page}>
                {page?.docs?.map((props) => (
                  <GalleryImage
                    data={props}
                    key={props._id}
                    className="mb-5"
                    lg="3"
                    md="4"
                    sm="12"
                    fixedheight="true"
                  />
                ))}
              </Fragment>
            ))}
            <div className="text-center p-4">
              <button
                className="btn btn-secondary btn-sm"
                ref={loadMoreButtonRef}
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetchingNextPage}
              >
                {isFetchingNextPage
                  ? t("Loading more...")
                  : hasNextPage
                  ? t("Load Newer")
                  : t("Nothing more to load")}
              </button>
            </div>
          </>
        )}
      </Row>
    </div>
  );
}

export default function Explore() {
  const [queryFilter, setQueryFilter] = useImmerReducer(
    (draft, action) => {
      switch (action.type) {
        case "selectMultiple":
          draft.selectedTags = action.selectedTags;
          draft.tags = action.selectedTags.map((t) => t.value);
          // if (
          //   action.selectedTags.findIndex((t) => t.isFixed) > 0 ||
          //   action.selectedTags.length === 0
          // ) {
          //   draft.selectedTags = [existingCategoryTags[0]];
          //   draft.tags = null;
          // } else {
          //   draft.selectedTags = action.selectedTags.filter((t) => !t.isFixed);
          //   draft.tags = action.selectedTags
          //     .filter((t) => !t.isFixed)
          //     .map((t) => t.value);
          // }
          break;
        case "name":
          draft.name = action.name;
          break;
        case "sortBy":
          draft.sortBy = action.sortBy;
          break;
        case "status":
          draft.status = action.status;
          break;
        case "priceRange":
          draft.priceRange = action.priceRange;
          break;
        case "verified":
          draft.verified = action.verified;
          break;
        // case "loadMore":
        //     draft.page = draft.page + 1;
        //     break;
        // case "loadLess":
        //     draft.page = draft.page - 1;
        //     break;
        default:
          break;
      }
    },
    {
      sortBy: "newest",
      status: "all",
      name: "",
      verified: "all",
      // selectedTags: [existingCategoryTags[0]],
      // page: 1
    }
  );

  const query = useInfinityGallery(queryFilter, {
    getPreviousPageParam: (firstPage) => firstPage.prevPage ?? false,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? false,
  });

  return (
    <section className="bg-white mt-5">
      <Container>
        <Row className="">
          <Col lg={{ span: 12 }}>
            <RightSide
              query={query}
              setQueryFilter={setQueryFilter}
              queryFilter={queryFilter}
            />
          </Col>
          {/* <Col lg={{ span: 6 }}>
            <LeftSide
              setQueryFilter={setQueryFilter}
              queryFilter={queryFilter}
            />
          </Col> */}
        </Row>
      </Container>
    </section>
  );
}
