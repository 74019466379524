import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  Col,
  Container,
  Row,
  Modal,
  Badge,
  Dropdown,
  Form,
  Button,
  FormGroup,
} from "react-bootstrap";
import Countdown from "react-countdown";

import ShareIcon from "@heroicons/react/outline/ShareIcon";
import DotsHorizontalIcon from "@heroicons/react/outline/DotsHorizontalIcon";
import ArrowDownIcon from "@heroicons/react/outline/ArrowDownIcon";
import { useGetProfile } from "actions/Api";
import { SET_MODAL, useApp, useAppState } from "context/context";
import Previewer from "components/Shared/Previewer2";
import NFTControlsBuyer from "components/Pages/NFT/NFTControlsBuyer";
import NFTControlsOwner from "components/Pages/NFT/NFTControlsOwner";
import NFTControlsMinter from "components/Pages/NFT/NFTControlsMinter";
import NFTControlsBidder from "components/Pages/NFT/NFTControlsBidder";
import NFTVouchers from "components/Pages/NFT/NFTVouchers";
import {
  isSameAddress,
  mapCurrency,
  mapCurrencyPropertyByAddress,
  truncateAddress,
  zeroAddress,
} from "utils";
import { FormatedDateRelative } from "components/Shared/FormatedDate";
import { NFTProvider, useNFTState } from "./NFT/NFTContext";
import BuyerModal from "components/Modals/BuyerModal";
import BidModal from "components/Modals/BidModal";
import SocialsBar from "components/Shared/SocialsBar";
import { networks } from "@aztlan/config";
import { createAvatar } from "@dicebear/avatars";
import * as style from "@dicebear/big-smile";
import NFTHistory from "components/Pages/NFT/NFTHistory";
import { utils } from "ethers";
import clsx from "clsx";
import { useWindowSize } from "@react-hook/window-size/throttled";
import { ReactComponent as TwitterIcon } from "assets/twitter.svg";
import { ReactComponent as FacebookIcon } from "assets/facebook.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as LogoGray } from "assets/logos/LogoGray.svg";
import { toast } from "react-toastify";
import { modals } from "constant";
import RippleButton from "components/Shared/RippleButton";
import CustomToggle from "components/Shared/CustomToggle";
import { isMobile } from "react-device-detect";

const tagWidth = "50px";

const units = ["days", "hours", "minutes", "seconds"];

const BidControllers = () => {
  const { blockchainInfo } = useNFTState();

  const { chainId } = useAppState();

  const { t } = useTranslation();

  const { data: user = {} } = useGetProfile(blockchainInfo.highestBidder);

  if (!blockchainInfo.onAuction) return null;

  const renderer = ({ completed, show, ...props }) => {
    if (completed) {
      // Render a completed state
      return (
        <div className="fw-bold text-muted mt-2">
          {t("La subasta ha terminado")}
        </div>
      );
    } else {
      // Render a countdown
      return (
        <div className="d-flex justify-content-between align-items-center text-center text-muted">
          {units.map((unit) => (
            <div
              className="me-2 d-inline"
              style={{ width: tagWidth }}
              key={unit}
            >
              <div className="bg-white">
                <h3 className="text-dark mb-0">
                  {String(props[unit]).length > 1
                    ? props[unit]
                    : `0${props[unit]}`}
                </h3>
              </div>
              <div>
                <small>{t(unit)}</small>
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <Row>
      <Col lg={{ span: 5 }}>
        <small className="fw-bold text-muted">
          {isSameAddress(blockchainInfo.highestBidder, zeroAddress)
            ? t("Initial Bid")
            : t("Current Bid")}
        </small>
        <div className="d-flex align-self-center align-items-center">
          <div>
            <CurrencyImage
              chainId={chainId}
              currency={blockchainInfo.bidCurrency}
              style={{ height: "35px" }}
              className="me-2"
            />
          </div>
          <div>
            <h3 className="fw-bold m-0">
              {blockchainInfo.highestBid &&
                utils
                  .formatUnits(blockchainInfo.highestBid, 18)
                  .toString()}{" "}
              {mapCurrencyPropertyByAddress(
                chainId,
                blockchainInfo.bidCurrency,
                "symbol"
              )}
            </h3>
          </div>
        </div>
        <div hidden={isSameAddress(blockchainInfo.highestBidder, zeroAddress)}>
          <div className="d-flex align-items-center align-self-center mt-1">
            <UserPill user={user} height="24px" width="24px" />
            <span className="text-muted fw-bold ms-2">
              {user.username
                ? `@${user.username}`
                : truncateAddress(blockchainInfo.highestBidder)}
            </span>
          </div>
        </div>
      </Col>
      <Col lg={{ span: 7 }}>
        <small className="fw-bold text-muted">{t("Auction ends in")}</small>
        <Countdown
          date={new Date(blockchainInfo.biddingTime * 1000)}
          renderer={renderer}
        />

        {/* <div className="d-flex">
          <div>
            <h3 className="fw-bold m-0">12</h3>
            <small>Hours</small>
          </div>
          <div className="ms-5">
            <h3 className="fw-bold m-0">12</h3>
            <small>Hours</small>
          </div>
          <div className="ms-5">
            <h3 className="fw-bold m-0">12</h3>
            <small>Hours</small>
          </div>
        </div> */}
      </Col>
    </Row>
  );
};

function NoAddress() {
  const { address } = useAppState();
  const { t } = useTranslation();

  if (address) return null;

  return (
    <div>
      <div className="bg-light lh-sm mt-5 p-3 d-inline-block rounded-3">
        <p className="text-dark fw-bold mb-0">{t("No account connected")}</p>
        <div>
          <small className="text-muted my-4">
            {t("Please connect your Web3 wallet")}
          </small>
        </div>
      </div>
    </div>
  );
}

function CurrencyImage({ chainId, currency = "0x", ...rest }) {
  return (
    <img
      alt=""
      src={`/img/currencies/${mapCurrency(
        chainId,
        currency
      ).toLowerCase()}.png`}
      {...rest}
    />
  );
}

function PriceControls() {
  const {
    blockchainInfo: { saleCurrency, sellingPrice, onSale },
  } = useNFTState();

  const { chainId } = useAppState();

  const { t } = useTranslation();

  if (!onSale) return null;

  return (
    <div className="mb-3">
      <div className="d-flex align-self-center align-items-center">
        <CurrencyImage
          chainId={chainId}
          currency={saleCurrency}
          style={{ height: "24px" }}
          className="me-2"
        />
        <h3 className="fw-bold m-0">
          {sellingPrice && utils.formatUnits(sellingPrice, 18).toString()}{" "}
          {mapCurrencyPropertyByAddress(chainId, saleCurrency, "symbol")}
        </h3>
      </div>
      <small className="fw-bold text-muted">{t("Precio de venta")}</small>
    </div>
  );
}

const NewControllers = () => {
  return (
    <Row className="border-start">
      <Col lg={{ span: 10, offset: 1 }}>
        <BidControllers />
        <PriceControls />
        <NFTControlsOwner />
        <NFTControlsBidder />
        <NFTControlsMinter />
        <NFTControlsBuyer />
        <NFTVouchers />
        <NoAddress />
      </Col>
    </Row>
  );
};

export function UserPill({
  user,
  height = "40px",
  width = "40px",
  className,
  noShadow,
}) {
  const avatar = useMemo(() => {
    if (!user.address) return null;
    if (user.imageUrl) return user.imageUrl;
    return createAvatar(style, {
      seed: String(user.address).toLowerCase(),
      dataUri: true,
    });
  }, [user.address, user.imageUrl]);

  if (!user?.address) return null;

  return (
    <div
      className={clsx(
        "rounded-pill  me-2",
        noShadow ? "border" : "shadow",
        className
      )}
      style={{
        height,
        width,
        backgroundImage: `url("${avatar}")`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    />
  );
}

function Provenance() {
  return <NFTHistory />;
}

function UserComponent({ address, title, noShadow }) {
  const navigate = useNavigate();

  const { data: user = {} } = useGetProfile(address, {
    refetchOnWindowsFocus: false,
  });

  const { t } = useTranslation();

  if (!address) {
    return null;
  }

  const handleGoToProfile = () => navigate(`/perfil/${address}`);

  return (
    <div onClick={handleGoToProfile}>
      <div className="mb-2">
        <small className="fw-bold text-muted">{t(title)}</small>
      </div>
      <div
        className="shadowed border pe-2 ps-2 pe-4"
        style={{ borderRadius: "2rem" }}
      >
        <div className="d-flex align-self-center align-items-center align-items-middle pointer p-2">
          <div>
            <UserPill user={user} noShadow={noShadow} />
          </div>
          <div className="text-truncate">
            <span className="fw-bold m-0 p-0 ms-2">
              {user.username
                ? `@${user.username}`
                : truncateAddress(user.address, 6)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function Controls() {
  const [, dispatch] = useApp();
  const { t } = useTranslation();

  const nft = useNFTState();
  const handleCopyLocation = () => {
    navigator.clipboard.writeText(window.location);
    toast.info(t("Copiado al portapapeles"));
  };

  const handleReport = () =>
    dispatch({
      type: SET_MODAL,
      modal: modals.REPORT,
      modalProps: { _id: nft._id },
    });

  return (
    <div className="d-flex mb-3">
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <Button
            variant="default"
            className="rounded-pill shadowed p-3 pointer"
          >
            <DotsHorizontalIcon width="24px" />
          </Button>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item className="text-danger" onClick={handleReport}>
            {t("Reportar")}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="ms-3">
        <Dropdown.Toggle as={CustomToggle}>
          <Button
            variant="default"
            className="rounded-pill shadowed p-3 pointer fw-bold"
          >
            <ShareIcon width="24px" className="me-2" />
            {t("Share")}
          </Button>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-dark pt-2 pb-3">
          <Dropdown.Header tag="div">
            <span className="fw-bold text-dark">{t("Share")}</span>
          </Dropdown.Header>
          <Dropdown.Item
            target="_blank"
            rel="noreferrer"
            className="text-muted text-decoration-none"
            onClick={handleCopyLocation}
          >
            <ShareIcon height="25px" className="pointer" />
            <span className="text-muted ms-3">{t("Copiar link")}</span>
          </Dropdown.Item>
          <Dropdown.Item
            className="text-white text-decoration-none"
            target="_blank"
            rel="noreferrer"
            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
              `${t("Te recomiendo ver este NFT en Aztlan Market")} ${
                window.location
              }`
            )}`}
          >
            <TwitterIcon />
            <span className="text-muted ms-3">Twitter</span>
          </Dropdown.Item>
          <Dropdown.Item
            target="_blank"
            rel="noreferrer"
            className="text-white text-decoration-none"
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              window.location
            )}&t=Aztlan`}
          >
            <FacebookIcon />
            <span className="text-muted ms-3">Facebook</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

function NFTDetailModal({ id, handle }) {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (window.history.length > 3) navigate(-1);
    else navigate("/");
  };

  const { t } = useTranslation();

  const nftName = useRef();

  const [{ chainId }] = useApp();

  const nft = useNFTState();

  const { data: user = {} } = useGetProfile(nft.author);

  const handleScrollToTitle = () =>
    nftName.current.scrollIntoView({ behavior: "smooth" });

  const [width, height] = useWindowSize({ initialHeight: 700, fps: 4 });

  const handleCopySha256 = () => {
    navigator.clipboard.writeText(nft.sha256);
    toast.info(t("Copiado al portapapeles"));
  };

  const handleEnterFullScreen = () => {
    if (!isMobile) {
      handle.enter();
    }
  };

  return (
    <Modal
      show={!!id}
      fullscreen
      size="lg"
      dialogClassName={"pt-5 px-0"}
      contentClassName="rounded-top bg-light"
      backdropClassName="bg-dark-x"
      onHide={handleGoBack}
    >
      <div className="position-relative">
        <div
          className=" position-absolute top-0 end-0"
          style={{ marginTop: "-55px" }}
        >
          <Button onClick={handleGoBack} variant="default" className="">
            <CloseIcon stroke="white" height="15px" width="15px" />
          </Button>
        </div>
      </div>
      <Modal.Body className="p-0 pt-3">
        {/* <NavBar className="shadow-none px-6" /> */}
        <Container fluid className="no-gutters">
          {/* Image */}
          <Row>
            <Col className="text-center">
              <div className="mt-2">
                <Previewer
                  onClick={handleEnterFullScreen}
                  className="shadow pointer-zoom-in"
                  style={{
                    maxWidth: `${width - 50}px`,
                    maxHeight: `${height - 250}px`,
                  }}
                  name={nft.name}
                  nsfw={nft.nsfw}
                  src={nft.IpfsUrl}
                  path={nft.filetype}
                  sha256={nft.sha256}
                  isMistery={nft.isMistery}
                  filetype={nft.filetype}
                />
              </div>
              <ArrowDownIcon
                height="20px"
                className="mt-5 mb-3 pointer"
                onClick={handleScrollToTitle}
              />
            </Col>
          </Row>

          {/* Title */}
          <Row className="bg-white px-lg-6 py-5" ref={nftName}>
            <Col>
              <h2 className="fw-bold">{nft.name}</h2>
            </Col>
          </Row>

          {/* Minted */}
          <Row className="bg-white px-lg-6">
            <Col lg="6" md="6">
              <div className="mb-3">
                <small className="fw-bold text-muted">
                  {t("Created")} <FormatedDateRelative date={nft.createdAt} />
                </small>

                <div className="mt-1">
                  {nft.tags?.map((tag, index) => (
                    <Badge
                      key={index}
                      className="rounded-1 text-capitalize bg-dark fw-bold me-2 shadowed cursor-default p-2 mt-2"
                    >
                      {t(tag)}
                    </Badge>
                  ))}
                </div>
              </div>

              <Row className="mb-5">
                <Col lg="4" md="7" sm="8" className="mt-4">
                  <UserComponent
                    address={nft.author}
                    title="Created by"
                    noShadow
                  />
                </Col>
                <Col lg="4" md="7" sm="8" className="mt-4">
                  <UserComponent
                    address={nft.blockchainInfo?.owner}
                    title="Owned by"
                    noShadow
                  />
                </Col>
              </Row>
            </Col>

            <Col lg="6" md="6">
              <NewControllers />
            </Col>
          </Row>

          {/* Description */}
          <Row className="bg-white px-lg-6 pt-5">
            <Col lg="6">
              <div className="mb-4">
                <h5 className="fw-bold">{t("Description")}</h5>
                <div className="border-top mt-3 mb-4" />
                <label>{nft.description}</label>
              </div>

              <div className="mb-5">
                <span className="text-muted fw-bold">{nft.username}</span>
              </div>

              <div className="mb-4">
                <h5 className="fw-bold">{t("Details")}</h5>
                <div className="border-top mt-3" />
              </div>

              <div className="mb-5">
                <FormGroup
                  className="mb-4"
                  hidden={!nft.blockchainInfo?.tokenId}
                >
                  <div>
                    <Form.Label className="text-muted fw-bold m-0">
                      {t("NFT Id")}
                    </Form.Label>
                  </div>
                  <a
                    href={`${networks[chainId]?.blockExplorerUrls?.[0]}token/${nft.blockchainInfoDatabase?.contractAddress}?a=${nft.blockchainInfo?.tokenId}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-muted text-decoration-none fw-light"
                  >
                    <small>{nft.blockchainInfo?.tokenId}</small>
                  </a>
                </FormGroup>
                <FormGroup className="mb-4">
                  <div>
                    <Form.Label className="text-muted fw-bold m-0">
                      SHA256
                    </Form.Label>
                  </div>
                  <div>
                    <RippleButton
                      className="m-0 p-0 text-muted pointer fw-light"
                      onClick={handleCopySha256}
                    >
                      <small>{truncateAddress(nft.sha256, 20)}</small>
                    </RippleButton>
                  </div>
                </FormGroup>
                <FormGroup className="mb-4">
                  <div>
                    <Form.Label className="text-muted fw-bold m-0">
                      IPFS
                    </Form.Label>
                  </div>
                  <div>
                    <a
                      hidden={!nft.IpfsHash}
                      href={`https://ipfs.io/ipfs/${nft.IpfsHash}`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none text-muted fw-light"
                    >
                      <small>{truncateAddress(nft.IpfsHash, 25)}</small>
                    </a>
                  </div>
                </FormGroup>
              </div>

              <Controls />
            </Col>

            <Col lg="6">
              <div>
                <h5 className="fw-bold">{t("Provenance")}</h5>
                <div className="border-top mt-3 mb-2" />
                <Provenance />
              </div>
            </Col>
          </Row>

          {/* Creator */}
          <Row className="bg-white px-lg-6 pt-5 pb-5">
            <Col lg="12">
              <div className="mb-4">
                <h5 className="fw-bold">{t("Creator")}</h5>
                <div className="border-top mt-3" />
              </div>
            </Col>
            <Col lg={{ span: 6 }} className="mt-3">
              <div className="d-flex align-self-center align-items-center">
                <div
                  style={{
                    height: "64px",
                    width: "64px",
                  }}
                >
                  <UserPill height="64px" width="64px" user={user} />
                </div>
                <div className="ms-4">
                  <h4 className="fw-bold" hidden={!user?.username}>
                    @{user?.username}
                  </h4>
                  <p className="fw-bold text-muted p-0 m-0">
                    {truncateAddress(user?.address)}
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={{ span: 5, offset: 1 }} className="mt-3">
              <h5>{user?.description}</h5>
              <SocialsBar
                twitter={user.twitter}
                facebook={user.facebook}
                instagram={user.instagram}
                className="me-5"
              />
            </Col>
          </Row>

          <Row>
            <SimpleFooter />
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

function SimpleFooter() {
  return (
    <div className="px-lg-6 py-4 bg-white border-top">
      <div className="d-flex align-self-center align-items-center">
        <div className="d-flex">
          <LogoGray height="40px" className="me-2" />
        </div>
        <div className="ms-auto">
          <SocialsBar
            // linkedin={"company/aztlanmarket"}
            facebook={"Aztlan-Market-106934338403309"} // TODO: CHANGE
            telegram={"aztlanmarket"}
            instagram={"aztlanmarket"}
            medium={"aztlanmarket"}
            twitter={"AztlanMarket"}
            discord={"B6XY3YDgdM"}
            reddit={"AztlanMarket"}
            className="ms-4"
          />
        </div>
      </div>
    </div>
  );
}

function FullView({ handle, id }) {
  const nft = useNFTState();

  if (!id) return null;

  return (
    <FullScreen handle={handle}>
      <div
        className="bg-white h-100 w-100 border d-flex aligns-items-center justify-content-center pointer-zoom-out"
        onClick={() => handle.exit()}
      >
        <div className={clsx(handle.active && "p-5")}>
          <Previewer
            className="shadow pointer-zoom-in h-100 img-fluid"
            fluid
            name={nft.name}
            nsfw={nft.nsfw}
            src={nft.IpfsUrl}
            path={nft.filetype}
            sha256={nft.sha256}
            isMistery={nft.isMistery}
            filetype={nft.filetype}
          />
        </div>
      </div>
    </FullScreen>
  );
}

export default function NFTDetail() {
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const handle = useFullScreenHandle();

  const id = params.get("nft");

  return (
    <NFTProvider id={id}>
      <BuyerModal />
      <BidModal />
      <FullView id={id} handle={handle} />
      <NFTDetailModal id={id} handle={handle} />
    </NFTProvider>
  );
}
