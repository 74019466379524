import Mapper from "components/Shared/Mapper";
import SocialsBar from "components/Shared/SocialsBar";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Diego from "assets/team/diego.jpg";
import Cristian from "assets/team/cristian.jpg";
import Arturo from "assets/team/arturo.jpg";

const developers = [
  {
    linkedin: "in/arturo-underwood",
    name: "Arturo Underwood",
    roles: ["Founder"],
    picture: Arturo,
  },
  {
    linkedin: "in/diego-velazquez-rabasa",
    name: "Diego Velazquez",
    roles: ["Co-founder", "Product Designer"],
    picture: Diego,
  },
  {
    linkedin: "in/cristian-palacios-beltran",
    name: "Cristian Palacios",
    roles: ["Co-founder", "Full Stack Engineer"],
    picture: Cristian,
  },
];

const DeveloperCard = ({ name, roles = [], picture, ...socials }) => {
  return (
    <Col lg={4} className="mb-4 mb-lg-0 user-select-none">
      <div className="card bg-white border-0 shadow-lg p-5">
        <div className="mb-5">
          <img
            src={picture}
            // alt={name}
            className="m-2 rounded-pill avatar avatar-64"
            style={{ objectFit: "cover" }}
          />
        </div>
        <p className="lead text-dark mb-0">{name}</p>
        <div>
          <span className="text-muted fw-light">{roles.join(" / ")}</span>
        </div>
        <div className="mt-3">
          <SocialsBar {...socials} />
        </div>
      </div>
    </Col>
  );
};

export default function About() {
  // Developers & roadmap
  const { t } = useTranslation();

  return (
    <section className="bg-white mt-5">
      <Container>
        <h3 className="mb-5">{t("Nuestro equipo")}</h3>
        <Row>
          <Mapper data={developers}>
            <DeveloperCard />
          </Mapper>
        </Row>
      </Container>
    </section>
  );
}
