import { Button } from "react-bootstrap";
import { isChrome, isChromium, isFirefox, isSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";

const selectLink = () => {
  if (isChrome || isChromium)
    return "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn";
  if (isFirefox)
    return "https://addons.mozilla.org/en-US/firefox/addon/ether-metamask";
  if (isSafari)
    return "https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202";
};

export default function InstallMetamaskModal() {
  const { t } = useTranslation();
  const handleOpen = () => {
    window.open(selectLink(), "_blank");
  };
  return (
    <div>
      <h5 className="mb-4">{t("Instala")} Metamask</h5>
      <div>
        {/* <img
        src="img/wallets/install_metamask.gif"
        alt=""
        className="rounded-3 my-3"
      /> */}
        <iframe
          width="100%"
          height="400px"
          src="https://www.youtube.com/embed/YVgfHZMFFFQ"
          title="METAMASK"
          frameBorder="0"
          className="rounded-3"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <small className="text-muted d-block my-3">
          {t("Refresca la pantalla después de instalar la extensión")}
        </small>
        <Button variant="primary" className="w-100" onClick={handleOpen}>
          {t("Descargar")}
        </Button>
      </div>
    </div>
  );
}
