import FolderIcon from "@heroicons/react/solid/FolderIcon";
import EyeIcon from "@heroicons/react/solid/LockClosedIcon";
import clsx from "clsx";
import { truncateAddress } from "utils";
import { maxHeight } from "components/Shared/Previewer";

const getSize = (size) => {
  switch (size) {
    case "sm":
      return 80;
    default:
    case "md":
      return 120;
  }
};

export default function FileTypePreview({
  filetype,
  size = "md",
  border,
  name,
  nsfw,
}) {
  return (
    <div
      className={clsx(
        "pointer bg-light rounded-3 d-flex justify-content-center text-muted text-uppercase p-3 w-100",
        border
      )}
      style={{ height: maxHeight }}
    >
      <div className="align-self-center text-center w-100">
        {nsfw ? (
          <EyeIcon height={getSize(size)} />
        ) : (
          <FolderIcon height={getSize(size)} />
        )}
        <div>
          {nsfw && <h4>NSFW</h4>}
          <small>{truncateAddress(name)}</small>
        </div>
        <h5 className="text-wrap w-100">{filetype}</h5>
      </div>
    </div>
  );
}
