import React, { useState } from "react";
import Pie from "@visx/shape/lib/shapes/Pie";
import { scaleOrdinal } from "@visx/scale";
import { Group } from "@visx/group";
import { LinearGradient } from "@visx/gradient";
import { animated, useTransition, interpolate } from "react-spring";
import { tokenomicsDistribution } from "constant";
import { useTranslation } from "react-i18next";

// data and types
const distributionsNames = tokenomicsDistribution.map((t) => t.label);
const distributions = tokenomicsDistribution;

// accessor functions
const usage = (d) => d.usage;

// color scales
const getdistributionsColor = scaleOrdinal({
  domain: distributionsNames,
  range: [
    "rgb(11, 109, 255, 0.9)",
    "rgb(11, 109, 255, 0.7)",
    "rgb(11, 109, 255, 0.5)",
    "rgb(11, 109, 255, 0.3)",
  ],
});

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };

export default function PieChart({
  width,
  height,
  margin = defaultMargin,
  animate = true,
}) {
  const [selecteddistributions, setSelecteddistributions] = useState(null);

  if (width < 10) return null;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 60;

  return (
    <svg width={width} height={height}>
      {/* <LinearGradient id="visx-pie-gradient" to="#282e36" from="#282e36" /> */}
      <rect
        rx={14}
        width={width}
        height={height}
        fill="url('#visx-pie-gradient')"
      />
      <Group top={centerY + margin.top} left={centerX + margin.left}>
        <Pie
          data={
            selecteddistributions
              ? distributions.filter(
                  ({ label }) => label === selecteddistributions
                )
              : distributions
          }
          pieValue={usage}
          outerRadius={radius}
          innerRadius={radius - donutThickness}
          cornerRadius={3}
          padAngle={0.005}
        >
          {(pie) => (
            <AnimatedPie
              {...pie}
              animate={animate}
              getValue={(arc) => arc.data.usage}
              getKey={(arc) => arc.data.label}
              onClickDatum={({ data: { label } }) =>
                animate &&
                setSelecteddistributions(
                  selecteddistributions && selecteddistributions === label
                    ? null
                    : label
                )
              }
              getColor={(arc) => getdistributionsColor(arc.data.label)}
            />
          )}
        </Pie>
      </Group>
    </svg>
  );
}

// react-spring transition definitions

const fromLeaveTransition = ({ endAngle }) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});
const enterUpdateTransition = ({ startAngle, endAngle }) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

function AnimatedPie({
  animate,
  arcs,
  path,
  getKey,
  getColor,
  onClickDatum,
  getValue,
}) {
  const { t } = useTranslation();
  const transitions = useTransition(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });
  return transitions((props, arc, { key }) => {
    const [centroidX, centroidY] = path.centroid(arc);
    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;

    return (
      <g key={key}>
        <animated.path
          className="pointer"
          // compute interpolated path d attribute from intermediate angle values
          d={interpolate(
            [props.startAngle, props.endAngle],
            (startAngle, endAngle) =>
              path({
                ...arc,
                startAngle,
                endAngle,
              })
          )}
          fill={getColor(arc)}
          onClick={() => onClickDatum(arc)}
          onTouchStart={() => onClickDatum(arc)}
        />
        {hasSpaceForLabel && (
          <animated.g style={{ opacity: props.opacity }}>
            <text
              fill="white"
              x={centroidX}
              y={centroidY}
              dy=".33em"
              fontSize={9}
              textAnchor="middle"
              pointerEvents="none"
            >
              {t(getKey(arc))}
            </text>
            <text
              fill="white"
              x={centroidX}
              y={centroidY + 10}
              dy=".33em"
              fontSize={9}
              textAnchor="middle"
              pointerEvents="none"
            >
              {getValue(arc)}%
            </text>
          </animated.g>
        )}
      </g>
    );
  });
}
