import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { fetcher, fetcherSigner } from "actions/Client";
import ERCController from "@aztlan/blockchain/artifacts/contracts/TokenController.sol/TokenController.json";
import { Contract, utils, BigNumber } from "ethers";
import { toast } from "components/Shared/Toast";
import {
  calculateSHA256,
  errorMapper,
  mapCurrencyProperty,
  toHex,
  zeroAddress,
} from "utils";
import { useTranslation } from "react-i18next";
import { useApp, useAppState } from "context/context";

function getERC20ControllerByAddress(address, wallet) {
  return new Contract(address, ERCController.abi, wallet);
}

function getERC20Controller(currency, wallet, chainId = 97) {
  const erc20Address = mapCurrencyProperty(chainId, currency, "address");
  if (!erc20Address) throw new Error("ContractNotDeployed");
  return new Contract(erc20Address, ERCController.abi, wallet);
}

export function useCryptoPrice({ currency, crypto, amount, chainId } = {}) {
  const { active } = useAppState();
  return useQuery(
    ["crypto", { currency, crypto, amount, chainId }, chainId],
    () =>
      fetcher({
        url: `/prices`,
        method: "POST",
        body: { currency, crypto, amount, chainId },
      }),
    { enabled: active && !!crypto && !!amount && !!chainId && !!currency }
  );
}

export function useQR(value, options) {
  return useQuery(
    ["qr", value],
    () =>
      fetcher({
        url: `/qr`,
        method: "POST",
        body: { value },
      }),
    options
  );
}

export function useRegisterToNewsletter(options) {
  return useMutation(
    (body) =>
      fetcher({
        url: "/newsletter",
        method: "POST",
        body,
      }),
    options
  );
}

export function useRegisterToAirdrop(options) {
  return useMutation(
    (body) =>
      fetcher({
        url: "/airdrop",
        method: "POST",
        body,
      }),
    options
  );
}

export function useMarkNotificationsAsRead(options) {
  return useMutation(
    (body) =>
      fetcher({
        url: "/notifications",
        method: "DELETE",
        body,
      }),
    options
  );
}

export function useReportNFT(options) {
  return useMutation(
    (body) =>
      fetcher({
        url: `/nft/${body.id}/report`,
        method: "POST",
        body,
      }),
    options
  );
}

export function useFetchLikesNFT({ _id, address } = {}, options) {
  return useQuery(
    ["likes", { _id, address }],
    () =>
      fetcher({
        url: `/nft/${_id}/likes?address=${address}`,
        method: "GET",
      }),
    { enabled: !!_id, ...options }
  );
}

export function useLikeNFT(options) {
  return useMutation(
    (body) =>
      fetcher({
        url: `/nft/${body._id}/likes`,
        method: "POST",
        body,
      }),
    options
  );
}

export function useVerifyProfile(options) {
  const { chainId, nftController, provider } = useAppState();
  return useMutation((body) => {
    const msgParams = {
      domain: {
        //**TORUS requires HEX**/
        chainId: chainId,
        name: "VerifyProfile",
        verifyingContract: nftController.address,
        version: "1",
      },
      message: { tweet: body.tweet },
      primaryType: "VerifyProfile",
      types: {
        EIP712Domain: [
          { name: "name", type: "string" },
          { name: "version", type: "string" },
          { name: "chainId", type: "uint256" },
          { name: "verifyingContract", type: "address" },
        ],
        VerifyProfile: [{ name: "tweet", type: "string" }],
      },
    };
    return fetcherSigner({
      url: `/profile/${body.address}/verify`,
      method: "POST",
      body: { msgParams, address: body.address },
      provider,
    });
  }, options);
}

export function useNotifications(address, options) {
  return useQuery(
    ["notifications", address],
    () =>
      fetcher({
        url: `/notifications/${address}`,
        method: "GET",
      }),
    { enabled: !!address, ...options }
  );
}

export function useGallery(
  { tags, name, page, owner, author, pastBidders, featured } = {},
  options
) {
  const { chainId } = useAppState();
  return useQuery(
    [
      "gallery",
      { tags, name, page, owner, author, pastBidders, chainId, featured },
    ],
    async () => {
      const datas = await fetcher({
        url: `/nft`,
        method: "POST",
        body: {
          tags,
          name,
          page,
          owner,
          author,
          pastBidders,
          chainId,
          featured,
        },
      });

      return datas;
    },
    options
  );
}

export function useInfinityGallery({ categoryTags, ...props } = {}, options) {
  const { chainId } = useAppState();
  return useInfiniteQuery(
    ["infinity-gallery", { ...props, chainId }],
    async ({ pageParam }) => {
      const datas = await fetcher({
        url: `/nft` + (pageParam ? `?page=${pageParam}` : ""),
        method: "POST",
        body: { ...props, chainId },
      });
      return datas;
    },
    options
  );
}

export const useERC20Decimals = (options) => {
  const { wallet } = useAppState();
  return useMutation(({ erc20Address }) => {
    const ercController = getERC20ControllerByAddress(erc20Address, wallet);
    return ercController.decimals();
  }, options);
};

export const useApproveERC20 = (options) => {
  const { wallet, nftController } = useAppState();
  return useMutation(async ({ erc20Address, amount }) => {
    const ercController = getERC20ControllerByAddress(erc20Address, wallet);
    const approve = await ercController.approve(nftController.address, amount);
    return approve.wait();
  }, options);
};

export const useERC20Allowance = (erc20Address, erc20Needed, enable) => {
  const { wallet, nftController, address, active } = useAppState();
  return useQuery(
    ["ERC20", erc20Address, address],
    async () => {
      const ercController = getERC20ControllerByAddress(erc20Address, wallet);
      const allowance = await ercController.allowance(
        address,
        nftController.address
      );
      const enough = erc20Needed && allowance.gte(BigNumber.from(erc20Needed));
      return { allowance: allowance.toString(), enough };
    },
    { enabled: !!erc20Address && active && enable }
  );
};

export const useGetUSDTBalance = () => {
  const { chainId, address, wallet, active } = useAppState();
  return useQuery(
    ["usdt-balance", address, chainId],
    async () => {
      const ercController = getERC20Controller("USDT", wallet, chainId);
      return {
        balance: utils.formatUnits(await ercController.balanceOf(address)),
      };
    },
    { enabled: active }
  );
};

export const useGetERC20Balance = () => {
  const { chainId, wallet, active, address } = useAppState();
  return useQuery(
    ["erc20-balance", { address, chainId }],
    async () => {
      const ercController = getERC20Controller("AZTLAN", wallet, chainId);
      return {
        balance: utils.formatUnits(await ercController.balanceOf(address)),
      };
    },
    { enabled: active }
  );
};

export const useGetRemainingBalance = ({ currency }) => {
  const { nftController, active, address } = useAppState();
  return useQuery(
    ["nftcurrencybalance", { address, currency }],
    async () => {
      const balance = await nftController.pendingReturns(address, currency);
      return { balance: balance.toString() };
    },
    { enabled: !!currency && active }
  );
};

export const useWithdrawBids = () => {
  const { nftController } = useAppState();
  return useMutation(async ({ currency }) => {
    const withdrawBids = await nftController.withdrawBids(currency);
    return withdrawBids.wait();
  });
};

export const useGetNativeBalance = () => {
  const { chainId, active, wallet, address } = useAppState();
  return useQuery(
    ["native-balance", { chainId, address }],
    async () => {
      return { balance: utils.formatUnits(await wallet.getBalance()) };
    },
    { enabled: active }
  );
};

export const useGetNFTInfo = ({ tokenId }, options = {}) => {
  const { nftController, active, chainId, address } = useAppState();
  return useQuery(
    ["nft-info", tokenId, chainId, address],
    async () => {
      const [sale, bid] = await nftController.currentStatus(tokenId);
      const owner = await nftController.ownerOf(tokenId);
      return {
        tokenId,
        owner: owner.toString().toLowerCase(),
        minted: true,
        sellingPrice: sale.sellingPrice.toString(),
        saleCurrency: sale.currency,
        bidCurrency: bid.currency,
        bidEnded: bid.bidEnded,
        biddingTime: bid.auctionEndTime.toString(),
        highestBid: bid.highestBid.toString(),
        highestBidder: bid.highestBidder,
        initialPrice: bid.initialPrice.toString(),
        bidSeller: bid.seller,
        bidOffers: bid.bidOffers.map((offer) => ({
          bidder: offer.bidder,
          amount: offer.offer.toString(),
          date: new Date(offer.date * 1000),
        })),
      };
    },
    { enabled: !!tokenId && active, ...options }
  );
};

export const useGetNFTSaleInfo = ({ tokenId }, options = {}) => {
  const { nftController, active } = useAppState();
  return useQuery(
    ["nft-sale-info", tokenId],
    async () => {
      const sales = await nftController.getSaleHistory(tokenId);
      return sales.map((sale) => ({
        buyer: sale.buyer,
        currency: sale.currency,
        length: sale.length,
        seller: sale.seller,
        sellingPrice: sale.sellingPrice.toString(),
      }));
    },
    { enabled: !!tokenId && active, refetchOnWindowFocus: false, ...options }
  );
};

export const useGetNFTBidInfo = ({ tokenId }, options = {}) => {
  const { nftController, active } = useAppState();
  return useQuery(
    ["nft-bid-info", tokenId],
    async () => {
      const bids = await nftController.getBidHistory(tokenId);
      return bids.map((bid) => ({
        // buyer: sale.buyer,
        // currency: sale.currency,
        // length: sale.length,
        // seller: sale.seller,
        // sellingPrice: sale.sellingPrice.toString(),
      }));
    },
    { enabled: !!tokenId && active, refetchOnWindowFocus: false, ...options }
  );
};

export const useCreateArtWork = (options) => {
  const { t } = useTranslation();
  const { nftController } = useAppState();
  return useMutation(
    async ({ sha256 }) => {
      const buyTicket = await nftController.createArtWork(sha256);
      return buyTicket.wait();
    },
    {
      ...options,
      onError: (err) => toast.error(t(errorMapper(err))),
    }
  );
};

export const usePutToSale = (options) => {
  const { t } = useTranslation();
  const { nftController } = useAppState();
  return useMutation(
    async ({ tokenId, sellingPrice, currency }) => {
      const tx = await nftController.putToSale(tokenId, sellingPrice, currency);
      await tx.wait();
    },
    {
      ...options,
      onError: (err) => toast.error(t(errorMapper(err))),
    }
  );
};

export const usePutToAuction = (options) => {
  const { t } = useTranslation();
  const { nftController } = useAppState();
  return useMutation(
    async ({ tokenId, initialPrice, currency, biddingTime }) => {
      const tx = await nftController.putToAuction(
        tokenId,
        initialPrice,
        currency,
        biddingTime
      );
      await tx.wait();
    },
    {
      ...options,
      onError: (err) => toast.error(t(errorMapper(err))),
    }
  );
};

export const useAuctionEnd = (options) => {
  const { t } = useTranslation();
  const { nftController } = useAppState();
  return useMutation(
    async ({ tokenId }) => {
      const tx = await nftController.endAuction(tokenId);
      await tx.wait();
    },
    {
      ...options,
      onError: (err) => toast.error(t(errorMapper(err))),
    }
  );
};

export const useBidNFT = (options = {}) => {
  const { t } = useTranslation();
  const { nftController } = useAppState();
  return useMutation(
    async ({ tokenId, offer, value }) => {
      const tx = await nftController.bidNFT(tokenId, offer, { value });
      await tx.wait();
    },
    {
      options,
      // ...options, onError: (err) => toast.error(t(errorMapper(err)))
    }
  );
};

export const useBuyNFT = (options = {}) => {
  const { t } = useTranslation();
  const { nftController } = useAppState();
  return useMutation(
    async ({ tokenId, value }) => {
      const tx = await nftController.buyNFT(tokenId, { value });
      await tx.wait();
    },
    {
      ...options,
      //  onError: (err) => toast.error(t(errorMapper(err)))
    }
  );
};

export const useBuyLazyNFT = (options = {}) => {
  const { t } = useTranslation();
  const { nftController } = useAppState();
  return useMutation(
    async ({ sha256, minPrice, currency, creator, signature }) => {
      const tx = await nftController.redeem(
        sha256,
        minPrice,
        currency,
        utils.getAddress(creator),
        signature,

        {
          value: currency === zeroAddress ? minPrice : 0,
        }
      );
      await tx.wait();
    },
    {
      ...options,
      //  onError: (err) => toast.error(t(errorMapper(err)))
    }
  );
};

export const useApprove = (options) => {
  // const { t } = useTranslation()
  const { nftController } = useAppState();
  return useMutation(async ({ tokenId }) => {
    const tx = await nftController.approve(nftController.address, tokenId);
    await tx.wait();
  }, options);
};

export const useSetApprovalForAll = (options) => {
  // const { t } = useTranslation()
  const { nftController } = useAppState();
  return useMutation(async () => {
    const tx = await nftController.setApprovalForAll(
      nftController.address,
      true
    );
    await tx.wait();
  }, options);
};

export const useIsApprovedForAll = (options) => {
  // const { t } = useTranslation()
  const { nftController, address } = useAppState();
  return useMutation(async () => {
    const tx = await nftController.isApprovedForAll(
      address,
      nftController.address
    );
    return tx;
  }, options);
};

export const useCancelSale = (options) => {
  const { t } = useTranslation();
  const { nftController } = useAppState();
  return useMutation(
    async ({ tokenId }) => {
      const tx = await nftController.cancelSale(tokenId);
      await tx.wait();
    },
    {
      ...options,
      onError: (err) => toast.error(t(errorMapper(err))),
    }
  );
};

export const useGetArtWork = (id, options) => {
  const { chainId } = useAppState();
  return useQuery(
    ["artwork", id, chainId],
    async () => {
      return fetcher({
        url: `/nft/${id}?chainId=${chainId}`,
        method: "GET",
      });
    },
    options
  );
};

export const useMutateProfile = (options) => {
  const { t } = useTranslation();
  const { provider } = useAppState();
  return useMutation(
    (body) =>
      fetcherSigner({
        url: `/profile`,
        method: "PUT",
        body,
        provider,
      }),
    {
      ...options,
      onError: (err) => toast.error(t(errorMapper(err))),
    }
  );
};

export const useGetArtists = ({ props } = {}, options) => {
  return useInfiniteQuery(
    ["infinity-artists", { ...props }],
    async ({ pageParam }) => {
      return fetcher({
        url: `/artists` + (pageParam ? `?page=${pageParam}` : ""),
        method: "POST",
        body: props,
      });
    },
    options
  );
};

export const useGetProfile = (address, options) => {
  return useQuery(
    ["profile", address],
    () =>
      fetcher({
        url: `/profile/${address}`,
        method: "GET",
      }),
    { enabled: !!address, ...options }
  );
};

export const useProfileImage = (address, options) => {
  return useQuery(
    ["profile-image", address],
    () =>
      fetcher({
        url: `/profile/${address}/image`,
        method: "GET",
      }),
    options
  );
};

export const useGetBlog = (options) => {
  return useQuery(
    ["blog"],
    () =>
      fetcher({
        url: `/blog`,
        method: "GET",
      }),
    options
  );
};

export const useGetBlogEntry = (entryId, options) => {
  return useQuery(
    ["blog-entry", entryId],
    () =>
      fetcher({
        url: `/blog/${entryId}`,
        method: "GET",
      }),
    options
  );
};

export const useFetchStats = (options) =>
  useQuery(
    ["stats"],
    () =>
      fetcher({
        url: `/stats`,
        method: "GET",
      }),
    options
  );

export const useUpload = (options = {}) => {
  const { t } = useTranslation();
  const { provider, address, nftController, chainId, connector } =
    useAppState();
  return useMutation(
    async ({ files, action, metadata }) => {
      const file = files[0];

      const msgParams = {
        domain: {
          //**TORUS requires HEX**/
          chainId: chainId,
          name: "FileUpload",
          verifyingContract: nftController.address,
          version: "1",
        },
        message: { fileUpload: file.path || file.name, action, metadata },
        primaryType: "FileUpload",
        types: {
          EIP712Domain: [
            { name: "name", type: "string" },
            { name: "version", type: "string" },
            { name: "chainId", type: "uint256" },
            { name: "verifyingContract", type: "address" },
          ],
          FileUpload: [
            { name: "fileUpload", type: "string" },
            { name: "action", type: "string" },
          ],
        },
      };

      const uploadAuth = await fetcherSigner({
        url: `/uploadAuth`,
        method: "POST",
        body: { msgParams, address },
        provider,
      });

      const formData = new FormData();

      formData.append(uploadAuth.uuid, file);

      return fetcher({
        url: `/upload`,
        method: "POST",
        body: formData,
      });
    },
    { ...options, onError: (err) => toast.error(t(errorMapper(err))) }
  );
};

export const useUploadHash = (options = {}) => {
  const { t } = useTranslation();
  const { provider, chainId, address, nftController, connector } =
    useAppState();
  return useMutation(
    async ({ files, ...message }) => {
      const file = files[0];

      const sha256 = await calculateSHA256(file);
      const msgParams = {
        domain: {
          //**TORUS requires HEX**/
          chainId: chainId,
          name: "HashUpload",
          verifyingContract: nftController.address,
          version: "1",
        },
        message: { ...message, sha256, filetype: file.type },
        primaryType: "HashUpload",
        types: {
          EIP712Domain: [
            { name: "name", type: "string" },
            { name: "version", type: "string" },
            { name: "chainId", type: "uint256" },
            { name: "verifyingContract", type: "address" },
          ],
          HashUpload: [{ name: "sha256", type: "string" }],
        },
      };

      return fetcherSigner({
        url: `/uploadHash`,
        method: "POST",
        body: { msgParams, address },
        provider,
      });
    },
    { ...options, onError: (err) => toast.error(t(errorMapper(err))) }
  );
};

export const useDeleteVoucher = (options = {}) => {
  const { t } = useTranslation();
  const { provider, chainId, address, nftController, connector } =
    useAppState();
  return useMutation(
    async ({ _id }) => {
      const msgParams = {
        domain: {
          //**TORUS requires HEX**/
          chainId: chainId,
          name: "DeleteVoucher",
          verifyingContract: nftController.address,
          version: "1",
        },
        message: { _id },
        primaryType: "DeleteVoucher",
        types: {
          EIP712Domain: [
            { name: "name", type: "string" },
            { name: "version", type: "string" },
            { name: "chainId", type: "uint256" },
            { name: "verifyingContract", type: "address" },
          ],
          DeleteVoucher: [{ name: "_id", type: "string" }],
        },
      };

      return fetcherSigner({
        url: `/voucher`,
        method: "DELETE",
        body: { msgParams, address },
        provider,
      });
    },
    { ...options, onError: (err) => toast.error(t(errorMapper(err))) }
  );
};

export const useCreateLazyArtWork = (options = {}) => {
  const { t } = useTranslation();
  const { provider, chainId, address, nftController, connector } =
    useAppState();
  return useMutation(
    async ({ sha256, minPrice, currency }) => {
      const msgParams = {
        domain: {
          name: "AztlanNFT-Voucher",
          version: "4",
          chainId: parseInt(chainId, 10), //chainId,
          verifyingContract: utils.getAddress(nftController.address),
        },
        message: {
          sha256,
          currency,
          minPrice,
          creator: utils.getAddress(address),
        },
        primaryType: "NFTVoucher",
        types: {
          EIP712Domain: [
            { name: "name", type: "string" },
            { name: "version", type: "string" },
            { name: "chainId", type: "uint256" },
            { name: "verifyingContract", type: "address" },
          ],
          NFTVoucher: [
            { name: "sha256", type: "string" },
            { name: "minPrice", type: "uint256" },
            { name: "currency", type: "address" },
            { name: "creator", type: "address" },
          ],
        },
      };

      // const body = await new Promise((resolve, reject) =>
      //   provider.send(
      //     {
      //       method: "eth_signTypedData_v4",
      //       params: [address, JSON.stringify(msgParams)],
      //       from: address,
      //     },
      //     (err, result) => {
      //       if (err) reject(err);
      //       console.log(result);
      //       resolve({ ...result, message: msgParams, address, msgParams });
      //     }
      //   )
      // );
      // return fetcher({ url: "/voucher", body, method: "POST" });
      return fetcherSigner({
        url: `/voucher`,
        method: "POST",
        body: { msgParams, address: utils.getAddress(address) },
        provider,
      });
    },
    { ...options, onError: (err) => toast.error(t(errorMapper(err))) }
  );
};
