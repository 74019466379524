import { toast } from "components/Shared/Toast";
import { sign } from "utils";

function isAbortError(error) {
  if (error && error.name === "AbortError") {
    return true;
  }
  return false;
}

export const fetcherSigner = async (props) => {
  try {
    const signed = await sign(props.body, props.provider);
    return fetcher({ ...props, body: signed });
  } catch (err) {
    // toast.error(err.message)
    throw err;
  }
};

export const fetcher = async ({ url, body, method = "GET", headers = {} }) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const promise = new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}${url}`,
        {
          body: body instanceof FormData ? body : JSON.stringify(body),
          method,
          signal,
          headers:
            body instanceof FormData
              ? {}
              : {
                  "Content-Type": "application/json",
                  ...headers,
                },
        }
      );
      if (!response.ok) {
        reject(new Error("Problem fetching data"));
      }
      const data = await response.json();
      resolve(data);
    } catch (ex) {
      if (isAbortError(ex)) {
        reject(new Error("Request cancelled"));
      }
    }
  });
  promise.cancel = () => {
    controller.abort();
  };
  return promise;
};
