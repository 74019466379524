import { networks } from "@aztlan/config";
import { es, enUS } from "date-fns/locale";
import { utils } from "ethers";

export const zeroAddress = "0x0000000000000000000000000000000000000000";

export const toHex = (value) => `0x${value.toString(16)}`;

export const buildQueryString = (params) =>
  Object.keys(params)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    })
    .join("&");

export const calculateSHA256 = (file) =>
  new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onloadend = async function () {
        const hashBuffer = await crypto.subtle.digest("SHA-256", reader.result);
        const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
        const hashHex = hashArray
          .map((b) => b.toString(16).padStart(2, "0"))
          .join(""); // convert bytes to hex string
        resolve(hashHex);
      };
      reader.onerror = (err) => {
        reject(err?.message || err);
      };
      reader.onabort = () => {
        reject("Aborted");
      };
      reader.readAsArrayBuffer(file);
    } catch (err) {
      reject(err?.message || err);
    }
  });

export function truncateAddress(address = "", length = 8) {
  if (!address) return "";
  return `${address.substring(0, length)}...${address.substring(
    address.length - 5
  )}`;
}

export function sign({ msgParams, address, message }, provider) {
  const params = [address, JSON.stringify(msgParams)];
  const method = "eth_signTypedData_v4";
  return new Promise((resolve, reject) =>
    provider.send(method).catch((a) => {
      const notSupported = String(a).includes(
        "Method eth_signTypedData_v4 is not supported"
      );
      provider.send(
        {
          method: notSupported ? "eth_sign" : method,
          params,
          from: address,
        },
        (err, result) => {
          if (err) return reject(err);
          if (result.error) {
            reject(result.error.message);
          }
          if (result.error) return reject(result);
          resolve({ ...result, message, address, msgParams });
        }
      );
    })
  );
}

export function isSameAddress(address, currentAddress) {
  return String(address).toLowerCase() === String(currentAddress).toLowerCase();
}

export const mapCurrency = (chainId, address) =>
  networks[Number(chainId)]?.BEP20S.find(
    (b) => String(address).toLowerCase() === String(b.address).toLowerCase()
  )?.symbol || address;

export const mapCurrencyProperty = (chainId, symbol, property) =>
  networks[Number(chainId)]?.BEP20S.find(
    (b) => String(symbol).toLowerCase() === String(b.symbol).toLowerCase()
  )?.[property];

export const mapCurrencyPropertyByAddress = (chainId, address, property) =>
  networks[Number(chainId)]?.BEP20S.find(
    (b) => String(address).toLowerCase() === String(b.address).toLowerCase()
  )?.[property];

export function getLocale(language) {
  switch (language) {
    case "en":
      return enUS;
    case "es":
    default:
      return es;
  }
}

export function removeSpecialCharacters(value = "") {
  return value.replace(/^[a-z0-9]+$/i);
}

export function getRandom(max = 1000000, min = 10000) {
  return String(Math.floor(Math.random() * (max - min + 1)) + min);
}

export function formatCrypto(value, fixed = 2) {
  return Number(utils.formatUnits(String(value)))
    .toFixed(fixed)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    .split(".")[0];
}

export function errorMapper(err) {
  const errmessage = err?.data?.message ?? err?.message ?? err;
  switch (true) {
    case /Not enough ERC20 allowed/.test(errmessage):
      return "errors.not_enough_allowed";
    case /Not enough money/.test(errmessage):
      return "errors.not_enough_funds";
    case /User denied message signature/.test(errmessage):
      return "errors.signature_rejected";
    case /Auction not yet ended/.test(errmessage):
      return "errors.action_not_ended";
    case /Auction already ended'/.test(errmessage):
      return "errors.auction_ended";
    case /Bid too low/.test(errmessage):
      return "errors.bid_too_low";
    case /transfer caller is not owner nor/.test(errmessage):
      return "errors.not_owner";
    case /Price must be higher than 0/.test(errmessage):
      return "errors.price_to_low";
    default:
      return errmessage;
  }
}

export const noop = () => {};

export const emailRule = /\S+@\S+\.\S+/;

export const isUndefined = (val) => typeof val === "undefined";
