import { useForm } from "react-hook-form";
import {
  useGallery,
  useGetProfile,
  useMutateProfile,
  useUpload,
} from "actions/Api";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  FormGroup,
  ProgressBar,
  Tabs,
  Tab,
  Row,
  Spinner,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  CLOSE_EDIT_PROFILE,
  SET_MODAL,
  TOGGLE_EDIT_PROFILE,
  useApp,
  useAppDispatch,
} from "context/context";
import DropZone from "components/Shared/DropZone";
import { toast } from "components/Shared/Toast";
import RippleButton from "components/Shared/RippleButton";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { isSameAddress, truncateAddress } from "utils";
import SocialsBar from "components/Shared/SocialsBar";
import Avatar from "components/Shared/Avatar";
import { FetcherComponent } from "components/Shared/Fetcher";
import GalleryImage from "components/Shared/GalleryImage";
import useFileUpload from "hooks/useFileUpload";

import Verified from "assets/icon-verified.svg";
import { modals } from "constant";
import clsx from "clsx";

export const profileInputs = [
  { inputName: "username", col: 6, maxLength: 25, watch: true },
  { inputName: "email", col: 6, watch: true },
  { inputName: "description", col: 12, inputType: "textarea", watch: true },
  { inputName: "twitter", col: 6, watch: true },
  { inputName: "facebook", col: 6, watch: true },
  { inputName: "instagram", col: 6, watch: true },
  { inputName: "website", col: 6 },
  { inputName: "other", col: 6, className: "text-capitalize" },
  { inputName: "profileProgress", inputType: "progress", col: 6 },
];

function Edit(props) {
  const {
    // errors,
    isDisabled,
    register,
    handleSubmit,
    onSubmit,
    toggleEditing,
    editing,
    getValues,
  } = props;

  const { t } = useTranslation();

  const toWatch = profileInputs.filter((n) => n.watch);

  const withValues = toWatch.filter((n) => getValues(n.inputName));

  const filled = (withValues.length / toWatch.length) * 100;

  const now = filled >= 100 ? 100 : Math.round(filled);

  if (!editing) return null;

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <h3 className="mb-4">{t("Edita tu perfil")}</h3>
      <Row className="mb-4">
        {profileInputs.map((data, key) => (
          <Col lg={data.col} key={key}>
            <FormGroup key={data.inputName}>
              <Form.Label
                htmlFor={data.inputName}
                className={clsx("fw-bold text-dark", data.className)}
              >
                {t(data.inputName)}
              </Form.Label>
              {data.inputType === "progress" ? (
                <ProgressBar now={now} label={`${now}%`} className="mt-2" />
              ) : (
                <Form.Control
                  as={data.inputType ? data.inputType : "input"}
                  className="form-control-lg mb-3"
                  disabled={isDisabled}
                  placeholder={t(`${data.inputName}_placeholder`)}
                  maxLength={data.maxLength || 150}
                  {...register(data.inputName)}
                />
              )}
            </FormGroup>
          </Col>
        ))}
      </Row>
      <div>
        <Button
          onClick={toggleEditing}
          disabled={isDisabled}
          variant="secondary"
          className="me-3"
        >
          {t("Cancelar")}
        </Button>
        <Button type="submit" disabled={isDisabled}>
          {t("Actualizar perfil")}
        </Button>
      </div>
    </Form>
  );
}

function EditButton({ isOwner, toggleEditing, editing, uploadBanner }) {
  const { t } = useTranslation();
  if (!isOwner || editing) return null;
  return (
    <>
      <Button
        variant="outline-dark"
        onClick={uploadBanner}
        className="me-3 btn-sm"
      >
        {t("Cambiar portada")}
      </Button>
      <Button variant="outline-dark" onClick={toggleEditing} className="btn-sm">
        {t("Editar perfil")}
      </Button>
    </>
  );
}

// function View(props) {
//   const { data: { username, description } = {}, editing } = props;

//   if (editing) return null;

//   return (
//     <Form>
//       <FormGroup>
//         <Form.Label>{username}</Form.Label>
//         <Form.Label>{description}</Form.Label>
//       </FormGroup>
//     </Form>
//   );
// }

export function ProfileCard(props) {
  const {
    data: {
      address,
      verified,
      username,
      description,
      imageUrl,
      ...socialNetworks
    } = {},
    editing,
    handleUpload,
    isUploading,
    watchedUsername,
    watchedDescription,
    handleChangeImage,
    editImage,
  } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const handleVerify = () => {
    dispatch({ type: SET_MODAL, modal: modals.VERIFY, modalSize: "lg" });
  };

  const handleCopyAddress = (address) => {
    navigator.clipboard.writeText(address);
    toast.info(t("Copiado al portapapeles"));
  };

  return (
    <div className="bg-white p-4 rounded-3 text-center border shadow-sm mb-5 mb-lg-0">
      <Avatar
        image={imageUrl}
        address={address}
        size="128"
        verified={verified}
        className="mb-3"
        onClick={handleChangeImage}
      />
      {editImage && (
        <DropZone
          text={
            isUploading
              ? `${t("Cargando")}...`
              : t("Arrastra o selecciona tu foto de perfil")
          }
          onDrop={handleUpload}
          className="mb-4 border-dashed-muted"
        />
      )}
      <div>
        <div className="fw-bold text-dark d-flex align-self-center align-items-center justify-content-center">
          <span className="text-truncate mb-0">
            {editing ? watchedUsername : username}
          </span>
          <div className="ms-1">
            <img
              src={Verified}
              alt=""
              className="img-fluid"
              hidden={!verified}
            />
          </div>
        </div>
      </div>
      <div>
        <RippleButton
          size="sm"
          variant="light"
          className="text-muted mb-0 mt-3 bg-light py-1 px-3"
          onClick={() => handleCopyAddress(address)}
        >
          <small className="p-0">{truncateAddress(address)}</small>
        </RippleButton>
      </div>
      <div className="mt-3">
        <Form.Label className="text-muted mb-0">
          <small>{editing ? watchedDescription : description}</small>
        </Form.Label>
      </div>
      <div className="mt-3">
        <SocialsBar {...socialNetworks} className="mx-2" />
      </div>
      {/* <div className="mt-3" hidden={!isOwner}>
        <Button size="sm" variant="outline-primary" onClick={handleVerify}>
          {t("Verify")}
          <img src={Verified} alt="" height="16px" className="ms-1" />
        </Button>
      </div> */}
    </div>
  );
}

function ProfileView({ galleryAsOwner, ...props }) {
  return (
    <Row>
      <Col lg="3">
        <ProfileCard {...props} />
      </Col>
      <Col lg={{ span: 9 }}>
        <Edit {...props} />
        {galleryAsOwner}
      </Col>
    </Row>
  );
}

function Banner(props) {
  return (
    <div
      className="w-100 bg-light"
      style={{
        height: "300px",
        backgroundPosition: "center",
        backgroundImage: `url(${props.data.bannerUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Container>
        <div
          className="d-flex align-items-end flex-column"
          style={{ height: "300px" }}
        >
          <div className="mt-auto pb-3">
            <EditButton {...props} />
          </div>
        </div>
      </Container>
    </div>
  );
}

const NoNFTS = ({ t }) => (
  <div className="text-center p-6">
    <p className="text-muted">{t("No hay elementos que mostrar")}</p>
  </div>
);

const TabsNFT = ({
  tab,
  setTab,
  t,
  queryAsAuthorGallery,
  queryAsOwnerGallery,
  queryAsAuction,
}) => (
  <Tabs
    id="controlled-tab-profile"
    activeKey={tab}
    onSelect={(t) => setTab(t)}
    className="mb-4 nav-fill"
  >
    <Tab eventKey="author" title={t("author")}>
      <Row>
        <FetcherComponent
          query={queryAsAuthorGallery}
          emptyComponent={<NoNFTS t={t} />}
        >
          <GalleryImage
            md={4}
            lg={4}
            sm={12}
            xs={12}
            fixedheight="true"
            className="mb-2"
          />
        </FetcherComponent>
      </Row>
    </Tab>
    <Tab eventKey="owner" title={t("owner")}>
      <Row>
        <FetcherComponent
          query={queryAsOwnerGallery}
          emptyComponent={<NoNFTS t={t} />}
        >
          <GalleryImage
            md={4}
            lg={4}
            sm={12}
            xs={12}
            fixedheight="true"
            className="mb-2"
          />
        </FetcherComponent>
      </Row>
    </Tab>
    <Tab eventKey="auction" title={t("auction")}>
      <Row>
        <FetcherComponent
          query={queryAsAuction}
          emptyComponent={<NoNFTS t={t} />}
        >
          <GalleryImage
            md={4}
            lg={4}
            sm={12}
            xs={12}
            fixedheight="true"
            className="mb-2"
          />
        </FetcherComponent>
      </Row>
    </Tab>
  </Tabs>
);

export default function Profile() {
  const [tab, setTab] = useState("author");

  const { t } = useTranslation();

  const { address: navigationAddress } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm();

  const { mutate, isLoading: isMutating } = useMutateProfile({
    onSuccess: () => {
      toast.success(t("executed_success"));
      toggleEditing();
      refetchProfile();
    },
  });

  const [
    { address, nftController, chainId, connector, editingProfile },
    dispatch,
  ] = useApp();

  const {
    data: profile = {},
    isLoading,
    refetch: refetchProfile,
  } = useGetProfile(navigationAddress, {
    enabled: !!navigationAddress,
    refetchOnWindowFocus: !editingProfile,
    onSuccess: (values) => {
      if (!editingProfile) {
        profileInputs.forEach((k) =>
          setValue(k.inputName, values[k.inputName])
        );
      }
    },
  });

  const { mutate: upload, isLoading: isUploading } = useUpload();

  const onSubmit = (message) => {
    const msgParams = {
      domain: {
        //**TORUS requires HEX**/
        chainId: chainId, //connector ? parseInt(chainId, 16) : chainId,
        name: "Profile",
        verifyingContract: nftController.address,
        version: "1",
      },
      message,
      primaryType: "Profile",
      types: {
        EIP712Domain: [
          { name: "name", type: "string" },
          { name: "version", type: "string" },
          { name: "chainId", type: "uint256" },
          { name: "verifyingContract", type: "address" },
        ],
        Profile: profileInputs
          .filter((p) => p.inputType !== "progress")
          .map((name) => ({
            name: name.inputName,
            type: "string",
          })),
      },
    };
    mutate({ msgParams, address });
  };

  const handleUpload = (files) => {
    upload(
      {
        files,
        action: "profile-image",
      },
      {
        onSuccess: () => {
          toast.success(t("executed_success"));
          refetchProfile();
          setEditImage(false);
        },
      }
    );
  };

  const { chooseFileAndUploadFile } = useFileUpload();

  const uploadBanner = () => {
    chooseFileAndUploadFile(
      {
        action: "profile-banner",
      },
      {
        accept: "image/*",
        onSuccess: (data, variables) => {
          toast.success(t("executed_success"));
          refetchProfile();
        },
      }
    );
  };

  const toggleEditing = () => {
    dispatch({ type: TOGGLE_EDIT_PROFILE });
  };

  const queryAsOwnerGallery = useGallery(
    { owner: navigationAddress },
    { enabled: !!navigationAddress && tab === "owner" }
  );

  const queryAsAuthorGallery = useGallery(
    { author: navigationAddress },
    { enabled: !!navigationAddress && tab === "author" }
  );

  const queryAsAuction = useGallery(
    { pastBidders: navigationAddress },
    { enabled: !!navigationAddress && tab === "auction" }
  );

  const watchedDescription = watch("description");
  const watchedUsername = watch("username");
  watch("email");
  watch("twitter");
  watch("facebook");
  watch("instagram");

  const isOwner = isSameAddress(navigationAddress, address);

  const [editImage, setEditImage] = useState(false);

  const handleChangeImage = () => {
    if (isOwner) {
      setEditImage(!editImage);
      // dispatch({ type: CLOSE_EDIT_PROFILE });
    }
  };

  useEffect(() => {
    if (!isOwner) {
      setEditImage(false);
    }
  }, [isOwner]);

  return (
    <div>
      <Banner
        data={profile}
        uploadBanner={uploadBanner}
        toggleEditing={toggleEditing}
        isOwner={isOwner}
        editing={editingProfile}
      />
      <section className="bg-white">
        <Container>
          <ProfileView
            toggleEditing={toggleEditing}
            editing={editingProfile}
            data={profile}
            isOwner={isOwner}
            errors={errors}
            isDisabled={isLoading || isMutating}
            register={register}
            handleUpload={handleUpload}
            isUploading={isUploading}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            getValues={getValues}
            watchedUsername={watchedUsername}
            watchedDescription={watchedDescription}
            handleChangeImage={handleChangeImage}
            editImage={editImage}
            galleryAsOwner={
              editingProfile || (
                <TabsNFT
                  tab={tab}
                  setTab={setTab}
                  t={t}
                  queryAsAuthorGallery={queryAsAuthorGallery}
                  queryAsOwnerGallery={queryAsOwnerGallery}
                  queryAsAuction={queryAsAuction}
                />
              )
            }
          />
        </Container>
      </section>
    </div>
  );
}
