import { truncateAddress } from "utils";
import Avatar from "components/Shared/Avatar";
import { TOGGLE_RIGHT_BAR, useAppDispatch } from "context/context";
import clsx from "clsx";
import { useGetProfile } from "actions/Api";
import PropTypes from "prop-types";
import Verified from "assets/icon-verified.svg";
import { useNavigate } from "react-router";
export default function ProfileViewer({
  address,
  description,
  classLayoutProfile,
  paddingXLayoutProfile,
  hidden,
}) {
  const navigate = useNavigate()
  const handleOpenProfile = () => {
    navigate(`/perfil/${address}`)
    // dispatch({ type: TOGGLE_RIGHT_BAR, rightBarProps: { address } });
  };

  const { data = {} } = useGetProfile(address);

  if (!address || hidden) return null;

  return (
    <div
      className={clsx(
        classLayoutProfile,
        "d-flex pointer rounded-3 py-2 border me-3",
        paddingXLayoutProfile || "px-3"
      )}
      onClick={handleOpenProfile}
    >
      <div className="flex-shrink-0">
        <Avatar
          image={data.imageUrl}
          address={address}
          className="bg-dark"
          size="40"
          verified={data.verified}
        />
      </div>
      <div className="flex-grow-1 ms-2 align-self-center">
        <div className="text-dark fw-bold mb-0 d-flex align-self-center align-items-center">
          <span className="text-truncate mb-0">
            {data.username || truncateAddress(address)}
          </span>
          <div className="ms-1">
            <img
              src={Verified}
              alt=""
              className="img-fluid"
              hidden={!data.verified}
            />
          </div>
        </div>
        <small className="text-muted text-break">
          {description || data.description}
        </small>
      </div>
    </div>
  );
}

ProfileViewer.propTypes = {
  address: PropTypes.string,
  description: PropTypes.string,
  classLayoutProfile: PropTypes.string,
  paddingXLayoutProfile: PropTypes.string,
  hidden: PropTypes.bool,
};
