import FolderIcon from "@heroicons/react/solid/FolderIcon";
import LockClosedIcon from "@heroicons/react/solid/LockClosedIcon";
import GiftIcon from "@heroicons/react/solid/GiftIcon";
import { truncateAddress } from "utils";
import clsx from "clsx";

const DEFAULT = () => <div></div>;

const Mistery = ({ sha256, filetype }) => (
  <div className="mt-5 mx-lg-6 mx-xs-2 rounded border p-5 shadow-sm">
    <div>
      <GiftIcon height={"200px"} />
    </div>
    <div>
      <h3>Mistery Box</h3>
      <h4>{truncateAddress(sha256)}</h4>
      <h5>{filetype}</h5>
    </div>
  </div>
);

const NSFW = ({ filetype, sha256 }) => (
  <div className="mt-5 mx-lg-6 mx-xs-2 rounded border p-5 shadow-sm">
    <div>
      <LockClosedIcon height={"200px"} />
    </div>
    <div>
      <h3>NSFW</h3>
      <h4>{truncateAddress(sha256)}</h4>
      <h5>{filetype}</h5>
    </div>
  </div>
);

const HASH = ({ sha256, filetype }) => (
  <div className="mt-5 mx-lg-6 mx-xs-2 rounded border p-5 shadow-sm">
    <div>
      <FolderIcon height={"200px"} />
    </div>
    <div>
      <h3>Hash</h3>
      <h4>{truncateAddress(sha256)}</h4>
      <h5>{filetype}</h5>
    </div>
  </div>
);

const Loading = (props) => (
  <div style={{ height: props?.style?.maxHeight }} className="loading" />
);

export default function Previewer({ path, fluid, ...rest }) {
  if (!rest.name) return <Loading {...rest} />;
  // if (!rest.isMistery)
  // return <Mistery {...rest} />;
  if (rest.nsfw) return <NSFW {...rest} />;
  if (!rest.src) return <HASH {...rest} />;
  switch (path.split(".").pop().split("/").pop().toLowerCase()) {
    case "mp3":
      return <audio controls autoPlay loop {...rest} />;
    case "webm":
    case "mp4":
      return <video controls autoPlay loop {...rest} />;
    case "png":
    case "jpg":
    case "jpeg":
    case "webp":
    case "gif":
      return (
        <div
          height={rest?.style?.height}
          className={clsx(fluid && "img-fluid h-100")}
        >
          <img alt="" {...rest} />
        </div>
      );
    default:
      return <DEFAULT {...rest} />;
  }
}
