import { cloneElement } from "react";

export default function Mapper({ children, data = [] }) {
  return data.map((innerData, index) =>
    cloneElement(children, {
      key: `${"cloned"}-${index}`,
      index,
      length: data.length,
      ...innerData,
    })
  );
}
