import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import ArrowRightIcon from "@heroicons/react/outline/ArrowRightIcon";
import SocialsBar from "components/Shared/SocialsBar";
import createPersistedState from "use-persisted-state";
import { version } from "../../../package.json";
import { SET_MODAL, useApp } from "context/context";
import { modals } from "constant";
import { useRegisterToNewsletter } from "actions/Api";
import { ReactComponent as LogoBlack } from "assets/logos/LogoBlack.svg";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { emailRule } from "utils";

const useCurrencyState = createPersistedState("currency");

const FooterLink = (props) => {
  const { pathname } = useLocation();
  return (
    <Link
      className={clsx(
        "text-decoration-none",
        props.to === pathname ? "text-dark pe-none" : "text-muted"
      )}
      {...props}
    >
      {props.children}
    </Link>
  );
};

export default function Footer() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const [{ address }, dispatch] = useApp();

  const [userCurrency, setCurrency] = useCurrencyState("MXN");

  const handleChangeLang = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleChangeCurrency = (newCurrency) => {
    setCurrency(newCurrency);
  };

  const handleConnectWallet = () => {
    dispatch({ type: SET_MODAL, modal: modals.CONNECT });
  };

  const handleGoTo = () => {
    if (!address) {
      handleConnectWallet();
    } else {
      navigate("/minar");
    }
  };

  const downloadWhitepaper = async () => {
    switch (i18n.language) {
      case "es":
        return window.open(
          "https://aztlan.market/Aztlan_Market_Whitepaper_ES.pdf",
          "_blank"
        );
      default:
        return window.open(
          "https://aztlan.market/Aztlan_Market_Whitepaper.pdf",
          "_blank"
        );
    }
  };
  const { mutate } = useRegisterToNewsletter();

  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty },
    reset,
  } = useForm({ mode: "all" });

  const onSubmit = (body) => {
    mutate(body, {
      onSuccess: () => {
        toast.success(`${t("Registrado con éxito")}: ${body.email}`);
        reset();
      },
      onError: () => {
        toast.error(t("Ese correo ya se encuentra registrado"));
      },
    });
  };

  return (
    <footer className="py-5 px-4 px-sm-0 bg-grey-final">
      <Container>
        <div className="d-flex flex-column flex-lg-row divider pb-5">
          <div className="flex-grow-1 mb-4 mb-lg-0">
            <h4 className="text-dark fw-bold mb-0">Aztlan Market</h4>
            <p className="text-muted mb-0">
              {t("La mejor plataforma digital para comprar y vender NFTs")}.
            </p>
          </div>
          <div className="d-flex flex-column flex-sm-row">
            <button
              onClick={handleGoTo}
              className="btn btn-secondary btn-sm align-self-center me-0 me-sm-3 mb-4 mb-sm-0"
            >
              {t("Crear NFT")}
            </button>
            <button
              hidden={!!address}
              onClick={handleConnectWallet}
              className="btn btn-primary btn-sm align-self-center"
            >
              {t("Conectar Wallet")}
            </button>
          </div>
        </div>
        <Row className="mb-6 mt-5">
          <Col lg="2" md="4" sm="4" className="mb-5 mb-sm-0">
            <FormGroup className="mb-3">
              <Form.Label to="/" className=" text-dark">
                <p className="fw-bold mb-0">{t("Foundation")}</p>
              </Form.Label>
            </FormGroup>
            <FormGroup className="mb-3">
              <FooterLink to="/acerca">
                <small>{t("Nuestro equipo")}</small>
              </FooterLink>
            </FormGroup>
            <FormGroup className="mb-3">
              <FooterLink onClick={downloadWhitepaper} to="#">
                <small>{t("Whitepaper")}</small>
              </FooterLink>
            </FormGroup>
            <FormGroup className="mb-3">
              <FooterLink to="/road-map">
                <small>{t("Roadmap")}</small>
              </FooterLink>
            </FormGroup>
            {/* <FormGroup className="mb-3">
              <FooterLink to="/newsletter">
                <small>{t("Newsletter")}</small>
              </FooterLink>
            </FormGroup> */}
            <FormGroup className="mb-3">
              <FooterLink to="/airdrop">
                <small>{t("Airdrop")}</small>
              </FooterLink>
            </FormGroup>
            <FormGroup className="mb-3">
              <FooterLink to="/blog">
                <small>{t("Blog")}</small>
              </FooterLink>
            </FormGroup>
          </Col>
          <Col lg="2" md="4" sm="4" className="mb-5 mb-sm-0">
            <FormGroup className="mb-3">
              <Form.Label to="/" className="text-dark">
                <p className="fw-bold mb-0">{t("Sitio")}</p>
              </Form.Label>
            </FormGroup>
            <FormGroup className="mb-3">
              <FooterLink to="/explorar">
                <small>{t("Explorar NFTs")}</small>
              </FooterLink>
            </FormGroup>
            {/* <FormGroup className="mb-3">
              <FooterLink to="/estadisticas"><small>{t("Estadísticas")}</small></FooterLink>
            </FormGroup> */}
            <FormGroup className="mb-3">
              <FooterLink to="/tokenomics">
                <small>{t("Tokenomics")}</small>
              </FooterLink>
            </FormGroup>
            <FormGroup className="mb-3">
              <FooterLink to="/verificador">
                <small>{t("Verificador")}</small>
              </FooterLink>
            </FormGroup>
            <FormGroup className="mb-3">
              <FooterLink to="/creadores">
                <small>{t("Creadores")}</small>
              </FooterLink>
            </FormGroup>
            {/* <FormGroup className="mb-3">
              <FooterLink to="/como-funciona">
                <small>{t("Cómo funciona")}</small>
              </FooterLink>
            </FormGroup> */}
            {/* <FormGroup className="mb-3">
              <FooterLink to="/airdrop">
                <small>{t("Airdrop")}</small>
              </FooterLink>
            </FormGroup> */}
          </Col>
          <Col lg="2" md="4" sm="4">
            <FormGroup className="mb-3">
              <Form.Label to="/ayuda" className="fw-bold text-dark">
                <p className="fw-bold mb-0">{t("help")}</p>
              </Form.Label>
            </FormGroup>
            <FormGroup className="mb-3">
              <FooterLink to="/terminos-y-condiciones">
                <small>{t("terms_and_conditions")}</small>
              </FooterLink>
            </FormGroup>
            <FormGroup className="mb-3">
              <FooterLink to="/privacidad">
                <small>{t("privacy_policy")}</small>
              </FooterLink>
            </FormGroup>
            <FormGroup className="mb-3">
              <FooterLink to="/ayuda">
                <small>{t("help")}</small>
              </FooterLink>
            </FormGroup>
          </Col>
          <Col
            lg={{ span: 3, offset: 1 }}
            md="6"
            sm="12"
            className="mb-5 mb-sm-0"
          >
            <FormGroup className="mb-0">
              <Form.Label to="/" className="fw-bold text-dark">
                {t("company_name")}
              </Form.Label>
            </FormGroup>
            <FormGroup className="mb-3">
              <Form.Label className="text-muted">
                <small>{t("slogan")}</small>
              </Form.Label>
            </FormGroup>
            <FormGroup>
              <Form.Label to="/" className="fw-bold">
                {t("Recibe mas noticias")}
              </Form.Label>
              <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <div className="input-group text-muted mb-4">
                  <Form.Control
                    name="email"
                    type="text"
                    placeholder={"beeple@aztlan.market"}
                    aria-label={t("Email")}
                    aria-describedby="basic-addon1"
                    // isValid={isValid}
                    isInvalid={isDirty && !isValid}
                    {...register("email", {
                      required: true,
                      pattern: {
                        value: emailRule,
                      },
                    })}
                    // onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button
                    className=" border-0 input-group-text"
                    id="basic-addon1"
                    type="submit"
                    disabled={!isValid}
                  >
                    <ArrowRightIcon width="15px" stroke="#757a8a" />
                  </button>
                </div>
              </Form>
            </FormGroup>
            <FormGroup>
              <div className="d-flex">
                <div className="me-4">
                  <Form.Label to="/" className="fw-bold text-dark">
                    {t("language")}
                  </Form.Label>
                  <div className="d-flex align-self-center align-items-center">
                    <Form.Select
                      size="sm"
                      className=" text-muted"
                      onChange={({ target }) => handleChangeLang(target.value)}
                      defaultValue={i18n.language}
                    >
                      <option value="es">ES</option>
                      <option value="en">EN</option>
                    </Form.Select>
                  </div>
                </div>

                <div>
                  <Form.Label to="/" className="fw-bold text-dark">
                    {t("currency")}
                  </Form.Label>
                  <div className="d-flex align-self-center align-items-center">
                    <Form.Select
                      size="sm"
                      className=" text-muted"
                      onChange={({ target }) =>
                        handleChangeCurrency(target.value)
                      }
                      defaultValue={userCurrency}
                    >
                      <option value="MXN">MXN</option>
                      <option value="USD">USD</option>
                    </Form.Select>
                  </div>
                </div>
              </div>
            </FormGroup>
          </Col>
          {/* <Col lg={{ span: 1, offset: 4 }} md={{ span: 1, offset: 11 }} xs={{ span: 1, offset: 10 }}>
            <UncontrolledDropdown>
              <DropdownToggle
                className="text-white fw-bold border-0 mx-3"
                tag="div"
              >
                <Button className="border-0 bg-dark text-muted"><CogIcon width="25px" /></Button>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-dark py-2 mt-3 p-4">
                <Dropdown.Item header className="text-white">
                  {t('language')}
                </Dropdown.Item>
                <Form.Select className="form-select border-dark" onChange={({ target }) => handleChangeLang(target.value)} defaultValue={i18n.language}>
                  <option ">ES</option>
                  <option value="en">EN</option>
                </Form.Select>
                <Dropdown.Item header className="text-white">
                  {t('currency')}
                </Dropdown.Item>
                <Form.Select className="form-select border-dark" onChange={({ target }) => handleChangeCurrency(target.value)} defaultValue={defaultUserCurrency}>
                  <option ">MXN</option>
                  <option value="USD">USD</option>
                </Form.Select>
              </DropdownMenu>

            </UncontrolledDropdown>
          </Col> */}
        </Row>

        <div>
          <div className="text-muted d-flex flex-column flex-sm-row">
            <div className="mb-4 mb-sm-0 mx-sm-2-auto">
              <div className="d-flex align-self-center align-items-center">
                <div>
                  <LogoBlack height="20px" />
                </div>
                <div className="ms-2">
                  <small>
                    <b>{t("company_name")}</b> ©{" "}
                    {/* {t("Todos los derechos reservados")} */}v{version}
                  </small>
                </div>
              </div>
            </div>
            <div className="ms-auto mx-sm-2-auto">
              <SocialsBar
                // linkedin={"company/aztlanmarket"}
                facebook={"Aztlan-Market-106934338403309"} // TODO: CHANGE
                telegram={"aztlanmarket"}
                instagram={"aztlanmarket"}
                medium={"aztlanmarket"}
                twitter={"AztlanMarket"}
                discord={"B6XY3YDgdM"}
                reddit={"AztlanMarket"}
                className="ms-3"
              />
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}
