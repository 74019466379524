import { useAppState } from "context/context";
import { utils } from "ethers";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { mapCurrency, zeroAddress } from "utils";
import {
  useApproveERC20,
  useDeleteVoucher,
  useERC20Allowance,
} from "actions/Api";
import { OPEN_BUY_MODAL, useNFT } from "./NFTContext";
import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LoadingButton from "components/Shared/LoadingButton";

function Voucher({
  currency,
  minPrice,
  sha256,
  chainId,
  isOwner,
  creator,
  signature,
  _id,
  handleDeleteVoucher,
  handleAuthorize,
  isLoadingApprove,
  openModal,
}) {
  const { t } = useTranslation();
  const { data: erc20Allowance = {} } = useERC20Allowance(
    currency,
    minPrice,
    !isOwner && currency === !zeroAddress
  );

  return (
    <div className="d-flex rounded-3 py-2 border me-3 px-3 my-2 shadowed">
      <div className="flex-shrink-0">
        <div className="btn btn-default m-0 p-0 g-0 position-relative">
          <div
            className="bg-light avatar rounded-pill avatar-40"
            style={{
              backgroundImage: `url(${`/img/currencies/${mapCurrency(
                chainId,
                currency
              ).toLowerCase()}.png`})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          ></div>
        </div>
      </div>
      <div className="flex-grow-1 ms-2 lh-1 align-self-center">
        <p className="text-dark fw-bold mb-1">
          {utils.formatUnits(minPrice, 18)} {mapCurrency(chainId, currency)}
        </p>
        <small className="text-muted text-break">Lazy minted</small>
      </div>

      <div hidden={isOwner}>
        <ButtonGroup>
          {currency !== zeroAddress && (
            <LoadingButton
              disabled={erc20Allowance.enough}
              size="sm"
              onClick={() => handleAuthorize(currency, minPrice)}
              loading={isLoadingApprove}
              loadingtext={t("Autorizando")}
            >
              {t("Autorizar")}
            </LoadingButton>
          )}
          <LoadingButton
            disabled={!erc20Allowance.enough && currency !== zeroAddress}
            size="sm"
            onClick={() =>
              openModal({
                sha256,
                minPrice,
                currency,
                signature,
                chainId,
                creator,
              })
            }
          >
            {t("Comprar")}
          </LoadingButton>
        </ButtonGroup>
      </div>
      <Button
        hidden={!isOwner}
        size="sm"
        onClick={() => handleDeleteVoucher(_id)}
      >
        {t("Eliminar")}
      </Button>
    </div>
  );
}

const VoucherContainer = ({
  vouchers,
  chainId,
  isOwner,
  openModal,
  handleDeleteVoucher,
  handleAuthorize,
  isLoadingApprove,
}) => (
  <div className="my-3">
    <p className="fw-bold text-dark mb-0">Vouchers</p>
    {vouchers
      .filter((voucher) => voucher.chainId === chainId)
      .map((voucher, key) => (
        <Voucher
          key={key}
          isOwner={isOwner}
          handleAuthorize={handleAuthorize}
          isLoadingApprove={isLoadingApprove}
          handleDeleteVoucher={handleDeleteVoucher}
          openModal={openModal}
          {...voucher}
        />
      ))}
  </div>
);

export default function NFTVouchers() {
  const [
    {
      vouchers,
      isAuthor,
      isOwner,
      blockchainInfo: { isMinted },
    },
    dispatchNFT,
  ] = useNFT();

  const { chainId, address } = useAppState();

  const { mutate: mutateDeleteVoucher } = useDeleteVoucher();

  const { mutate: mutateApprove, isLoading: isLoadingApprove } =
    useApproveERC20();

  const handleAuthorize = (currency, minPrice) => {
    mutateApprove({ erc20Address: currency, amount: minPrice });
  };

  const handleDeleteVoucher = (_id) => {
    mutateDeleteVoucher({ _id });
  };

  const openModal = (voucher) => {
    if (!isAuthor) {
      dispatchNFT({
        type: OPEN_BUY_MODAL,
        voucher,
      });
    }
  };

  if (!vouchers.length || !chainId) {
    return null;
  }

  return (
    <VoucherContainer
      vouchers={vouchers}
      chainId={chainId}
      isOwner={isAuthor}
      openModal={openModal}
      handleAuthorize={handleAuthorize}
      isLoadingApprove={isLoadingApprove}
      handleDeleteVoucher={handleDeleteVoucher}
    />
  );
}
