import { Col, Container } from "react-bootstrap";

export default function Jumbotron({ title, boldTitle, subtitle, children }) {
  return (
    <Container>
      <h3 className="text-dark mb-3">
        {title} <span className="fw-bold">{boldTitle}</span>
      </h3>
      <p className="text-muted">{subtitle}</p>
      {children}
    </Container>
  );
}
