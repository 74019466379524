import clsx from "clsx";
import FileTypePreview from "components/Shared/FileTypePreview";

export const maxHeight = "250px";

export default function Previewer({
  preview,
  path = "",
  className,
  fixedheight,
  pointer,
  fullWidth = true,
  height = maxHeight,
  nsfw,
  hashOnly,
}) {
  if (nsfw) {
    return <FileTypePreview filetype={path} nsfw />;
  }
  if (hashOnly) {
    return <FileTypePreview filetype={path} />;
  }
  switch (path.split(".").pop().split("/").pop().toLowerCase()) {
    case "mp3":
      return (
        <audio
          src={preview}
          controls
          autoPlay
          loop
          className={clsx(fullWidth && "w-100", className)}
          style={{ height }}
        />
      );
    case "webm":
    case "mp4":
      return (
        <video
          src={preview}
          controls
          autoPlay
          loop
          className={clsx(fullWidth && "w-100", className)}
          style={{ height }}
        />
      );
    case "png":
    case "jpg":
    case "jpeg":
    case "webp":
    case "gif":
      if (fixedheight) {
        return (
          <div
            className={clsx(fullWidth && "w-100", className)}
            style={{
              backgroundImage: `url(${preview})`,
              height,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        );
      } else {
        return (
          <img
            src={preview}
            className={clsx(
              pointer && "pointer",
              fullWidth ? "d-block h-100 w-100 rounded-3" : "w-100",
              "img-responsive"
            )}
            alt=""
          ></img>
        );
      }
    default:
      return <FileTypePreview filetype={path} size="sm" />;
  }
}
