import { useApproveERC20, useBidNFT, useGetProfile } from "actions/Api";
import CurrencyInput from "components/Shared/CurrencyInput";
import { useApp, useAppState } from "context/context";
import { utils } from "ethers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "components/Shared/Toast";
import { Button, Modal } from "react-bootstrap";
import { errorMapper, zeroAddress } from "utils";
import LoadingButton from "components/Shared/LoadingButton";
import PriceTicker from "components/Shared/PriceTicker";
import { CLOSE_BID_MODAL, useNFT } from "components/Pages/NFT/NFTContext";

const BidModalContainer = ({
  name,
  currency,
  price,
  verified,
  handleBid,
  isLoading,
  t,
  isLoadingApprove,
  showBidModal,
  handleClose,
}) => (
  <Modal
    show={showBidModal}
    centered
    backdropClassName="zi-1055"
    onHide={handleClose}
  >
    <Modal.Header closeButton></Modal.Header>
    <Modal.Body>
      <div className="text-center">
        <h5 className="text-dark fw-bold">{t("Ofertar por  este NFT")}</h5>
        <span className="text-muted">
          {" "}
          {t("Confirma la oferta de")} <label className="fw-bold">{name}</label>{" "}
          {t("por")}:{" "}
        </span>
        <h3 className="text-center text-dark mt-3 mb-4">
          <PriceTicker
            currency={currency}
            price={price}
            className="justify-content-center"
          />
        </h3>

        <div className="bg-light p-2 my-4 rounded-3" hidden={verified}>
          <p className="text-dark mb-0">
            {t("Este creador aun no ha sido verificado")}
          </p>
          <small className="text-muted">
            {t("Compra este NFT bajo tu propio riesgo")}
          </small>
        </div>

        <div>
          <div>
            <LoadingButton
              onClick={handleBid}
              loading={isLoading}
              loadingtext={
                isLoadingApprove
                  ? t("Esperando pago")
                  : t("Esperando aprobación pago")
              }
            >
              {t("Ofertar ahora")}
            </LoadingButton>
          </div>
          <small className="text-muted d-block mt-3">
            {t("Las ofertas no pueden deshacerse")}
          </small>
        </div>
      </div>
    </Modal.Body>
  </Modal>
);

export default function BidModal() {
  const [
    {
      author,
      formattedOffer,
      name,
      showBidModal,
      refetchArtwork,
      blockchainInfo: { tokenId, bidCurrency },
      refetchInfo,
    },
    dispatch,
  ] = useNFT();
  const { mutate: bidNFT, isLoading } = useBidNFT();

  const { t } = useTranslation();

  const { data: authorProfile = {} } = useGetProfile(author, {
    enabled: !!author,
  });

  const { mutate: mutateApprove, isLoading: isLoadingApprove } =
    useApproveERC20();

  const handleClose = () => {
    dispatch({ type: CLOSE_BID_MODAL });
  };

  const handleBid = () => {
    bidNFT(
      {
        tokenId: tokenId,
        offer: formattedOffer,
        value: bidCurrency === zeroAddress ? formattedOffer : 0,
      },
      {
        onSuccess: () => {
          handleClose();
          refetchArtwork();
          refetchInfo();
        },
        onError: (err) => {
          const error = errorMapper(err);
          if (error === "errors.not_enough_allowed") {
            mutateApprove(
              {
                erc20Address: bidCurrency,
                amount: formattedOffer,
              },
              {
                onSuccess: () => {
                  handleBid();
                },
                onError: (err2) => {
                  toast.error(errorMapper(err2));
                },
              }
            );
          } else {
            toast.error(error);
          }
        },
      }
    );
  };

  return (
    <BidModalContainer
      name={name}
      t={t}
      tokenId={tokenId}
      currency={bidCurrency}
      price={formattedOffer}
      verified={authorProfile?.verified}
      handleBid={handleBid}
      isLoading={isLoading}
      isLoadingApprove={isLoadingApprove}
      showBidModal={showBidModal}
      handleClose={handleClose}
    />
  );
}
