import { SET_MODAL, useApp } from "context/context";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Row } from "react-bootstrap";
import LoadingButton from "components/Shared/LoadingButton";
import { useAuctionEnd } from "actions/Api";
import CurrencyInput from "components/Shared/CurrencyInput";
import { modals } from "constant";
import { OPEN_BID_MODAL, SET_OFFER, useNFT } from "./NFTContext";

const BidderControls = ({
  auctionIsFinished,
  hasHighestBid,
  isOwner,
  handleCloseAuction,
  isEndingAuction,
  t,
  offer,
  setOffer,
  handleOffer,
}) => {
  if (auctionIsFinished) {
    return (
      <div className="my-4">
        <LoadingButton
          className="w-100"
          onClick={handleCloseAuction}
          loading={isEndingAuction}
          loadingtext={t("Cerrando subasta")}
        >
          {t("Settle")}
        </LoadingButton>
      </div>
    );
  }

  if (!isOwner && !auctionIsFinished) {
    return (
      <div className="my-4">
        {hasHighestBid ? (
          <Button variant="secondary" disabled={true}>
            {t("Tienes la oferta mas alta")}
          </Button>
        ) : (
          <Row>
            <Col lg="7">
              <Form.Group>
                <Form.Label className="fw-bold">{t("Tu oferta")}</Form.Label>
                <CurrencyInput
                  placeholder="0.00"
                  type="text"
                  className="form-control form-control-lg"
                  value={offer}
                  onChange={({ target }) => setOffer(target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg="3">
              <Button onClick={handleOffer} disabled={!offer}>
                {t("Ofertar ahora")}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    );
  }
  return null;
};

export default function NFTControlsBidder() {
  const [
    {
      blockchainInfo: { hasHighestBid, auctionIsFinished, onAuction, tokenId },
      isOwner,
      offer,
      refetchInfo,
      refetchArtwork,
    },
    dispatchNFT,
  ] = useNFT();
  const { t } = useTranslation();

  const [{ address }, dispatch] = useApp();

  const { mutate: endAuction, isLoading: isEndingAuction } = useAuctionEnd();

  const handleOffer = () => {
    if (!address) {
      dispatch({ type: SET_MODAL, modal: modals.CONNECT });
    } else {
      dispatchNFT({
        type: OPEN_BID_MODAL,
      });
    }
  };

  const setOffer = (offer) => {
    dispatchNFT({ type: SET_OFFER, offer });
  };

  const handleCloseAuction = () => {
    endAuction(
      { tokenId: tokenId },
      {
        onSuccess: () => {
          refetchInfo();
          refetchArtwork();
        },
      }
    );
  };
  if (!onAuction) {
    return null;
  }

  return (
    <BidderControls
      auctionIsFinished={auctionIsFinished}
      hasHighestBid={hasHighestBid}
      isOwner={isOwner}
      handleCloseAuction={handleCloseAuction}
      isEndingAuction={isEndingAuction}
      t={t}
      offer={offer}
      setOffer={setOffer}
      handleOffer={handleOffer}
    />
  );
}
