import { useTranslation } from "react-i18next";
import { Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import { BigNumber } from "@ethersproject/bignumber";
import { formatCrypto, truncateAddress } from "utils";
import ParentSize from "@visx/responsive/lib/components/ParentSizeModern";

import { ReactComponent as TrendingDownIcon } from "assets/icons/trendingdown.svg";
import { ReactComponent as MultichainIcon } from "assets/icons/multichain.svg";
import { ReactComponent as CertifiedIcon } from "assets/icons/certified.svg";
import { ReactComponent as NetworkIcon } from "assets/icons/network.svg";
import { ReactComponent as CoinIcon } from "assets/icons/coin.svg";
import { ReactComponent as Logo } from "assets/logo.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import LogoutIcon from "@heroicons/react/outline/LogoutIcon";
import PieChart from "components/Graphs/PieChart";
import Jumbotron from "components/Shared/Jumbotron";

import Hero from "assets/hero-img-02.png";
import Check from "assets/check.svg";
import { tokenomicsDistribution } from "constant";

export const tokenStats = {
  totalSupply: "100000000000000000000000000",
  ticker: "AZTLAN",
  network: "Binance Smart Chain",
  type: "BEP20",
  address: "0x92bD46E88931cbcfCFe1a4b7cCE1847D6fA74FAD",
};

const cards = [
  {
    title: "Hash 256",
    icon:  <MultichainIcon />,
    subtitle: "NFT’s únicos en la red, como una verdadera obra de arte",
  },
  {
    title: "ERC20",
    icon: <MultichainIcon />,
    subtitle: "Compra y vende NFT’s en cualquier ERC20 soportado por la red",
  },
  {
    title: "Certificado",
    icon: <CertifiedIcon />,
    subtitle:
      "Certifica y verifica la proveniencia de tu NFT, Autores certificados",
  },
  {
    title: "Multichain",
    icon: <NetworkIcon />,
    subtitle: "Minimiza los costos y elige la red de tu preferencia",
  },
  {
    title: "Bajo costo",
    icon: <TrendingDownIcon />,
    subtitle:
      "Operaciones Offchain para maximizar la eficiencia y reducir costos",
  },
  {
    title: "Stablecoins",
    icon:  <CoinIcon />,
    subtitle:
      "Evita la volatilidad y manten el valor de tu obra al vender en Stablecoins",
  },
];

const benefits = [
  "Certificar activos como un artista verificado",
  "Reducir las comisiones en la transferencia de activos",
  "Tener mayores niveles de exposición dentro de los listados de las plataformas",
  "Acceso a eventos y/o galas organizadas por la fundación",
  "Acceder a votaciones sobre propuestas para modificaciones y mejoras de la plataforma",
];

const UtilityToken = ({ t }) => (
  <Row>
    <Col
      lg={{ span: 5, offset: 1 }}
      className="d-flex flex-column align-items-center align-self-center"
    >
      <h3 className="fw-bold">
        {t("company_name")} {t("utility_token")}
      </h3>
      <p>{t("utility_token_subtitle")}</p>
      <ul className="list-unstyled border-0 mb-0">
        {benefits.map((benefit, key) => (
          <li className="border-0 bg-white mb-2" key={key}>
            <div className="d-flex align-items-start">
              <div className="avatar avatar-28 rounded-pill p-2 me-3">
                <img src={Check} alt="" className="img-fluid" />
              </div>

              <div>
                <span className="text-muted"> {t(benefit)}</span>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </Col>
    <Col
      lg={{ span: 5, offset: 1 }}
      className="d-flex flex-column align-items-center"
    >
      <img src={Hero} alt="" className="img-fluid" />
    </Col>
  </Row>
);

const TokenInformation = ({ t }) => (
  <>
    <Row>
      <Col lg={{ span: 6 }}>
        <h3 className="mb-5">{t("Información del token")}</h3>
      </Col>
      <Col lg={{ span: 5 }}>
        <div className="d-flex flex-column justify-content-evenly border p-5 mb-5">
          <div className="pb-3">
            <p className="lead fw-bold mb-0">
              {formatCrypto(tokenStats.totalSupply)}
            </p>
            <p className="text-muted mb-0">{t("Total")}</p>
          </div>
          <div className="border-bottom mb-3"></div>
          <div className="pb-3">
            <p className="lead fw-bold mb-0">{tokenStats.ticker}</p>
            <p className="text-muted mb-0">{t("Ticker")}</p>
          </div>
          <div className="border-bottom mb-3"></div>
          <div className="pb-3">
            <p className="lead fw-bold mb-0">{tokenStats.type}</p>
            <p className="text-muted mb-0">{t("Tipo")}</p>
          </div>
          <div className="border-bottom mb-3"></div>
          <div className="pb-3">
            <p className="lead fw-bold mb-0">{tokenStats.network}</p>
            <p className="text-muted mb-0">{t("Blockchain")}</p>
          </div>
          <div className="border-bottom mb-3"></div>
          <div className="">
            <p className="fw-bold mb-0">
              <a
                className="lead fw-bold text-decoration-none text-dark"
                target="_blank"
                rel="noreferrer"
                href={`https://bscscan.com/token/${tokenStats.address}`}
              >
                {truncateAddress(tokenStats.address, 15)}
              </a>
            </p>
            <p className="text-muted mb-0">{t("Dirección")}</p>
          </div>
        </div>
      </Col>
    </Row>
  </>
);

const TokenDistribution = ({ t }) => (
  <Row>
    <Jumbotron
      title={t("Distribución del")}
      boldTitle={t("token")}
      subtitle={t("El token estará distribuido de la siguiente manera")}
    />
    <Col
      lg={{ span: 4 }}
      className="align-self-center align-items-center text-center"
    >
      <div style={{ height: "300px", width: "300px" }} className="text-center">
        <ParentSize>
          {({ width, height }) => <PieChart width={width} height={height} />}
        </ParentSize>
      </div>
    </Col>
    <Col
      lg={{ span: 6, offset: 1 }}
      className="align-self-center align-items-center"
    >
      <ListGroup className="border-0">
        {tokenomicsDistribution
          .sort((dist, prev) =>
            BigNumber.from(dist.usage).lt(BigNumber.from(prev.usage))
          )
          .map((dist, key) => (
            <ListGroup.Item className="border-0 bg-white text-dark" key={key}>
              <div className="d-flex">
                <div>
                  <div className="avatar bg-primary avatar-16 rounded-pill p-2 me-3" />
                </div>
                <div>
                  <div>
                    <b>
                      {dist.usage}% {t(dist.label)}
                      {dist.symbol}
                    </b>
                  </div>
                  <div>
                    <small>
                      {formatCrypto(
                        BigNumber.from(tokenStats.totalSupply)
                          .mul(dist.usage)
                          .div(100)
                      )}{" "}
                      {tokenStats.ticker}
                    </small>
                  </div>
                </div>
              </div>
            </ListGroup.Item>
          ))}
      </ListGroup>
      <Col lg="7" className="mt-3">
        <div>
          <small className="text-muted">
            *{" "}
            {t(
              "Los fundadores no tendrán acceso a sus tokens hasta 6 meses después de la puesta en venta"
            )}
          </small>
        </div>
        <div className="ms-3 mt-2">
          <small>{t("Founder")} 1 - 5,000,000 Timelock</small>
          <a
            href="https://bscscan.com/address/0x09875dc6aa657437d4cb74b82a3e84c1dcbe734b#readContract"
            target="_blank"
            rel="noreferrer"
          >
            <LogoutIcon height="16px" className="ms-2" />
          </a>
        </div>
        <div className="ms-3 mt-2">
          <small>{t("Cofounder")} 2 - 3,000,000 Timelock</small>
          <a
            href="https://bscscan.com/address/0x10A27251090D851e6f37978982218F2871742d7d#readContract"
            target="_blank"
            rel="noreferrer"
          >
            <LogoutIcon height="16px" className="ms-2" />
          </a>
        </div>
        <div className="ms-3 mt-2">
          <small>{t("Cofounder")} 3 - 2,000,000 Timelock</small>
          <a
            href="https://bscscan.com/address/0x326274feB8A2985158d7cAd591240418ab4e9171#readContract"
            target="_blank"
            rel="noreferrer"
          >
            <LogoutIcon height="16px" className="ms-2" />
          </a>
        </div>
      </Col>
    </Col>
  </Row>
);

const TokenomicsCard = ({ title, subtitle, icon, t }) => (
  <Card className="bg-white px-3 py-2 mb-4">
    <Card.Header className="mt-3 bg-white border-0">
      <div className="bg-primary avatar rounded avatar-64 p-2">{icon}</div>
      <h5 className="text-dark mt-5 fw-bold">{t(title)}</h5>
    </Card.Header>
    <Card.Body className="pt-0">
      <p className="text-muted mb-0">{t(subtitle)}</p>
    </Card.Body>
  </Card>
);

const TokenBenefits = ({ t }) => (
  <Row>
    <h3 className="text-dark mb-5">{t("Tokenomics")}</h3>
    {cards.map((card, key) => (
      <Col lg="4" md="6" sm="12" xs="12" key={key}>
        <TokenomicsCard {...card} t={t} />
      </Col>
    ))}
  </Row>
);

export default function Tokenomics() {
  const { t } = useTranslation();

  return (
    <>
      <section className="bg-white mt-5">
        <Container>
          <UtilityToken t={t} />
        </Container>
      </section>
      <section className="bg-white">
        <Container>
          <TokenInformation t={t} />
        </Container>
      </section>
      <section className="bg-white">
        <Container>
          <TokenDistribution t={t} />
        </Container>
      </section>
      <section className="bg-white">
        <Container>
          <TokenBenefits t={t} />
        </Container>
      </section>
    </>
  );
}
