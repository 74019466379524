import { useGetArtists } from "actions/Api";
import Avatar from "components/Shared/Avatar";
import { FetcherComponent } from "components/Shared/Fetcher";
import SocialsBar from "components/Shared/SocialsBar";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import { Fragment, useMemo, useRef } from "react";
import { Card, CardGroup, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as style from "@dicebear/big-smile";
import { truncateAddress } from "utils";

import ProfileImage from "assets/ProfileImg.jpg";
import Cover from "assets/cover-bg.jpg";
import { Link } from "react-router-dom";
import Verified from "assets/icon-verified.svg";
import { createAvatar } from "@dicebear/avatars";

function Artist({ data = {} }) {
  const { t } = useTranslation();
  const {
    username,
    description,
    address,
    verified,
    imageUrl,
    bannerUrl,
    nftsCreated = 0,
    ...socialNetworks
  } = data;

  const avatar = useMemo(() => {
    if (!address || imageUrl) return null;
    return createAvatar(style, {
      seed: String(address).toLowerCase(),
      dataUri: true,
    });
  }, [address, imageUrl]);

  return (
    <Col>
      <div className="bg-white text-center rounded-3 user-profile w-100 shadow-sm">
        <div className="position-relative d-flex align-items-center justify-content-center mb-5 rounded-3-top">
          <div
            className="w-100"
            style={{
              backgroundImage: `url(${bannerUrl || Cover})`,
              backgroundSize: "cover",
              height: "100px",
              borderRadius: "1rem 1rem 0 0",
            }}
          ></div>
          <img
            src={avatar || imageUrl}
            alt=""
            style={{
              width: "100px",
              top: "50px",
              maxHeight: "100px",
              objectFit: "cover",
            }}
            className="border border-light bg-light border-3 position-absolute rounded-pill img-fluid"
          />
        </div>
        <div className="pt-2 px-4 pb-4">
          <h5 className="mb-0 text-truncate">
             {username}
            <img
              src={Verified}
              alt=""
              className="img-fluid ms-1"
              hidden={!verified}
            />
          </h5>
          <small className="text-muted d-block mb-2">
            {truncateAddress(address)}
          </small>
          <Link
            className="btn btn-secondary btn-sm w-100"
            to={`/perfil/${address}`}
          >
            {t("Ver perfil")}
          </Link>
        </div>
      </div>
    </Col>
    // <Col>
    //   <Card className="text-center pointer" onClick={goToProfile}>
    //     <Card.Header className="p-3">
    //       <Avatar
    //         image={imageUrl}
    //         // verified={verified}
    //         address={address}
    //         size="128"
    //       />
    //       {/* <div
    //         className="avatar rounded-pill align-self-center"
    //         style={{
    //           backgroundImage: `url(${imageUrl})`,
    //           backgroundPosition: "center",
    //           backgroundRepeat: "space",
    //           backgroundSize: "250px",
    //           height: "250px",
    //           width: "250px",
    //         }}
    //       /> */}
    //     </Card.Header>
    //     <Card.Body>
    //       <Card.Title className="d-flex align-self-center align-items-center justify-content-center">
    //         {username}
    //         {<Verified verified={verified} absolutePositioned={false} />}
    //       </Card.Title>
    //       <Card.Text>{description}</Card.Text>
    //       <Card.Text>NFTS Creados: {nftsCreated}</Card.Text>
    //       <Card.Text className="text-muted">
    //         {truncateAddress(address)}
    //       </Card.Text>
    //       <SocialsBar {...socialNetworks} className="mx-3" />
    //     </Card.Body>
    //   </Card>
    // </Col>
  );
}

export default function Artists() {
  const { t } = useTranslation();

  const {
    fetchNextPage,
    hasNextPage,
    status,
    error,
    // fetchPreviousPage,
    // hasPreviousPage,
    // isFetchingPreviousPage,
    isFetchingNextPage,
    data,
  } = useGetArtists();

  const loadMoreButtonRef = useRef();

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  return (
    <section className="bg-white mt-5">
      <Container>
        <h3 className="mb-0">{t("Creators")}</h3>
        <p className="text-muted mb-5">
          {t("Descubre a los mejores artistas y sus obras")}
        </p>
        <Row lg={4} md={2} xs={1} className="g-5 text-dark">
          {data?.pages?.map((page) => (
            <Fragment key={page.page}>
              {page?.docs?.map((props) => (
                <Artist key={props._id} data={props} />
              ))}
            </Fragment>
          ))}
        </Row>
        {/*         
          <Col lg={{ span: 3 }}>
            <div className="bg-white text-center rounded-3 user-profile w-100 shadow-sm">
              <div className="position-relative d-flex align-items-center justify-content-center mb-5 rounded-3-top">
                <div className="user-profile-bg w-100"></div>
                <img src={ProfileImage} alt="" className="border border-light border-3 position-absolute" />
              </div>
              <div className="p-4">
                <h5 className="mb-0">Jhony Walker</h5>
                <small className="text-muted d-block mb-4">0x3c6690...a6f44</small>
                <button className="btn btn-secondary btn-sm w-100">Ver perfil</button>
              </div>
            </div>
          </Col>
        </Row> */}
        <div className="text-center mt-5">
          <button
            className="btn btn-secondary btn-sm"
            ref={loadMoreButtonRef}
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            {isFetchingNextPage
              ? t("Loading more...")
              : hasNextPage
              ? t("Load Newer")
              : t("Nothing more to load")}
          </button>
        </div>
      </Container>
    </section>
  );
}
