import { useCreateArtWork } from "actions/Api";
import { Loading } from "components/Shared/Fetcher";
import { useAppState } from "context/context";
import { useTranslation } from "react-i18next";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { isSameAddress } from "utils";
import { REFETCH_INTERVAL, TOGGLE_LAZY, useNFT } from "./NFTContext";
import { useState } from "react";
import QuestionMarkCircleIcon from "@heroicons/react/outline/QuestionMarkCircleIcon";
import LoadingButton from "components/Shared/LoadingButton";

export default function NFTControlsMinter() {
  const [
    { blockchainInfo, sha256, author, refetchInterval, lazy, vouchers },
    dispatch,
  ] = useNFT();

  const { t } = useTranslation();

  const [isLazy, setIsLazy] = useState(false);

  const { address } = useAppState();

  const { mutate: createArtWork, isLoading } = useCreateArtWork();

  const handleMint = () => {
    createArtWork(
      {
        sha256,
      },
      {
        onSuccess: () => {
          dispatch({ type: REFETCH_INTERVAL, refetchInterval: 2000 });
        },
      }
    );
  };

  const toggleSetLazy = () => setIsLazy(!isLazy);

  const handleLazyMint = () => {
    dispatch({ type: TOGGLE_LAZY });
  };

  if (blockchainInfo?.minted || !isSameAddress(address, author)) {
    return null;
  }

  if (lazy || vouchers?.length) {
    return null;
  }

  return (
    <>
      <div
        variant="light"
        className="d-flex justify-content-between align-items-start my-4"
      >
        <div>{/* <ExclamationIcon className="me-2" height="20px" /> */}</div>
        <div className="me-auto">
          <div
            className="text-dark fw-bold d-flex align-self-center align-items-center"
            htmlFor="free_minting"
            id="free_minting"
          >
            <div>
              <label>{t("Free minting")}</label>
            </div>
            <div className="ms-1 pointer">
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id="tooltip-disabled">
                    {t("Free_minting_tooltip")}
                  </Tooltip>
                }
              >
                <div>
                  <QuestionMarkCircleIcon
                    height="20px"
                    className="text-muted"
                  />
                </div>
              </OverlayTrigger>
            </div>
          </div>
          <small className="text-muted">
            {" "}
            {t("Buyer will pay gas fees for minting")}
          </small>
        </div>

        <div className="form-check form-switch ms-2">
          <input
            checked={isLazy}
            onChange={toggleSetLazy}
            className="form-check-input bg-light"
            type="checkbox"
          />
        </div>
      </div>

      <div className="">
        {!isLazy ? (
          <LoadingButton
            loading={refetchInterval || isLoading}
            onClick={handleMint}
            disabled={!!refetchInterval || isLoading}
            hidden={!!blockchainInfo?.minted}
            className="w-100"
            loadingtext={t("Minando")}
          >
            {t("Minar")}
          </LoadingButton>
        ) : (
          <LoadingButton
            onClick={handleLazyMint}
            loading={refetchInterval || isLoading}
            disabled={!!refetchInterval || isLoading}
            hidden={!!blockchainInfo?.minted}
            className="w-100"
            // variant="secondary"
            loadingtext={t("Minando")}
          >
            {t("Crear voucher")}
          </LoadingButton>
        )}
      </div>
    </>
  );
}
