import { modals } from "constant";
import { Button } from "react-bootstrap";

const { useAppDispatch, SET_MODAL } = require("context/context");
const { useTranslation } = require("react-i18next");

export default function WarningConnectModal() {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const handleHasKey = () => {
    dispatch({ type: SET_MODAL, modal: modals.USELOCAL });
  };
  const handleNewAddress = () => {
    dispatch({ type: SET_MODAL, modal: modals.CREATELOCAL });
  };

  return (
    <>
      <div className="text-center">
        <h4 className="text-dark">{t("warning")}:</h4>
        <small className="text-muted"> {t("wallet_danger")}</small>
      </div>
      <Button variant="primary" className="w-100 my-3" onClick={handleHasKey}>
        {t("i_have_a_key")}
      </Button>

      <Button
        variant="secondary"
        className="w-100 mb-3"
        onClick={handleNewAddress}
      >
        {t("create_new_key")}
      </Button>
    </>
  );
}
