import { useNavigate } from "react-router-dom";
import { Card, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import FileTypePreview from "components/Shared/FileTypePreview";
import Previewer from "components/Shared/Previewer";
import ProfileViewer from "components/Shared/ProfileViewer";
import PriceTag from "components/Shared/PriceTag";
import PriceTicker from "components/Shared/PriceTicker";
import { useLocation } from "react-router";

function GalleryImage({ data, goTo, ...props }) {
  const {
    name,
    image,
    author,
    blockchainInfo,
    description,
    _id,
    IpfsHash,
    filetype,
    sha256,
    vouchers = [],
    IpfsUrl,
    file = {},
    nsfw,
  } = data;

  const navigate = useNavigate();

  const location = useLocation();

  const { t } = useTranslation();

  const handleGoToNFT = () => {
    navigate({
      pathname: location.pathname,
      search: "?nft=" + sha256,
    });
  };

  return (
    <Col {...props} className="gx-5">
      <Card className="bg-white border-0 pointer mb-5" onClick={handleGoToNFT}>
        <div>
          {IpfsUrl || file?.path ? (
            <Previewer
              preview={IpfsUrl}
              path={filetype}
              nsfw={nsfw}
              {...file}
              className="rounded"
              fixedheight={props.fixedheight}
            />
          ) : (
            <FileTypePreview filetype={filetype} size="sm" name={sha256} />
          )}
        </div>
        <div className="pt-3">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h6 className="text-dark fw-bold text-truncate mb-0">{name}</h6>
            {(blockchainInfo?.onSale || blockchainInfo?.onAuction) && (
              <div className="d-flex align-items-center">
                <PriceTag
                  className="text-primary fw-bold rounded-0 p-0"
                  price={blockchainInfo?.price}
                  currency={blockchainInfo?.currency}
                />
              </div>
            )}
            {vouchers?.[0] && (
              <div className="d-flex align-items-center">
                <PriceTag
                  className="text-primary fw-bold rounded-0 p-0"
                  price={vouchers?.[0]?.minPrice}
                  currency={vouchers?.[0]?.currency}
                  chainId={vouchers?.[0]?.chainId}
                />
              </div>
            )}
          </div>
          <ProfileViewer
            hidden={props.hideProfile}
            classLayoutProfile="border-0"
            paddingXLayoutProfile="px-0"
            address={blockchainInfo?.owner || author}
            description={
              blockchainInfo?.owner === author
                ? t("OwnerAndAuthor")
                : blockchainInfo?.owner
                ? t("Owner")
                : t("Author")
            }
          />
          <div>
            <div className="d-flex">
              {blockchainInfo?.onAuction && (
                <>
                  <div>
                    <small className="text-muted">{t("Oferta más alta")}</small>
                  </div>
                  <div className="ms-2">
                    <PriceTicker
                      price={blockchainInfo?.price}
                      currency={blockchainInfo?.currency}
                      className="fw-bold"
                      sm
                    />
                  </div>
                </>
              )}
              {blockchainInfo?.onSale && (
                <>
                  <small className="d-block text-muted">{t("En venta")}</small>

                  <div className="ms-2">
                    {/* <PriceTicker price={price} currency={currency} className="fw-bold" /> */}
                  </div>
                </>
              )}
              {!!vouchers?.length && (
                <>
                  <small className="d-block text-muted">{t("Vouchers")}</small>

                  <div className="ms-2">
                    {/* <PriceTicker price={price} currency={currency} className="fw-bold" /> */}
                  </div>
                </>
              )}
              {!blockchainInfo?.onSale &&
                !blockchainInfo?.onAuction &&
                !!!vouchers.length && (
                  <>
                    <div>
                      <small className="text-muted">
                        {t("Solo exhibición")}
                      </small>
                    </div>
                    <div className="ms-2">
                      {/* <PriceTicker price={price} currency={currency} className="fw-bold" /> */}
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      </Card>
    </Col>
  );
}

export default GalleryImage;
